export const getDiffHighlightedText = (
  diff,
  words,
  currentTime,
  handleWordClick
) => {
  if (!diff || !Array.isArray(diff) || !words || !Array.isArray(words))
    return null;

  let wordIndex = 0;

  return diff.map((part, partIndex) => {
    if (!part || typeof part !== "object") return null;

    if (part.added) {
      return (
        <span
          key={`added-${partIndex}`}
          style={{
            color: "green",
            fontWeight: "normal",
          }}
        >
          {part.value.split(/\s+/).map((word, idx) => {
            const isActive = currentTime >= words[wordIndex]?.start && currentTime <= words[wordIndex]?.end;
            const currentWordIndex = wordIndex;
            wordIndex++;
            return (
              <span
                key={`${word}-${currentWordIndex}`}
                onClick={() => handleWordClick(word, currentWordIndex)}
                style={{
                  cursor: "pointer",
                  fontWeight: isActive ? "bold" : "normal",
                  whiteSpace: "pre-wrap",
                }}
              >
                {word}{" "}
              </span>
            );
          })}
        </span>
      );
    } else if (part.removed) {
      return (
        <span
          key={`removed-${partIndex}`}
          style={{
            color: "red",
            textDecoration: "line-through",
            whiteSpace: "pre-wrap",
          }}
        >
          {part.value}
        </span>
      );
    } else {
      return part.value.split(/\s+/).map((word, idx) => {
        const isActive = currentTime >= words[wordIndex]?.start && currentTime <= words[wordIndex]?.end;
        const currentWordIndex = wordIndex;
        wordIndex++;
        return (
          <span
            key={`${word}-${currentWordIndex}`}
            onClick={() => handleWordClick(word, currentWordIndex)}
            style={{
              fontWeight: isActive ? "bold" : "normal",
              cursor: "pointer",
              whiteSpace: "pre-wrap",
            }}
          >
            {word}{" "}
          </span>
        );
      });
    }
  });
};