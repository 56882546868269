import React, { useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";

const RecognitionComponent = () => {
  const { link } = useSelector((state) => state.featureData);
  const [audioFiles, setAudioFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ratings, setRatings] = useState({});

  const handleUpload = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
      audioFile: file,
    }));
    setAudioFiles([...audioFiles, ...newFiles]);
  };

  const handleDelete = () => {
    setAudioFiles([]);
    setRatings({});
  };

  // This function simulates an API call
  const handleDetectVoice = async (event) => {
    if (audioFiles.length === 0) {
      return; // No files selected
    }

    const formData = new FormData();
    Array.from(audioFiles).forEach((file, index) => {
      formData.append(`files`, file.audioFile);
    });
    setIsLoading(true);

    try {
      // Adjust the URL to your upload endpoint
      const response = await axios.post(link, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // Handle response, e.g., setting ratings based on the response
      // console.log('Upload success', response.data);
      const updatedRatings = generateRatings(response.data); // Call it directly if no response data is required
      setRatings(updatedRatings);
      // Optionally, update audioFiles state with uploaded file details from response if needed
    } catch (error) {
      console.error("Upload error", error);
      // Handle error, e.g., showing an error message
    } finally {
      setIsLoading(false); // End loading


    }
  };

  const generateRatings = (ratingData) => {
    let newRatings = {};

    audioFiles.forEach((file, index) => {
      newRatings[file.name] = {};

      // Assuming ratingData[index + 1] corresponds to the file at the same index
      // because the first row of ratingData is ['--0--', 'file1', 'file2', 'file3']
      const fileRatings = ratingData[index + 1].slice(1); // Skip the first element, which is the file name in the response

      audioFiles.forEach((innerFile, innerIndex) => {
        // Map each rating to the corresponding file based on order
        const rating = fileRatings[innerIndex];
        newRatings[file.name][innerFile.name] =
          index === innerIndex ? 1 : parseFloat(rating);
      });
    });

    return newRatings;
  };

  return (
    <Box sx={{ flexGrow: 1, position: "relative", width: "100%" }}>
      <Stack
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        // sx={{ maxWidth: 'calc(100vw - 32px)', margin: 'auto' }} // Add padding to account for potential scrollbar
      >
        <Stack direction="row" spacing={2} justifyContent="center" mb={2}>
          {" "}
          <Button
            variant="contained"
            component="label"
            sx={{ textTransform: "none" }}
          >
            Upload Audio
            <input
              type="file"
              hidden
              multiple
              accept="audio/*"
              onChange={handleUpload}
            />
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            sx={{ textTransform: "none" }}
          >
            Delete Audio
          </Button>
        </Stack>
        <Box mb={2} height="100%" overflow="auto">
          {audioFiles.map((file, index) => (
            <Typography key={index}>{file.name}</Typography>
          ))}
        </Box>

        <Button
          variant="contained"
          color="primary"
          mb={2}
          onClick={handleDetectVoice}
          disabled={isLoading}
          sx={{ textTransform: "none" }}
        >
          {isLoading ? <CircularProgress size={24} /> : "Detect Voice"}
        </Button>

        {audioFiles.length > 0 && (
          <TableContainer
            component={Paper}
            sx={{ maxWidth: "100%", maxHeight: 840, mt: 2 }}
          >
            <Table stickyHeader sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1100, // Ensure zIndex is high enough
                      backgroundColor: (theme) =>
                        theme.palette.background.paper, // Use theme's background color
                      minWidth: 160, // Set minimum width for the sticky column
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Audio Files
                  </TableCell>
                  {audioFiles.map((file, index) => (
                    <TableCell
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1100,
                        // backgroundColor: (theme) =>
                        //   theme.palette.background.paper,
                        // whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {file.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {audioFiles.map((file, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,

                        zIndex: 1100, // Ensure zIndex is high enough
                        backgroundColor: (theme) =>
                          theme.palette.background.paper, // Use theme's background color
                        //   whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {file.name}
                    </TableCell>
                    {audioFiles.map((innerFile, innerIndex) => (
                      <TableCell key={innerIndex}>
                        {isLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          ratings[file.name] &&
                          ratings[file.name][innerFile.name]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
};

export default RecognitionComponent;
