import { useCallback } from 'react';
import axios from 'axios';
import { alertActions } from '../Components/Alert/alertSlice';

const useAudioFileUpload = (link, dispatch) => {
  const handleAudioFileUpload = useCallback(async (event) => {
    const files = event.target.files;
    const formData = new FormData();

    formData.append("file", files[0]);

    try {
      const response = await axios.post(`${link}add_new_audio_file`, formData, {
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        dispatch(
          alertActions.showAlert({
            type: "success",
            message: "File uploaded successfully",
          })
        );

      }
    } catch (error) {
      console.error("Error processing file:", error);
      dispatch(
        alertActions.showAlert({
          type: "error",
          message: "Error processing file",
        })
      );
    }
  }, [link, dispatch]);

  return { handleAudioFileUpload };
};

export default useAudioFileUpload;