import { configureStore } from "@reduxjs/toolkit";
// import projectEvaluationsSlice from "./projectEvaluations-slice";
import featureSlice from "./feature-slice";
import orgChatSlice from "./org-chat/orgChat-slice";
import noiseReductionSlice from "./noise-reduction/noiseReduction-slice";
import kubernetesControllerSlice from "./kubernetes-controller/kubernetesController-slice";
import objectDetectionSlice from "./object-detection/objectDetection-slice";
import openaiRagchainSlice from "../Components/OrgChat/openaiRagchain/openai-slice";
import googleRagchainSlice from "../Components/OrgChat/googleRagchain/google-slice";
import llamaIndexSlice from "../Components/OrgChat/llama-index/llamaIndex-slice";
import semanticRetrieverSlice from "../Components/OrgChat/SemanticRetriever/semanticRetriever-slice";
import manualTranscribeSlice from "../Components/Transcription/ManualTranscribe/manualTranscribe-slice";
import transcriptionSlice from "../Components/Transcription/Transcription/transcription-slice";
import ACESlice from "../Components/Transcription/ACE/ACE-slice";

import alertSlice from "../Components/Alert/alertSlice";
import userSlice from "../Components/Login/userSlice";

const store = configureStore({
  reducer: {
    featureData: featureSlice.reducer,
    orgChatData: orgChatSlice.reducer,
    noiseReductionData: noiseReductionSlice.reducer,
    kubernetesControllerData: kubernetesControllerSlice.reducer,
    objectDetectionData: objectDetectionSlice.reducer,

    openaiRagchainData: openaiRagchainSlice.reducer,
    googleRagchainData: googleRagchainSlice.reducer,
    llamaIndexData: llamaIndexSlice.reducer,
    semanticRetrieverData: semanticRetrieverSlice.reducer,
    
    manualTranscribeData: manualTranscribeSlice.reducer,
    transcriptionData: transcriptionSlice.reducer,
    ACEData: ACESlice.reducer,
    
    alertData: alertSlice.reducer,
    userData: userSlice,
    // userData: userSlice.reducer,

  },
});

export default store;
