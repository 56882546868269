import React from "react";
import { Box, Paper, Avatar, Typography } from "@mui/material";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const MessageItem = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        mb: 2,
        flexDirection: message.sender === "user" ? "row-reverse" : "row",
      }}
    >
      <Avatar sx={{ color: "#000", bgcolor: "transparent" }}>
        {message.sender === "error" ? (
          <ErrorOutlineIcon style={{ color: "#DA0016" }} />
        ) : (
          <RecordVoiceOverIcon
            style={{
              transform: message.sender === "user" ? "scaleX(-1)" : "scaleX(1)",
            }}
          />
        )}
      </Avatar>
      <Paper
        elevation={3}
        sx={{
          p: 2,
          maxWidth: "70%",
          bgcolor:
            message.sender === "user"
              ? "#add8e6"
              : message.sender === "error"
              ? "#DA0016"
              : "#fff",
          borderRadius: "20px",
          marginLeft:
            message.sender === "user" || message.sender === "error" ? 1 : 2,
          marginRight:
            message.sender === "user" || message.sender === "error" ? 2 : 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: message.sender === "error" ? "#fff" : "",
            textAlign:"right",
            direction:"rtl",
            // color: message.sender === "error" ? "#fff" : "",
            // textAlign: message.sender === "user" ? "right" : "left",
            // direction: message.sender === "user" ? "rtl" : "ltr",
          }}
        >
          {message.text}
        </Typography>
      </Paper>
    </Box>
  );
};

export default MessageItem;

// import React from 'react';
// import { Box, Avatar, Paper, Typography } from '@mui/material';
// import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// const MessageItem = ({ message }) => {
//   return (
//     <Box sx={{ display: "flex", mb: 2, flexDirection: message.sender === "user" ? "row-reverse" : "row" }}>
//       <Avatar sx={{ color: "#000", bgcolor: "transparent" }}>
//         {message.sender === "error" ? <ErrorOutlineIcon style={{ color: "#DA0016" }} /> : <RecordVoiceOverIcon />}
//       </Avatar>
//       <Paper elevation={3} sx={{
//         p: 2,
//         maxWidth: "70%",
//         direction: "rtl",

//         bgcolor: message.sender === "user" ? "#add8e6" : "#fff",
//         borderRadius: "20px",
//         marginLeft: message.sender === "user" ? 1 : 2,
//         marginRight: message.sender === "user" ? 2 : 1,
//       }}>
//         <Typography variant="body1" sx={{ textAlign: message.sender === "user" ? "right" : "left" }}>
//           {message.text}
//         </Typography>
//       </Paper>
//     </Box>
//   );
// };

// export default MessageItem;
