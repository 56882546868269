import React from "react";
import { Typography, TextField, Grid, Box } from "@mui/material";

const TranscriptField = ({
  label,
  value,
  onChange,
  md,

}) => {
  return (
    <Grid item xs={12} md={md}>
      <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "end" }}>
        {label}
      </Typography>
        <TextField
          fullWidth
          multiline
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={label}
          sx={{
            direction: "rtl",
            backgroundColor: "transparent",
            "& .Mui-disabled": {
              color: "rgba(0, 0, 0, 0.87)",
              WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
            },
          }}
        />
    </Grid>
  );
};

export default TranscriptField;
