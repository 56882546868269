import React from "react";
import { Box, Button, Grid } from "@mui/material";
import AudioUploadButton from "../Buttons/AudioUploadButton";

const ActionButtons = ({
  onSave,
  onUnableToTranscribe,
  fileInputRef,
  handleAudioFileUpload,
  disabledSaveTranscript,
  disabledUploadButton,
  tooltipMessage,
}) => (
  <Box mt={3} display="flex" justifyContent="space-between">
    <Grid container spacing={3} mt={0}>
      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          sx={{ textTransform: "capitalize", width: "100%" }}
          // disabled={disabledSaveTranscript}
        >
          Save a transcript
        </Button>
      </Grid>
      <Grid item xs={12} md={4}>
        <Button
          variant="contained"
          color="error"
          onClick={onUnableToTranscribe}
          sx={{ textTransform: "capitalize", width: "100%" }}
        >
          I couldn't transcribe
        </Button>
      </Grid>
      <Grid item xs={12} md={4}>
        <AudioUploadButton
          fileInputRef={fileInputRef}
          handleAudioFileUpload={handleAudioFileUpload}
          disabledUploadButton={disabledUploadButton}
          tooltipMessage={tooltipMessage}
        />
      </Grid>
    </Grid>
  </Box>
);

export default ActionButtons;
