import { ColorModeContext, useMode } from "./Pages/Global/Config/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import Chat from "./Pages/Chat";
import Reduction from "./Pages/Reduction";
import VoiceRecognition from "./Pages/VoiceRecognition";
import RootLayout from "./Pages/Global/Layout/RootLayout";
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import SignLanguage from "./Pages/SignLanguage";
import ObjectDetection from "./Pages/ObjectDetection";
import Transcription from "./Pages/Transcription";

import Login from "./Components/Login/Login";

const router = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <Navigate to="/orgChat" replace /> },
      {
        path: "orgChat",
        // element: <ModelProjects />,
        children: [
          {
            index: true,
            element: <Chat />,
          },
        ],
      },

      {
        path: "noiseReduction",
        children: [
          {
            index: true,
            element: <Reduction />,
          },
        ],
      },
      {
        path: "voiceRecognition",
        children: [
          {
            index: true,
            element: <VoiceRecognition />,
          },
        ],
      },
      {
        path: "signLanguage",
        children: [
          {
            index: true,
            element: <SignLanguage />,
          },
        ],
      },
      {
        path: "objectDetection",
        children: [
          {
            index: true,
            element: <ObjectDetection />,
          },
        ],
      },
      {
        path: "transcription",
        children: [
          {
            index: true,
            element: <Transcription />,
          },
        ],
      },
    ],
  },
]);

function App() {
  const [theme, colorMode] = useMode();

  // let pageName = window.location.pathname.split("/").pop();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            <RouterProvider router={router} />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
