import React, { useRef, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { handleObjectDetectionApiRequest } from "../../store/object-detection/objectDetectionApiHandler";
import { objectDetectionActions } from "../../store/object-detection/objectDetection-slice";

const WhiteIconButton = styled(IconButton)({
  color: "#FFFFFF",
});

const ImageDisplay = () => {
  const dispatch = useDispatch();
  const { imageSrc, fileName,  imageResponseData } =
    useSelector((state) => state.objectDetectionData);
  // const { imageSrc, fileName, imageLoadingState, imageResponseData } =
  //   useSelector((state) => state.objectDetectionData);

  const imageRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const isDataLoaded =
      imageResponseData && Object.keys(imageResponseData).length > 0;

    if (imageRef.current && canvasRef.current && isDataLoaded) {
      const ctx = canvasRef.current.getContext("2d");
      const { width, height } = imageRef.current.getBoundingClientRect();
      canvasRef.current.width = width;
      canvasRef.current.height = height;

      ctx.clearRect(0, 0, width, height);

      Object.entries(imageResponseData).forEach(
        ([key, { text, boundingBox, confidence }]) => {
        // ([key, { text, boundingBox: boundingBox, confidence }]) => {

          if (typeof boundingBox === "string") {
            let parsedBoundingBox;
            try {
              parsedBoundingBox = JSON.parse(boundingBox).flat();
            } catch (error) {
              console.error(`Error parsing BoundingBox for key ${key}:`, error);
              return;
            }

            const [startX, startY, endX, endY] = parsedBoundingBox;

            const scaleX = width / imageRef.current.naturalWidth;
            const scaleY = height / imageRef.current.naturalHeight;

            ctx.beginPath();
            ctx.rect(
              startX * scaleX,
              startY * scaleY,
              (endX - startX) * scaleX,
              (endY - startY) * scaleY
            );
            ctx.lineWidth = 4;
            ctx.strokeStyle = "yellow";
            ctx.stroke();

            ctx.fillStyle = "yellow";
            ctx.font = "bold 18px Arial";
            ctx.fillText(
              `Text: ${text}`,
              startX * scaleX,
              startY * scaleY - 10
            );
            ctx.fillText(
              `Confidence: ${parseFloat(confidence).toFixed(2)}%`,
              startX * scaleX,
              startY * scaleY - 25
            );
          } else {
            console.error(`Raw BoundingBox for key ${key}:`, boundingBox);
          }
        }
      );
    }
  }, [imageSrc, imageResponseData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      handleObjectDetectionApiRequest({ type: "uploadImage", formData })
    );

    if (file) {
      const imageSrc = URL.createObjectURL(file);
      dispatch(objectDetectionActions.setImageSrc(imageSrc));
      dispatch(objectDetectionActions.setFileName(file.name));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        margin: "5rem 0rem 0rem 0rem",
      }}
    >
      <Box
        sx={{
          position: "relative", // Parent needs to be relative
          display: "inline-block", // This ensures the container fits the content size
        }}
      >
        <img
          ref={imageRef}
          src={imageSrc}
          alt="Uploaded"
          style={{
            maxWidth: "100%",
            maxHeight: "400px",
            display: "block", // Make sure the image is not stretching beyond its aspect ratio
          }}
        />
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            maxWidth: "100%",
            maxHeight: "400px", // Ensure canvas does not exceed the image dimensions
            pointerEvents: "none", // Allows clicks to pass through to elements underneath
          }}
        />
      </Box>

      <Box
        sx={{
          flex: 1,
          p: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
            p: 1,
            backgroundColor: "#0277bd",
            border: "2px solid black",
            borderRadius: "9px",
            width: "fit-content",
          }}
        >
          <WhiteIconButton color="primary" component="label">
            <CloudUploadIcon />
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileChange}
            />
          </WhiteIconButton>
          <Typography sx={{ color: "#FFFFFF", marginLeft: "8px" }}>
            {fileName || "No file selected"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageDisplay;
