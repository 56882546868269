import { createAsyncThunk,  } from "@reduxjs/toolkit";
import axios from "axios";

const linkMap = {
  // Organizational Chatbot Links
  "RAG langchain with OpenAI": "openaiLangChain",
  "RAG langchain with Google": "googleLangChain",
  "Llama Index": "llamaIndex",
  "Google Document Search": "googleDocumentSearch",
  "Semantic Retriever": "semanticRetriever",
};

export const handleNoiseReductionApiRequest = createAsyncThunk(
  "api/handleNoiseReductionApiRequest",
  async ({ type, formData = null, message = "", noiseReductionType = null, index = null }, thunkAPI) => {
    const link = thunkAPI.getState().featureData.link; // Assume you have a link in your api slice's state
    const userUniqueId = thunkAPI.getState().apiData.userUniqueId; // Assume you have a link in your api slice's state
    let url;
    let config = {};
    let payload;

    switch (type) {
      case "reduceNoise":
        url = `${link}`;
        config.headers = { "Content-Type": "multipart/form-data" };
        payload = formData;
        break;

        
      case "uploadFiles":
        url = `${link}/uploadfiles`;
        config.headers = { "Content-Type": "multipart/form-data" };
        payload = formData;
        break;



      default:
        throw new Error("Unsupported API request type");
    }
    const response = await axios.post(url, payload, config);
    return { type, data: response.data, noiseReductionType, index };
  }
);
