import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Box, Paper } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import AudioFile from "./AudioFile";
import { useDispatch, useSelector } from "react-redux";
import { noiseReductionActions } from "../../store/noise-reduction/noiseReduction-slice";
import { handleNoiseReductionApiRequest } from "../../store/noise-reduction/noiseReductionApiHandler";
import { linkMap } from "../../Pages/Global/Config/linkMapUtils";

const ReductionComponent = () => {
  const dispatch = useDispatch();

  const { selectedFeature } = useSelector((state) => state.featureData);
  const [audioFiles, setAudioFiles] = useState({
    autoEncoder: [],
    spectralGating: [],
  });
  // const noiseReductionType = linkMap[selectedFeature];

  const [noiseReductionType, setNoiseReductionType] = useState("")

  useEffect(() => {
    // dispatch(orgChatActions.setDBToUse("local"));
    setNoiseReductionType(linkMap[selectedFeature])
  }, [selectedFeature]);


  // const handleUpload = (event) => {

  //   const files = event.target.files;
  //   const formData = new FormData();
  //   formData.append("file", files);
  //   const audioFilesArray = Array.from(files).map((file) => {
  //     const fileId = Date.now().toString(); // Generate a unique file ID
  //     sessionStorage.setItem(fileId, file); // Save the actual File object to session storage
  //     return {
  //       url: URL.createObjectURL(file),
  //       name: file.name,
  //       processing: false,
  //       processedUrl: "",
  //       fileId: fileId,
  //     };
  //   });
  //   dispatch(
  //     noiseReductionActions.addAudioFiles({
  //       noiseReductionType,
  //       files: audioFilesArray,
  //     })
  //   );

  //   console.log(audioFiles);
  //   event.target.value = null;
  // };

  const handleUpload = (event) => {
    const files = event.target.files;
    const audioFilesArray = Array.from(files).map((file) => {
      return {
        url: URL.createObjectURL(file),
        name: file.name,
        processing: false,
        processedUrl: "",
        audioFile: file
      };
    });
    setAudioFiles((prevState) => ({
      ...prevState,
      [noiseReductionType]: [
        ...prevState[noiseReductionType],
        ...audioFilesArray,
      ],
    }));
    event.target.value = null;
  };


  // const handleUpload = (event) => {
  //   const files = event.target.files;
  //   const audioFilesArray = Array.from(files).map((file) => ({
  //     url: URL.createObjectURL(file),
  //     name: file.name,
  //     processing: false,
  //     processedUrl: "",
  //     audioFile: file
  //   }));
  //   setAudioFiles([
  //     ...state.audioFiles[noiseReductionType],
  //     ...audioFilesArray,
  //   ]);
  // event.target.value = null;
  // };

  const handleDelete = (index) => {
    setAudioFiles((prevState) => {
      const updatedFiles = prevState[noiseReductionType].filter(
        (_, i) => i !== index
      );
      // const fileId = prevState[noiseReductionType][index].fileId;
      // sessionStorage.removeItem(fileId); // Remove the File object from session storage
      return {
        ...prevState,
        [noiseReductionType]: updatedFiles,
      };
    });
  };

  // const handleDelete = (index, noiseReductionType) => {
  //   const fileId = audioFiles[noiseReductionType][index].fileId;
  //   sessionStorage.removeItem(fileId); // Remove the File object from session storage
  //   dispatch(
  //     noiseReductionActions.deleteAudioFile({ index, noiseReductionType })
  //   );
  // };

  // const handleDelete = (index) => {
  //   setAudioFilesState(audioFilesState.filter((_, i) => i !== index));
  //   // Optionally revoke the URL to free up memory
  //   // URL.revokeObjectURL(audioFilesState[index].url);
  // };

  return (
    <Box sx={{ flexGrow: 1, width: "100%", margin: "auto" }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Paper
          elevation={3}
          sx={{
            margin: "1rem 0rem 0rem 1rem ",
            padding: "1rem 1rem 1rem 1rem ",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // alignItems: "center", // Vertically center the content
            width: "100%", // Utilize all the space in the row
          }}
        >
          {/* File name */}
          <Typography variant="subtitle1" sx={{ flex: 1, pr: 5 }}>
            File Name
          </Typography>

          {/* Original file player */}
          <Typography variant="subtitle1" sx={{ flex: 1, pr: 0 }}>
            Original File
          </Typography>

          {/* Reduce Noise button or processed file player */}
          <Typography variant="subtitle1" sx={{ flex: 1, pr: 0 }}>
            Noise Reduced File
          </Typography>

          {/* Delete button */}
          {/* <Typography variant="subtitle1" sx={{ flex: 1, pr:0  }}></Typography> */}
        </Paper>

        {audioFiles[noiseReductionType] && audioFiles[noiseReductionType].map((file, index) => (
          <AudioFile
            index={index}
            file={file}
            onDelete={() => handleDelete(index)}
            noiseReductionType={linkMap[selectedFeature]}
            // Pass additional props as needed for processing
          />
        ))}

        {/* Adjusted Grid item for the button to be always centered */}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", mt: 2 }}
        >
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadIcon />}
            sx={{ textTransform: "none" }} // Prevents all caps
          >
            Upload New Sound File
            <input
              type="file"
              hidden
              accept="audio/mp3,audio/wav"
              multiple
              onChange={handleUpload}
            />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReductionComponent;
