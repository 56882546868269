import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { handleOrgChatApiRequest } from "../store/org-chat/orgChatApiHandler";
import ThreeSectionLayout from "../Components/Layout/ThreeSectionLayout";
import ChatComponent from "../Components/OrgChat/ChatComponent";

import langChain from "../Pages/Global/Icons/langChain.webp";
import RAGLangChain from "../Pages/Global/Icons/RAGLangChain.webp";
import LLAMA from "../Pages/Global/Icons/LLAMA.webp";
import GoogDocSearch from "../Pages/Global/Icons/GoogDocSearch.webp";
import GoogSimRet from "../Pages/Global/Icons/GoogSimRet.webp";
import { orgChatActions } from "../store/org-chat/orgChat-slice";
import { linkMap } from "./Global/Config/linkMapUtils";
import OpenAIRagachain from "../Components/OrgChat/openaiRagchain/OpenAIRagachain";
import GoogleRagachain from "../Components/OrgChat/googleRagchain/GoogleRagachain";
import LlamaIndex from "../Components/OrgChat/llama-index/LlamaIndex";
import SemanticRetriever from "../Components/OrgChat/SemanticRetriever/SemanticRetriever";

function Chat() {
  const dispatch = useDispatch();
  const { selectedFeature } = useSelector((state) => state.featureData);

  useEffect(() => {
    const cleanup = async () => {
      try {
        // Make an API call to delete the user's history data
        const methods = [
          process.env.REACT_APP_LOCALHOST_SEMANTIC_API ||
            "/semantic-retriever-api/",
          process.env.REACT_APP_LOCALHOST_LLAMA_API || "/llama-index-api/",
          process.env.REACT_APP_LOCALHOST_OTHER_API || "/google-langchain-api/",
          process.env.REACT_APP_LOCALHOST_API || "/openai-langchain-api/",
        ];
        methods.forEach((method) => {
          dispatch(
            handleOrgChatApiRequest({ feature: method, type: "cleanup" })
          );
        });
        console.log("Chat history deleted successfully");
      } catch (error) {
        console.error("Failed to delete chat history:", error);
      }
    };

    // Run the cleanup function when the component is unmounted
    window.addEventListener("beforeunload", cleanup);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  }, []);

  const { selectedVectorDB } = useSelector((state) => state.orgChatData);

  const handleChatFeatureSelect = (feature) => {
    dispatch(orgChatActions.resetChatHistory(linkMap[feature.label]));

    dispatch(handleOrgChatApiRequest({ type: "getHistory" }));
    try {
      dispatch(
        orgChatActions.setSelectedMenuItem(
          selectedVectorDB[linkMap[feature.label]].vectorDBName
        )
      );
      dispatch(
        orgChatActions.setDBToUse(
          selectedVectorDB[linkMap[feature.label]].vectorDBName
        )
      );
    } catch (error) {}
  };

  const leftSection = {
    title: "Features",
    features: [
      {
        label: "RAG langchain with OpenAI",
        image: langChain,
        value: "feature1",
        description: "RAG langchain with OpenAI",
        // state: deployments["Organizational Chatbot"].find(d => d.name === 'RAG langchain with OpenAI')?.state || 'Unknown'
        // state: getStateHandler('RAG langchain with OpenAI')
      },
      {
        label: "RAG langchain with Google",
        image: RAGLangChain,
        value: "feature2",
        // state: getStateHandler('RAG langchain with Google')
      },
      {
        label: "llama Index",
        image: LLAMA,
        value: "feature3",
      },
      {
        label: "Google Documents Search",
        image: GoogDocSearch,
        value: "feature4",
      },
      {
        label: "Google Similarity Retrieval",
        image: GoogSimRet,
        value: "feature5",
      },
    ],
    action: (feature) => handleChatFeatureSelect(feature),
    // action: (feature) => console.log(`${feature.value} clicked1`),
  };

  const middleSection = {
    content:
      selectedFeature === "RAG langchain with OpenAI" ? (
        <OpenAIRagachain />
      ) : selectedFeature === "RAG langchain with Google" ? (
        <GoogleRagachain />
      ) : selectedFeature === "llama Index" ? (
        <LlamaIndex />
      ) : selectedFeature === "Google Similarity Retrieval" ? (
        <SemanticRetriever />
      ): (
        <ChatComponent />
      ),
  };

  // const middleSection = {
  //   content: <OpanAIRagachain />,
  //   // content: <ChatComponent />,
  // };

  const rightSection = {
    title: "Organizational Chatbot",
    content: (
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        sx={{ width: "100%", textAlign: "center" }}
      >
        An organizational chatbot is an AI-powered virtual assistant designed to
        enhance internal communication and collaboration within a company. It
        automates common tasks, answers questions, facilitates discussions, and
        personalizes employee experiences across different departments. By
        integrating with various systems and analyzing usage data, it unlocks
        valuable insights for improving company culture, workflow efficiency,
        and decision-making.
      </Typography>
    ),
  };

  return (
    <>
      <ThreeSectionLayout
        leftSection={leftSection}
        middleSection={middleSection}
        rightSection={rightSection}
      />
    </>
  );
}

export default Chat;
