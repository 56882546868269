import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  IconButton,
  Divider,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoDialog from "../Dialog/InfoDialog";

function SNRScore({ processedData }) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const chunkSizes = processedData.chunkSizes || [];
  const samplingRates = processedData.samplingRates || [];
  //   console.log(processedData)

  return (
    <div>
      <Box sx={{ flexGrow: 1, width: "100%", margin: "auto" }}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Paper
            elevation={0}
            sx={{
              margin: "1rem 0rem 0rem 1rem ",
              padding: "1rem 1rem 1rem 1rem ",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              // alignItems: "center", // Vertically center the content
              width: "100%", // Utilize all the space in the row
            }}
          >
            <Grid
              container
              sx={{ width: "100%" }}
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ flex: 1, pr: 5 }}>
                  Signal-to-Noise Ratio (SNR) Metrics -{" "}
                  {
                    <IconButton size="small" onClick={handleOpenDialog}>
                      <InfoOutlinedIcon
                        style={{
                          color: "blue",
                        }}
                      />
                    </IconButton>
                  }
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ flex: 1, pr: 0 }}>
                  <strong> Original SNR: </strong> {processedData.originalSNRDB}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="subtitle1" sx={{ flex: 1, pr: 0 }}>
                  <strong> Noise Reduced SNR: </strong>
                  {processedData.bestSNRDB}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle1" sx={{ flex: 1, pr: 0 }}>
                  <strong> Best Chunk Size: </strong>
                  {processedData.bestChunkSize}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ flex: 1, pr: 0 }}>
                  <strong> Best Sampling Rate: </strong>{" "}
                  {processedData.bestSamplingRate}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ flex: 1, pr: 0 }}>
                  <strong> Tested Chunk Sizes: </strong>
                  {chunkSizes.join(', ')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" sx={{ flex: 1, pr: 0 }}>
                  <strong> Tested Sampling Rates: </strong>
                  {samplingRates.join(', ')}
                </Typography>
              </Grid>
              {/* <Grid item xs={3}>
                <Typography variant="subtitle1" sx={{ flex: 1, pr: 0 }}>
                 Best Chunk Size: {processedData.bestChunkSize}
                </Typography>
              </Grid> */}
            </Grid>
          </Paper>

          <Grid container justifyContent="center" alignItems="center">
            {/* <IconButton onClick={handleOpenDialog}>
            <InfoOutlinedIcon />
          </IconButton> */}
            <InfoDialog
              open={openDialog}
              onClose={handleCloseDialog}
              title={"Signal-to-Noise Ratio (SNR)"}
            >
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Signal-to-Noise Ratio (SNR) is a critical metric in evaluating
                the audio quality of sound files. It measures the ratio of the
                level of the desired audio signal (such as music or speech) to
                the level of background noise. Expressed in decibels (dB), SNR
                provides a numerical value that helps to assess how much the
                main sound stands out against the noise.
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", mb: 2 }}
              >
                Understanding SNR Scores in Audio Files:
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                <ul>
                  <li>
                    <strong>High SNR</strong> (typically above 20 dB): Indicates
                    that the main audio signal is significantly louder than the
                    background noise. Audio files with high SNR are usually
                    clear and pleasant to listen to because the noise is almost
                    imperceptible.
                  </li>
                  <li>
                    <strong>Moderate SNR</strong> (between 10 dB and 20 dB):
                    Suggests that while the audio signal is louder than the
                    noise, there may be some noticeable background noise. This
                    level of SNR is acceptable for many applications but might
                    not be ideal for high-fidelity or critical listening
                    environments.
                  </li>
                  <li>
                    <strong>Low SNR</strong> (below 10 dB): Implies that the
                    noise level is close to or surpasses the level of the audio
                    signal. Such audio files often sound muddy or hissy, making
                    it difficult to hear the main audio clearly.
                  </li>
                </ul>
              </Typography>
            </InfoDialog>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default SNRScore;
