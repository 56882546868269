import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  ListItem,
  ListItemText,
  Divider,
  Tooltip,
  List,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,

} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { orgChatActions } from "../../../store/org-chat/orgChat-slice";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import AnimatedCloudIcon from "../../../Pages/Global/Icons/AnimatedCloudIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";

const CreateDatastore = ({
  deleteIconStatus,
  deleteFileIconStatuses,
  availableVectorDatastores,
  datastoreName,
  uploadedDocumentsFiles,
  documentLoadStatus,
  cloudUploadStatus,
  handleDatastoreNameChange,
  handleSaveNewDatastore,
  handleSingleFileDelete,
  handleSelectDB,
  disableCreateNewCorpora,
  nameChangeDisabled,
  handleDeleteDB,
}) => {
  const dispatch = useDispatch();
  const { selectedFeature } = useSelector((state) => state.featureData);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [newDatastoreName, setNewDatastoreName] = useState("");
  const [newDatastoreNameError, setNewDatastoreNameError] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    dispatch(orgChatActions.setDBToUse("local"));
    handleSelectDB("local");
  }, [dispatch]);

  useEffect(() => {
    setNewDatastoreName(datastoreName);
  }, [selectedFeature, datastoreName]);

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setNewDatastoreName(newName);
    handleDatastoreNameChange(event);

    const nameExists = availableVectorDatastores.some(
      (db) => db.db_name === newName
    );
    if (nameExists) {
      setNewDatastoreNameError(true);
      setShowTooltip(true);
    } else {
      setNewDatastoreNameError(false);
      setShowTooltip(false);
    }
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    handleDeleteDB(datastoreName); // Call the delete handler passed from props
    handleCloseDeleteDialog();
  };

  // const handleSingleFileDelete = (file_name) => async (event) => {
  //   event.stopPropagation();
  //   dispatch(
  //     orgChatActions.setDeleteFileIconStatus({
  //       fileName: file_name,
  //       status: "loading",
  //     })
  //   );
  // };

  const getDeleteIconForFile = (fileName) => {
    const status = uploadedDocumentsFiles[fileName] || "idle";
    switch (status) {
      case "loading":
        return <CircularProgress size={24} />;
      case "deleting":
        return <CircularProgress size={24} />;
      case "success":
        return <DeleteIcon />;
      case "error":
        return <DeleteIcon color="error" />;
      default:
        return <DeleteIcon />;
    }
  };

  const getTooltipTitle = () => {
    if (!datastoreName) {
      return "Please type a datastore name";
    } else if (cloudUploadStatus === "success") {
      return "Corpus already created";
    } else {
      return "Please upload files";
    }
  };

  const statusDeleteIcon = {
    loading: <CircularProgress size={24} />,
    success: <DeleteIcon color="success" />,
    error: <DeleteIcon color="error" />,
    idle: <DeleteIcon />,
  };

  const statusIcon = {
    loading: <CircularProgress size={24} />,
    success: <CheckCircleIcon color="success" />,
    error: <ErrorIcon color="error" />,
  };

  const isBackupDisabled =
    !newDatastoreName ||
    Object.keys(uploadedDocumentsFiles).length === 0 ||
    newDatastoreNameError;

  return (<>
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={10} sm={6} md={6} lg={4}>
        <Tooltip
          title={
            newDatastoreNameError ? (
              <Box display="flex" alignItems="center">
                <InfoIcon sx={{ mr: 1 }} />
                Choose another name
              </Box>
            ) : (
              ""
            )
          }
          open={showTooltip}
          placement="top"
          arrow
        >
          <TextField
            fullWidth
            label="New Datastore Name"
            variant="outlined"
            value={newDatastoreName}
            onChange={handleNameChange}
            error={newDatastoreNameError}
            disabled={disableCreateNewCorpora || nameChangeDisabled}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={10} sm={6} md={6} lg={5}>
        <FormControl fullWidth>
          <InputLabel id="select-existing-datastore-label">
            {Object.keys(uploadedDocumentsFiles).length > 0
              ? "Uploaded Files"
              : "No Files Uploaded"}
          </InputLabel>

          <Select
            labelId="select-existing-datastore-label"
            id="select-existing-datastore"
            disabled={disableCreateNewCorpora}
            value=""
            label={
              Object.keys(uploadedDocumentsFiles).length > 0
                ? "Uploaded Files"
                : "No Files Uploaded"
            }
          >
            {Object.entries(uploadedDocumentsFiles).map(
              ([fileName, status], index) => (
                <MenuItem key={fileName} value={fileName}>
                  <ListItem sx={{ direction: "rtl", textAlign: "right" }}>
                    <IconButton
                      onClick={handleSingleFileDelete(fileName)}
                      edge="end"
                      aria-label="delete"
                    >
                      {getDeleteIconForFile(fileName)}
                    </IconButton>
                    <ListItemText
                      primary={
                        fileName + (status === "success" ? " - עלה בהצלחה" : "")
                      }
                      secondary={
                        status !== "pending" && status !== "success"
                          ? status
                          : ""
                      }
                      secondaryTypographyProps={{
                        style: {
                          color:
                            status !== "pending" && status !== "success"
                              ? "red"
                              : null,
                        },
                      }}
                    />
                  </ListItem>
                  {index <
                    Object.entries(uploadedDocumentsFiles).length - 1 && (
                    <Divider />
                  )}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={10} sm={6} md={6} lg={3}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: 1,
          }}
        >
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
          <Tooltip
            title={getTooltipTitle()}
            enterDelay={500}
            leaveDelay={200}
            placement="top"
            arrow
          >
            <span>
              <IconButton
                size="small"
                disabled={isBackupDisabled || cloudUploadStatus === "success"}
                onClick={handleSaveNewDatastore}
              >
                <AnimatedCloudIcon status={cloudUploadStatus} />
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            aria-label="delete"
            onClick={() => handleOpenDeleteDialog()}
            // disabled={selectDeleteButtonDisabled}
          >
            <DeleteIcon />
          </IconButton>
          {/* <IconButton>{statusDeleteIcon[deleteIconStatus]}</IconButton>
          {statusIcon[documentLoadStatus] || null} */}
        </Box>
      </Grid>
    </Grid>

<Grid container justifyContent="center" alignItems="center">

<Dialog
  open={openDeleteDialog}
  onClose={handleCloseDeleteDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">
    {"Confirm Deletion"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      <Typography>
        {`Are you sure you want to delete ${datastoreName} Vector Store?
         This action cannot be canceled`}
      </Typography>
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDeleteDialog} color="primary">
      Cancel
    </Button>
    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
      Yes
    </Button>
  </DialogActions>
</Dialog>
</Grid>
</>
  );
};

export default CreateDatastore;
