import React from "react";
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, TextField } from "@mui/material";

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilterControl = ({ name, options, value, onChange }) => {
  if (options === "free-text") {
    return (
      <TextField
        fullWidth
        label={name.charAt(0).toUpperCase() + name.slice(1)}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        margin="normal"
        size="small"
      />
    );
  }

  const optionsArray = Array.isArray(options) ? options : [];

  return (
    <FormControl fullWidth margin="normal" size="small">
      <InputLabel>{name.charAt(0).toUpperCase() + name.slice(1)}</InputLabel>
      <Select
        multiple
        value={Array.isArray(value) ? value : []}
        onChange={(e) => onChange(e.target.value)}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {optionsArray.map((option) => (
          <MenuItem key={option} value={option} sx={{ height: ITEM_HEIGHT }}>
            <Checkbox checked={value.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterControl;