import React, { useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  Button,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import SNRScore from "./SNRScore";

import { useSelector } from "react-redux";
const AudioFile = ({ file, onDelete }) => {
  const { link, selectedFeature, userUniqueId } = useSelector(
    (state) => state.featureData
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const [processedFile, setProcessedFile] = useState(null);
  const [processedData, setProcessedData] = useState(null);

  const formatSNR = (snr) => {
    return `${snr.toFixed(2)} dB`;
  };

  const handleReduceNoise = async () => {
    setIsProcessing(true);
    const formData = new FormData(); // Use FormData to handle file uploads
    formData.append("file", file.audioFile); // Append the file to the form data
    // formData.append("file", file.originFile); // Append the file to the form data
    formData.append("sampling_rate", 8000);
    formData.append("chunk_size", 25000);
    formData.append("unique_user_id", userUniqueId);
    // console.log(formData)
    // console.log(`This is the LINKS => ${link}process`);

    try {
      const response = await axios.post(`${link}process`, formData, {
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log("response");
      // console.log(response);
      const audioUrl = URL.createObjectURL(response.data);
      setProcessedFile(audioUrl);
      if (response.status === 200) {
        const audioBlob = new Blob([response.data], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(audioBlob);

        let jsonData = {};
        try {
          // console.log(`==>> response111111111: ${response}`);
          // console.log(response);
          jsonData = JSON.parse(response.headers["x-json-data"]);
        } catch (error) {
          console.warn("Invalid JSON data in response headers:", error);
        }

        // console.log(`==>> response: ${response}`);
        // console.log(response);
        // console.log(`==>> jsonData: ${jsonData}`);
        // console.log(jsonData);
        setProcessedData({
          audioUrl,
          originalSNRDB: `${jsonData.original_snr.toFixed(2)} dB` || 0,
          originalSNR: jsonData.original_snr || 0,
          bestSNRDB: `${jsonData.best_snr.toFixed(2)} dB` || 0,
          bestSNR: jsonData.best_snr || 0,
          bestChunkSize: jsonData.best_chunk_size || 0,
          bestSamplingRate: jsonData.best_sampling_rate || 0,
          samplingRates: jsonData.sampling_rates || [],
          chunkSizes: jsonData.chunk_sizes || [],
          // metrics: jsonData.metrics || [],
        });

        // console.log("Everything went well!!!");
      }
    } catch (error) {
      console.error("Error processing file:", error);
      // console.log(`==>> response ON ERROR: ${response}`)
      // console.log(response)
      console.log(error);
    }
    // console.log(`==>> response ON ERROR: ${response}`);
    // console.log(response);
    setIsProcessing(false);
  };

  return (
    <Grid item xs={12}>
      <Paper
        elevation={1}
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column", // Change flexDirection to "column" to stack content vertically
          alignItems: "center",
          width: "100%",
        }}
      >
        {/* Main audio file and controls section */}
        <Grid
          container
          sx={{ width: "100%" }}
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={3}>
            <Typography variant="body2">{file.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <audio controls src={file.url} style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={4} sx={{ alignItems: "center" }}>
            {isProcessing ? (
              <CircularProgress size={24} />
            ) : processedFile ? (
              <audio controls src={processedFile} style={{ width: "100%" }} />
            ) : (
              <Button
                onClick={handleReduceNoise}
                disabled={isProcessing}
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                Reduce Noise
              </Button>
            )}
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={onDelete} size="large">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        {/* SNR score section */}
        <Grid item sx={{ width: "100%", mt: 2 }}>
          <SNRScore processedData={processedData ?? {}} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AudioFile;
