import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import { getDiffHighlightedText } from "./highlightUtils";

const TranscriptFieldKareoke = ({
  label,
  value,
  md,
  disable = false,
  currentTime,
  handleWordClick,
  wordsTranscriptTimestamp,
  diff = null,
}) => {
  return (
    <Grid item xs={12} md={md}>
      <Typography variant="subtitle1" gutterBottom sx={{ textAlign: "end" }}>
        {label}
      </Typography>
      <Box
        sx={{
          border: "1px solid #ccc",
          padding: 2,
          borderRadius: 2,
          direction: "rtl",
          backgroundColor: disable ? "inherit" : "transparent",
          whiteSpace: "pre-wrap",
          overflow: "visible",
        }}
      >
        {diff && wordsTranscriptTimestamp?.words
          ? getDiffHighlightedText(
              diff,
              wordsTranscriptTimestamp.words,
              currentTime,
              handleWordClick
            )
          : value}
      </Box>
    </Grid>
  );
};

export default TranscriptFieldKareoke;
