import React, { useMemo, useCallback } from "react";
import {
  Avatar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Divider,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userActions } from "./userSlice";

function GoogleBanner() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { userName, userPicture, userId } = useSelector((state) => state.userData);
  const { selectedFeature } = useSelector((state) => state.featureData);
  const { hoursTranscribed } = useSelector((state) => state.manualTranscribeData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = useCallback((event) => {

    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(userActions.clearUser());
    navigate("/login");
    handleClose();
  }, [dispatch, navigate, handleClose]);

  const menuItems = useMemo(() => [
    <MenuItem key="user" style={{ pointerEvents: "none" }}>
      <b>User: </b>&nbsp; {userName}
    </MenuItem>,
    <Divider key="divider1" />,
    <MenuItem key="email" style={{ pointerEvents: "none" }}>
      <b>Email: </b>&nbsp; {userId}
    </MenuItem>,
    <Divider key="divider2" />,
    selectedFeature === "Manual Transcribe" && (
      <React.Fragment key="transcribe">
        <MenuItem style={{ pointerEvents: "none" }}>
          <b>Hours Transcribed: </b>&nbsp; {hoursTranscribed[userName] || 'N/A'}
        </MenuItem>
        <Divider />
      </React.Fragment>
    ),
    <MenuItem key="logout" onClick={handleLogout} style={{ justifyContent: "center" }}>
      Logout
    </MenuItem>
  ].filter(Boolean), [userName, userId, selectedFeature, hoursTranscribed, handleLogout]);

  const menuProps = useMemo(() => ({
    anchorEl,
    keepMounted: true,
    open: Boolean(anchorEl),
    onClose: handleClose,
    sx: {
      "& .MuiPaper-root": {
        borderRadius: 3,
        marginTop: 1,
        width: "24rem",
        color: "rgb(55, 65, 81)",
        boxShadow:
          "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenuItem-root": {
          "& .MuiSvgIcon-root": {
            fontSize: 18,
            color: "grey",
            marginRight: 1.5,
          },
        },
      },
    },
  }), [anchorEl, handleClose]);


  return (
    <div>
      {isMobile ? (
        <Box sx={{ marginRight: 2 }} width="100%">
          <IconButton onClick={handleClick}>
            <Avatar src={userPicture} alt={userName} />
          </IconButton>
          <Menu {...menuProps}>{menuItems}</Menu>

        </Box>
      ) : (
        <Box
          display="inline-flex"
          alignItems="center"
          width="100%"
          sx={{ borderRadius: 2 }}

        >
          <Button
            id="env-control-button"
            onClick={handleClick}
            variant="contained"

            sx={{
              textTransform: "none",
              width: "100%",
              bgcolor: "#E5EDF2",
              "&:hover": { bgcolor: "#ced5d9" },
              justifyContent: "left",
            }}
          >
            <Avatar
              src={userPicture}
              alt={userName}
              sx={{ width: 30, height: 30 }}
            />
            <Typography
              variant="h5"
              component="div"
              marginLeft={2}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                alignItems: "center",
                color: "black",
              }}
            >
              {userName}
            </Typography>
          </Button>
          <Menu {...menuProps}>{menuItems}</Menu>

        </Box>
      )}
    </div>
  );
}

export default React.memo(GoogleBanner);