import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Skeleton,
} from "@mui/material";
import { useSelector } from "react-redux";

const ObjectTable = () => {
  const { videoElement, videoResponseData, error, loadingTableStatus } =
    useSelector((state) => state.objectDetectionData);

  const navigateToTimestamp = (timestamp) => {
    const [hours, minutes, seconds] = timestamp.split(":").map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    if (videoElement) {
      videoElement.currentTime = totalSeconds;
    }
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: "60vh", overflow: "auto" }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell>Object</TableCell>
            <TableCell>Accuracy</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingTableStatus
            ? // Display Skeleton loaders when data is loading
              [...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))
            : // Display data rows when not loading
              videoResponseData.map((row, index) => (
                <TableRow
                  key={index}
                  hover
                  onClick={() => navigateToTimestamp(row.timestamp)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>{row.timestamp}</TableCell>
                  <TableCell>{row.object}</TableCell>
                  <TableCell>{row.accuracy}</TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ObjectTable;
