// AnimatedCloudIcon.js
import React from "react";
import { keyframes } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const FloatingAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const ArrowAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
`;

const IconWrapper = styled("div")`
  position: relative;
  display: inline-block;
  animation: ${({ status }) =>
      status === "loading" ? FloatingAnimation : "none"}
    2s ease-in-out infinite;
`;

const AnimatedArrow = styled("div")`
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${ArrowAnimation} 1.5s infinite;
`;

const CloudIcon = styled(CloudUploadIcon)`
  font-size: 30px;
  color: ${({ status }) =>
    status === "loading"
      ? "#1976d2"
      : status === "success"
      ? "#06A77D"
      : status === "error"
      ? "#DA0016"
      : "inherit"};
  transition: color 0.3s ease;
`;

const AnimatedCloudIcon = ({ status }) => {
  return (
    <IconWrapper status={status}>
      <CloudIcon status={status} />
      {status === "loading" && (
        <AnimatedArrow>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="#1976d2"
          >
            <path d="M7 14l5-5 5 5z" />
          </svg>
        </AnimatedArrow>
      )}
    </IconWrapper>
  );
};

export default AnimatedCloudIcon;
