import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const handleOrgChatApiRequest = createAsyncThunk(
  "api/handleOrgChatApiRequest",
  async ({ type, formData = null, message = "", feature = "" }, thunkAPI) => {
    const link = thunkAPI.getState().featureData.link; // Assume you have a link in your api slice's state
    const selectedFeature = thunkAPI.getState().featureData.selectedFeature; // Assume you have a link in your api slice's state
    const dbToUse = thunkAPI.getState().orgChatData.dbToUse; // Assume you have a link in your api slice's state
    const userUniqueId = thunkAPI.getState().featureData.userUniqueId; // Assume you have a link in your api slice's state
    let url;
    let config = {};
    let payload;
    let METHOD = "post";

    switch (type) {
      case "uploadFiles":
        url = `${link}uploadfiles/${userUniqueId}`;
        config.headers = { "Content-Type": "multipart/form-data" };
        payload = formData;
        break;
      case "getHistory":
        METHOD = "get";

        url = `${link}get_history/${userUniqueId}/${dbToUse}`;
        config.headers = { "Content-Type": "multipart/form-data" };
        break;
      //ANCHOR - Send Message
      case "sendMessage":
        url = `${link}interact`;
        console.log(dbToUse)
        if (
          selectedFeature === "RAG langchain with OpenAI" ||
          selectedFeature === "RAG langchain with Google"
        ) {
          payload = {
            userUniqueId: userUniqueId,
            user_input: message.newMessage,
            db_to_use: dbToUse,
          };
        } else {
          payload = {
            userUniqueId: userUniqueId,
            user_input: message.newMessage,
          };
        }

        config.headers = { "Content-Type": "application/json" };
        break;

      case "deleteCorpus":
        url = `${link}delete_corpus`;
        payload = { userUniqueId: userUniqueId };
        config.headers = { "Content-Type": "application/json" };
        break;
      case "deleteSingleFile":
        url = `${link}deletefile`;
        payload = { userUniqueId: userUniqueId, user_input: message };
        config.headers = { "Content-Type": "application/json" };
        break;

      case "deleteChatHistory":
        METHOD = "get";
        if (
          selectedFeature === "RAG langchain with OpenAI" ||
          selectedFeature === "RAG langchain with Google"
        ) {
          url = `${link}delete_chat_history/${userUniqueId}/${dbToUse}`;
        } else {
          url = `${link}delete_chat_history`;
          payload = { userUniqueId: userUniqueId };
        }

        break;
      case "reduceNoise":
        url = link;
        break;
      case "cleanup":
        url = `${feature}cleanup`;
        console.log("CLEAN")
        payload = { userUniqueId: userUniqueId };
        config.headers = { "Content-Type": "application/json" };
        break;
      // case "getDBFiles":
      //   METHOD = "get";
      //   url = `${link}list_db_in_storage`;
      //   console.log(`url => ${url}`)
      //   config.headers = { "Content-Type": "application/json" };
      //   break;

      case "downloadVectorDB":
        payload = { userUniqueId: userUniqueId, user_input: message };
        url = `${link}download_from_storage`;
        config.headers = { "Content-Type": "application/json" };
        break;
      case "uploadVectorDB":
        payload = { userUniqueId: userUniqueId, user_input: message };
        url = `${link}upload_db_to_storage`;
        console.log(`selectedFeature: ${selectedFeature}`);
        config.headers = { "Content-Type": "application/json" };
        break;
      case "getGoogleLangchainAvailableDatastores":
        METHOD = "get";

        const googleLangchainLink =
          process.env.REACT_APP_LOCALHOST_OTHER_API || `/google-langchain-api/`;
        url = `${googleLangchainLink}list_db_in_storage`;
        config.headers = { "Content-Type": "application/json" };
        break;
      case "getOpenaiLangchainAvailableDatastores":
        METHOD = "get";
        const openaiLangchainLink =
          process.env.REACT_APP_LOCALHOST_API || `/openai-langchain-api/`;
        url = `${openaiLangchainLink}list_db_in_storage`;
        config.headers = { "Content-Type": "application/json" };
        break;
      case "getAvailableDatastores":
        METHOD = "get";
        console.log(feature)
        url = `${link}list_db_in_storage`;
        config.headers = { "Content-Type": "application/json" };
        break;
      default:
        throw new Error("Unsupported API request type");
    }

    const response = await axios({
      method: METHOD,
      url: url,
      data: payload,
      ...config,
    });
    return { type, data: response.data, feature };
  }
);
