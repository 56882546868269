import React, { useRef } from "react";
import { Box, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ScrollableContainer = ({ children }) => {
  const scrollRef = useRef(null);

  const scroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <IconButton onClick={() => scroll(-200)} sx={{ zIndex: 1 }}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box
        ref={scrollRef}
        sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': { display: 'none' },
          '-ms-overflow-style': 'none',
          'scrollbarWidth': 'none',
          margin: '0 12px', 
        }}
      >
        <Box sx={{ display: 'flex', padding: '0 44px' }}> {/* Ensure internal padding within the scrollable area */}
          {children}
        </Box>
      </Box>
      <IconButton onClick={() => scroll(200)} sx={{ zIndex: 1 }}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default ScrollableContainer;
