import React from "react";
import { List } from "@mui/material";
import MessageItem from "./MessageItem";
import TypingIndicator from "./TypingIndicator";

const MessageList = ({ chatHistory, chatListRef, botIsTyping, dbToUse }) => {
  const historyHello = [{ text: "שלום, איך אוכל לעזור?", sender: "bot" }];
  const history = chatHistory[dbToUse] || [];


  return (
    <List
      sx={{
        mb: 2,
        overflow: "auto",
        flexGrow: 1,
        maxHeight: "80%",
        bgcolor: "#f0f0f0",
        p: 2,
        borderRadius: "7px",
        
      }}
      ref={chatListRef}
    >
      {historyHello.map((message, index) => (
        <MessageItem key={index} message={message} direction="rtl"/>
      ))}

      {dbToUse !== ""
        ? history.map((message, index) => (
            <MessageItem key={index} message={message}  />
          ))
        : null}

      {/* {history.map((message, index) => (
        <MessageItem key={index} message={message} />
      ))} */}
      {botIsTyping && <TypingIndicator />}
    </List>
  );
};

export default MessageList;

// import React from "react";
// import { List, Box } from "@mui/material";
// import MessageItem from "./MessageItem";
// import TypingIndicator from "./TypingIndicator";

// const MessageList = ({ chatHistory, chatListRef, botIsTyping }) => {
//   return (
//     <List
//       sx={{
//         mb: 2,
//         overflow: "auto",
//         flexGrow: 1,
//         maxHeight: "80%",
//         bgcolor: "#f0f0f0",
//         p: 2,
//         borderRadius: "7px",
//       }}
//       ref={chatListRef}
//     >
//       {chatHistory.map((message, index) => (
//         <MessageItem key={index} message={message} />
//       ))}
//       {botIsTyping && <TypingIndicator />}
//     </List>
//   );
// };

// export default MessageList;
