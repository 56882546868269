import { initializeApp } from "firebase/app";
// import { getAuth, GoogleAuthProvider, signInWithPopup,signInWithCredential } from "firebase/auth";
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithCredential } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider,GoogleAuthProvider, signInWithCredential,signInWithEmailAndPassword };






