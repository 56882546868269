import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch } from "react-redux";
import { useTags } from "../../Hooks/Transcription/useTags";
import { featureActions } from "../../store/feature-slice";
import { manualTranscribeActions } from "../Transcription/ManualTranscribe/manualTranscribe-slice";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const formatTags = (tags) => {
  if (!tags || tags.length === 0)
    return { clientTags: "None", agentTags: "None" };

  const formatSingleTag = (tag) => {
    if (!tag) return "None";

    const fields = [
      { key: "Gender", value: tag.gender },
      { key: "Age", value: tag.age },
      { key: "Accent", value: tag.accent },
      { key: "Conversation Type", value: tag.conversation_type },
      { key: "Subject", value: tag.subject },
      { key: "Emotion", value: tag.emotion },
      { key: "Intention", value: tag.intention },
      { key: "Intention Success", value: tag.intention_success },
    ];

    if (tag.names_or_terms)
      fields.push({ key: "Names or Terms", value: tag.names_or_terms });
    if (tag.double_meaning)
      fields.push({ key: "Double Meaning", value: tag.double_meaning });

    return fields;
  };

  const clientTag = tags.find((tag) => tag.speaker_role === "Client");
  const agentTag = tags.find((tag) => tag.speaker_role === "Agent");

  return {
    clientTags: formatSingleTag(clientTag),
    agentTags: formatSingleTag(agentTag),
  };
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const FileListItem = ({
  file,

  onFileSelect,
  isLoadingDetails,
  filesData,
  formatedWER,
}) => {
  const dispatch = useDispatch();
  const { setTags } = useTags();

  const [open, setOpen] = useState(false);
  const [audioElement, setAudioElement] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const hasErrors =
    file.options_audio_error &&
    Object.values(file.options_audio_error).some((value) => value === true);
  const hasTags = file.tags && file.tags.length > 0;
  const hasTranscriber =
    file.transcriber_username && file.transcriber_username.trim() !== "";
  const { clientTags, agentTags } = formatTags(file.tags);

  useEffect(() => {
    if (open && !audioElement) {
      const audio = new Audio(file.audioSrc);
      setAudioElement(audio);
    }

    return () => {
      if (audioElement) {
        audioElement.pause();
        setAudioElement(null);
      }
    };
  }, [open, file.audio_url, audioElement]);

  const handleExpand = () => {
    setOpen(!open);
    onFileSelect(file._id);
  };

  const handleEdit = () => {
    dispatch(featureActions.setSelectedFeature("Manual Transcribe"));
    const seconderyTranscript =
      filesData[file._id].newTranscript === ""
        ? filesData[file._id].originalTranscript
        : filesData[file._id].newTranscript;
    dispatch(
      manualTranscribeActions.setTranscriptionToEdit({
        audioFilename: file.audio_filename,
        transcriptionId: file._id,
        originalTranscript: filesData[file._id].originalTranscript,
        newTranscript: seconderyTranscript,
        words_transcript_timestamp: file.words_transcript_timestamp,
        audioPath: filesData[file._id].audioSrc,
        audioFilename: file.audio_filename,
        status: file.status,
      })
    );

    setTags(file.tags);
    dispatch(manualTranscribeActions.setAgentTags(file.tags));
  };

  const getErrorList = () => {
    const errors = [];
    const errorMapping = {
      notUnderstandable: "Not Understandable",
      notHebrew: "Not Hebrew",
      backgroundNoise: "Background Noise",
      distorted: "Distorted",
    };

    for (const [key, value] of Object.entries(file.options_audio_error)) {
      if (value === true && key !== "other") {
        errors.push(errorMapping[key] || key);
      }
    }

    if (file.options_audio_error.other && file.options_audio_error.otherText) {
      errors.push(`Other: ${file.options_audio_error.otherText}`);
    }

    return errors;
  };

  const renderTags = (tags, title) => (
    <Box mb={1}>
      <Divider sx={{ my: 2 }} />

      <Typography variant="h4" fontWeight="bold" gutterBottom>
        {title}:
      </Typography>
      {tags === "None" ? (
        <Typography variant="body2">None</Typography>
      ) : (
        <Grid container spacing={1}>
          {tags.map((tag, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Typography variant="body2" component="div">
                <Box component="span" fontWeight="bold">
                  {tag.key}:
                </Box>{" "}
                {tag.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{ "& > *": { borderBottom: "unset", maxWidth: "1px" } }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleExpand}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            maxWidth: "350px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {file.audio_filename}
        </TableCell>
        {!isSmallScreen && (
          <>
            <TableCell
              style={{
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {file.status}
            </TableCell>
            <TableCell
              style={{
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {file.transcriber_username}
            </TableCell>
            <TableCell
              style={{
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {formatDate(
                file.update_time ? file.update_time : file.upload_time
              )}
            </TableCell>
            <TableCell
              style={{
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {formatedWER}
            </TableCell>
          </>
        )}
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {isLoadingDetails ? (
                <Typography>Loading...</Typography>
              ) : (
                <>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    {filesData[file._id] && (
                      <audio
                        controls
                        src={filesData[file._id].audioSrc}
                        style={{ flexGrow: 1 }}
                      >
                        Your browser does not support the audio element.
                      </audio>
                    )}

                    <Button
                      startIcon={<EditIcon />}
                      onClick={handleEdit}
                      sx={{ ml: 2 }}
                    >
                      Edit
                    </Button>
                  </Box>
                  {isSmallScreen && (
                    <>
                      <Typography variant="body2">
                        Status: {file.status}
                      </Typography>
                      <Typography variant="body2">
                        User: {file.transcriber_username}
                      </Typography>
                      <Typography variant="body2">
                        Last Update:{" "}
                        {formatDate(
                          file.update_time ? file.update_time : file.upload_time
                        )}
                      </Typography>
                    </>
                  )}

                  {filesData[file._id] && (
                    <>
                      <Divider sx={{ my: 2 }} />
                      <Typography
                        variant="body2"
                        sx={{
                          direction: "rtl",
                          textAlign: "right",
                        }}
                      >
                        <b> תמלול ראשוני:</b> {/* sd */}
                        {filesData[file._id].originalTranscript}
                      </Typography>
                    </>
                  )}

                  {filesData[file._id] &&
                    filesData[file._id].newTranscript !== "" && (
                      <>
                        <Divider sx={{ my: 2 }} />
                        <Typography
                          variant="body2"
                          sx={{
                            direction: "rtl",
                            textAlign: "right",
                          }}
                        >
                          <b>תמלול חדש:</b> {filesData[file._id].newTranscript}
                        </Typography>
                      </>
                    )}

                  {hasErrors && (
                    <>
                      <Typography variant="subtitle2" gutterBottom>
                        Errors Reported:
                      </Typography>
                      <List dense>
                        {getErrorList().map((error, index) => (
                          <ListItem key={index}>
                            <ListItemText primary={error} />
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}

                  {hasTags && (
                    <>
                      {renderTags(agentTags, "Agent Tags")}
                      {renderTags(clientTags, "Client Tags")}
                    </>
                  )}
                  <Divider sx={{ my: 2 }} />

                  {hasTranscriber && (
                    <Typography variant="body2">
                      <b>Transcriber Username:</b> {file.transcriber_username}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default FileListItem;
