import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  IconButton,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoDialog from "../../Dialog/InfoDialog";
import DatastoreSelectItems from "./DatastoreSelectItems";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";

const SelectDatastore = ({
  selectedVectorDB,
  selectedMenuItem,
  availableVectorDatastores,
  loadingAvailableDatastores,
  handleGetDB,
  handleGetDBAvailable,
  handleSelectDB,
  handleDeleteDB,
}) => {
  const [selectButtonDisabled, setSelectButtonDisabled] = useState(true);
  const [selectDeleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
  const [selectButtonText, setSelectButtonText] = useState("Select Datastore");

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const [datastoreValue, setDatastoreValue] = useState(selectedMenuItem);


  useEffect(() => {
    if (selectedMenuItem === selectedVectorDB.vectorDBName) {
      setSelectButtonDisabled(true);
      setDeleteButtonDisabled(false);
      setSelectButtonText("Datastore Selected!");
    } else {
      setSelectButtonDisabled(false);
      setDeleteButtonDisabled(true);
    }
    if (selectedMenuItem === "") {
      setDeleteButtonDisabled(true);
      setDatastoreValue("");
      setSelectButtonText("Select Datastore");
    }
  }, [selectedVectorDB, selectedMenuItem]);

  useEffect(() => {
    handleGetDBAvailable();
    handleSelectDB(selectedMenuItem);
    // console.log(`selectedMenuItem ===> ${selectedMenuItem}`);
  }, [handleGetDBAvailable]);

  const selectDB = (selectedValue) => {
    // console.log(selectedValue);
    handleSelectDB(selectedValue);
    setSelectButtonDisabled(false);
    setSelectButtonText(
      selectedValue === selectedVectorDB.vectorDBName
        ? "Datastore Selected!"
        : "Select Datastore"
    );
  };

  const handleOpenDialog = () => {
    setOpenInfoDialog(true);
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    console.log(` => ${selectedMenuItem}`);
    handleDeleteDB(selectedMenuItem); // Call the delete handler passed from props
    console.log(` => ${selectedMenuItem}`);
    handleCloseDeleteDialog();
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={10} sm={11} md={11} lg={5}>
          <DatastoreSelectItems
            handleSelectDB={selectDB}
            vectorDBName={selectedVectorDB.vectorDBName}
            selectedMenuItem={selectedMenuItem}
            loadingAvailableDatastores={loadingAvailableDatastores}
            availableVectorDatastores={availableVectorDatastores}
            datastoreValue={datastoreValue}
            setDatastoreValue={setDatastoreValue}
          />
        </Grid>
        <Grid item xs={2} sm={1} md={1} lg={1}>
          <IconButton
            size="small"
            onClick={handleOpenDialog}
            disabled={
              !selectedVectorDB.vectorDBFiles ||
              selectedVectorDB.vectorDBFiles.length === 0
            }
          >
            <InfoOutlinedIcon
              style={{
                color:
                  selectedVectorDB.vectorDBFiles &&
                  selectedVectorDB.vectorDBFiles.length > 0
                    ? "blue"
                    : "rgba(0, 0, 0, 0.26)",
              }}
            />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={10}
          sm={11}
          md={11}
          lg={4}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={handleGetDB}
            disabled={selectButtonDisabled}
            sx={{
              textTransform: "none",
              padding: "1rem 2rem",
              width: "100%",
            }}
          >
            {selectButtonText}
          </Button>
        </Grid>
        <Grid
          item
          xs={2}
          sm={1}
          md={1}
          lg={1}
          sx={{
            display: "flex",
            alignItems: "end",
          }}
        >
          <IconButton
            aria-label="delete"
            onClick={() => handleOpenDeleteDialog()}
            disabled={selectDeleteButtonDisabled}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <InfoDialog
          open={openInfoDialog}
          onClose={handleCloseInfoDialog}
          title={selectedVectorDB.vectorDBName}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ textAlign: "center" }}
          >
            Created: {selectedVectorDB.created}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ mb: 2, direction: "rtl" }}>
            קבצים שנרשמו:
          </Typography>
          <List dense sx={{ direction: "rtl" }}>
            {selectedVectorDB.vectorDBFiles?.map((file, index) => (
              <ListItem key={index} sx={{ textAlign: "right" }}>
                <ListItemText primary={file} />
              </ListItem>
            ))}
          </List>
        </InfoDialog>
        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Deletion"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>
                {`Are you sure you want to delete ${selectedVectorDB.vectorDBName} Vector Store?
                 This action cannot be canceled`}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default SelectDatastore;
