import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const NavigationThumbnails = ({ title, imageSrc, navigateTo, highlight }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(navigateTo);
  };

  return (
    <Card
      sx={{
        width: 300,
        m: 1,
        mr: 2,

        border: highlight ? "2px solid blue" : "none", // Highlight border when on the page
        transform: highlight ? "scale(1.12)" : "none", // Scale up when highlighted
        transition: "transform 0.3s ease-in-out", // Smooth transition for scaling
      }}
    >
      <CardActionArea onClick={handleClick}>
        <CardMedia
          component="img"
          height="90"
          image={imageSrc}
          alt={title}
          sx={{
            objectFit: "fit", // Ensure the image fits inside the card
          }}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            textAlign="center"
          >
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default NavigationThumbnails;
