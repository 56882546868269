import React from "react";
import { Box, Button, Typography, Slide } from "@mui/material";
import FilterAccordion from "./FilterAccordion";

const FilterSidebar = ({
  sidebarOpen,
  filters,
  setFilters,
  resetFilters,
  setIsFiltering,
}) => {
  const handleFilterChange = (newFilters) => {
    setIsFiltering(true);
    setFilters(newFilters);
  };

  const handleResetFilters = () => {
    setIsFiltering(true);
    resetFilters();
  };

  return (
    <Slide direction="left" in={sidebarOpen} mountOnEnter unmountOnExit>
      <Box
        sx={{
          width: 300,
          borderRight: 1,
          borderColor: "divider",
          p: 2,
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">Filters</Typography>
          <Button onClick={handleResetFilters}>Reset</Button>
        </Box>
        <FilterAccordion
          title="Agent"
          role="agent"
          filters={filters}
          setFilters={handleFilterChange}
        />
        <FilterAccordion
          title="Client"
          role="client"
          filters={filters}
          setFilters={handleFilterChange}
        />
      </Box>
    </Slide>
  );
};

export default FilterSidebar;
