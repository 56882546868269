import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Skeleton,
} from "@mui/material";
import { useSelector } from "react-redux";

const ImageObjectTable = () => {
  const {  imageResponseData, error, loadingTableStatus } =
    useSelector((state) => state.objectDetectionData);
  // const { videoElement, imageResponseData, error, loadingTableStatus } =
  //   useSelector((state) => state.objectDetectionData);



  // const handleUpload = (event) => {
  //   const files = event.target.files;
  //   const newFiles = Array.from(files).map((file) => ({
  //     name: file.name,
  //     url: URL.createObjectURL(file),
  //     audioFile: file,
  //   }));
  //   setAudioFiles([...audioFiles, ...newFiles]);
  // };

  // const navigateToTimestamp = (timestamp) => {
  //   const [hours, minutes, seconds] = timestamp.split(":").map(Number);
  //   const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  //   if (videoElement) {
  //     videoElement.currentTime = totalSeconds;
  //   }
  // };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: "60vh", overflow: "auto" }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {/* Updated table headers */}
            <TableCell>Text</TableCell>
            <TableCell>Confidence</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingTableStatus
            ? // Display Skeleton loaders when data is loading
              [...Array(5)].map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))
            : // Display data rows when not loading
              imageResponseData.map((row, index) => (
                <TableRow key={index} style={{ cursor: "pointer" }}>
                  <TableCell>{row.text}</TableCell>{" "}
                  {/* Updated to display text */}
                  <TableCell>{row.confidence}</TableCell>{" "}
                  {/* Display confidence */}
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ImageObjectTable;
