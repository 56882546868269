import React from "react";
import {  Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

function LoginBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        position="relative"
        overflow="hidden"
        style={{
          background:
            "linear-gradient(119deg, rgba(3, 76, 198, 0.60) 11.38%, rgba(21, 97, 223, 0.60) 35.81%, rgba(143, 183, 255, 0.60) 86.00%), #1561DF",
          height: isMobile ? "15rem" : "20rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} md="auto" lg="auto">
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={12} md="auto" lg="auto">
              <Typography
                variant={isMobile ? "h3" : "h1"}
                align="center"
                color="white"
                style={{ fontWeight: 600, fontSize: isMobile ? 40 : 70 }}
              >
                Innovation Lab
              </Typography>
            </Grid>
          </Grid>
          <Typography
            align="center"
            sx={{
              color: "white",
              fontSize: isMobile ? "1rem" : "1.5rem",
              fontWeight: 100,
              marginTop: "1rem",
            }}
          >
            Kaleidoo employees - login with your Kaleidoo email - "user_name"@kaleidoo.ai
          </Typography>
        </Grid>
        <Grid item xs={12} md="auto" lg="auto"></Grid>
      </Grid>
      <Grid
        container
        spacing={12}
        position="absolute"
        top="52%"
        zIndex={1}
        justifyContent="space-between"
        style={{
          width: "100%",
          justifyContent: "center",
        }}
      >

      </Grid>
    </>
  );
}

export default LoginBar;
