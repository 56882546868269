import React, { useState, useEffect } from "react";
import LoginBar from "./LoginBar";
import jwt_decode from "jwt-decode";
import { userActions } from "./userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  Alert,
  Paper,
  Divider,
  Typography,
} from "@mui/material";
import {
  auth,
  signInWithCredential,
  GoogleAuthProvider,
} from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  function handleCallbackResponse(response) {
    var userObject = response.credential;
    var user = jwt_decode(response.credential);

    // Firebase sign-in with Google credential
    const credential = GoogleAuthProvider.credential(userObject);

    signInWithCredential(auth, credential)
      .then((result) => {
        const firebaseUser = result.user;

        dispatch(
          userActions.setUser({
            userId: firebaseUser.email,
            userToken: userObject,
            userName: firebaseUser.displayName,
            userPicture: firebaseUser.photoURL,
            userAuth: firebaseUser.emailVerified,
          })
        );

        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing in with Firebase: ", error);
        setError("Google sign-in failed. Please try again.");
      });
  }

  function handleGoogleSignIn() {
    if (window.google && window.google.accounts && window.google.accounts.id) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GCP_CLIENT_ID,
        callback: handleCallbackResponse,
      });

      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outline",
        size: isMobile ? "medium" : "large",
        shape: "rectangular",
        width: isMobile ? 300 : 900,
      });
      google.accounts.id.prompt();
    } else {
      setTimeout(handleGoogleSignIn, 100);
    }
  }

  function handleEmailSignIn(event) {
    event.preventDefault();
    setError(null); // Reset error state

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        navigate("/");
        const displayName = email.split("@")[0];
        const user = userCredential.user;
        dispatch(
          userActions.setUser({
            userId: email,
            userToken: user.accessToken,
            userName: displayName,
            userPicture: user.photoURL,
            userAuth: true,
          })
        );

        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing in with email and password: ", error);
        console.log(error);
        setError("Invalid email or password. Please try again.");
      });
  }

  function handelCallbackResponse(response) {
    var userObject = response.credential;
    var user = jwt_decode(response.credential);

    dispatch(
      userActions.setUser({
        userId: user.email,
        userToken: userObject,
        userName: user.name,
        userPicture: user.picture,
        userAuth: user.email_verified,
      })
    );

    navigate("/");
  }

  function handleGoogleSignIn() {
    if (window.google && window.google.accounts && window.google.accounts.id) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GCP_CLIENT_ID,
        callback: handelCallbackResponse,
      });

      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outline",
        size: isMobile ? "medium" : "large",
        shape: "rectangular",
        width: isMobile ? 300 : 900,
      });
      google.accounts.id.prompt();
    } else {
      setTimeout(handleGoogleSignIn, 100);
    }
  }

  useEffect(() => {
    /* global google */
    handleGoogleSignIn();
  }, []);

  return (
    <>
      <LoginBar />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#797D83",
          padding: isMobile ? "2rem 1rem" : "2rem 2rem",
          flexDirection: "column",
        }}
      >
        <Paper
          elevation={2}
          sx={{
            // padding: 2,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: isMobile ? "2rem 1rem" : "2rem 0rem",
            width: "55%",
            width: isMobile ? "100%" : "75%",
            my: { xs: 2, sm: 0 },
          }}
        >
          <Typography variant="h4" gutterBottom>
            - Kaleidoo Users -
          </Typography>
          <Divider />
          <div
            id="signInDiv"
            style={{
              transform: isMobile ? "scale(1)" : "scale(1.4)",
              marginTop: "1rem",
            }}
          ></div>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h4" gutterBottom>
            - Guests -
          </Typography>

          {error && (
            <Alert severity="error" sx={{ marginTop: "1rem" }}>
              {error}
            </Alert>
          )}

          <form
            onSubmit={handleEmailSignIn}
            style={{
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "100%" : "50%",
            }}
          >

            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ marginTop: "2rem" }}
              fullWidth
            />
            <TextField
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginTop: "1rem" }}
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "1rem",textTransform: "none", }}
            >
              Sign in with Email
            </Button>
          </form>
        </Paper>
      </Box>
    </>
  );
}

export default Login;
