import { createSlice } from "@reduxjs/toolkit";
import { handleNoiseReductionApiRequest } from "./noiseReductionApiHandler";

// const handleFormData = (files) => {
//   const formData = new FormData();
//   const fileArray = Array.from(files);
//   fileArray.forEach((file) => formData.append("files", file));
//   return formData;
// };

const noiseReductionSlice = createSlice({
  name: "noiseReductionHandler",
  initialState: {
    audioFiles: {
      autoEncoder: [],
      spectralGating: [],
    },

    files: [],
    uploadStatus: "idle", // 'loading', 'success', 'error'
    loading: false,
    chatHistory: [{ text: "שלום, איך אוכל לעזור?", sender: "bot" }], // Change to chatHistory
    ratings: {}, // Change to Voice recognition Rating
    processing: false,
    botIsTyping: false,
    userUniqueId: "",
    deleteIconStatus: "idle",

    // uploadStatus: "idle", // 'idle', 'success', 'error'
  },
  reducers: {
    setAudioFiles: (state, action) => {
      state.audioFiles[action.payload.noiseReductionType] =
        action.payload.files;
    },
    addAudioFiles: (state, action) => {
      const { noiseReductionType, files } = action.payload;

      const audioFilesArray = Array.from(files).map((file) => ({
        url: URL.createObjectURL(file),
        name: file.name,
        processing: false,
        processedUrl: "",
        // audioFile: file, // Save the actual file object
      }));


      
      state.audioFiles[noiseReductionType] = [
        ...state.audioFiles[noiseReductionType],
        ...audioFilesArray,
      ];
    },
    deleteAudioFile: (state, action) => {
      state.audioFiles[action.payload.noiseReductionType] = state.audioFiles[
        action.payload.noiseReductionType
      ].filter((_, i) => i !== action.payload.index);
    },

    addFiles: (state, action) => {
      state.files = action.payload.files;
    },
    setUploadStatus: (state, action) => {
      state.uploadStatus = action.payload.status;
    },
    updateChatHistory: (state, action) => {
      state.chatHistory.push({
        text: action.payload.text,
        sender: action.payload.sender,
      });
    },
    resetChatHistory: (state, action) => {
      state.chatHistory = [{ text: "שלום, איך אוכל לעזור?", sender: "bot" }];
      state.botIsTyping = false;
    },
    setBotIsTyping: (state, action) => {
      state.botIsTyping = action.payload;
    },
  },
  extraReducers: (builder) => {
    // const dispatch = useDispatch();

    builder
      .addCase(handleNoiseReductionApiRequest.pending, (state, action) => {
        const { type, noiseReductionType, index } = action.meta.arg;

        switch (type) {
          case "reduceNoise":
            state.audioFiles[noiseReductionType][index].processing = true;
            break;

          case "uploadFiles":
            state.uploadStatus = "loading";
            break;
          case "sendMessage":
            state.botIsTyping = true;
            break;
          case "deleteCorpus":
            state.deleteIconStatus = "loading";
            break;
          case "detectVoice":
            // state.ratings = generateRatings(data);
            break;
          case "reduceNoise":
            // state.processedFileURL = URL.createObjectURL(data);
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }

        console.log("action");
        console.log(action);
        state.loading = true;
      })
      .addCase(handleNoiseReductionApiRequest.fulfilled, (state, action) => {
        const { type, data, noiseReductionType, index } = action.payload;
        state.loading = false;
        // state.uploadStatus = "success";
        switch (type) {
          case "reduceNoise":
            state.audioFiles[noiseReductionType][index].processedUrl =
              URL.createObjectURL(data);
            state.audioFiles[noiseReductionType][index].processing = false;
            break;

         
         
         
         
            case "uploadFiles":
            state.uploadStatus = "success";
            break;
          case "sendMessage":
            state.botIsTyping = false;
            state.chatHistory.push({ text: data, sender: "bot" });
            break;
          case "deleteCorpus":
            state.uploadStatus = "idle";
            console.log("NONOO error");
            console.log(data);
            console.log(action.payload);
            break;
          case "detectVoice":
            // state.ratings = generateRatings(data);
            break;
          case "reduceNoise":
            state.processedFileURL = URL.createObjectURL(data);
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleNoiseReductionApiRequest.rejected, (state, action) => {
        const { type } = action.meta.arg;

        console.error("Error processing request:", action.error);
        switch (type) {
          case "deleteCorpus":
            state.deleteIconStatus = "error";
            console.log("Has an error");
            console.log(type);
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      });
  },
});

export const noiseReductionActions = noiseReductionSlice.actions;

export default noiseReductionSlice;
