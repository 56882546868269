import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete"; // Delete button icon
import { useDispatch, useSelector } from "react-redux";
import { handleOrgChatApiRequest } from "../../store/org-chat/orgChatApiHandler";
import { handleObjectDetectionApiRequest } from "../../store/object-detection/objectDetectionApiHandler";

const VideoList = () => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const { existingFiles } = useSelector((state) => state.objectDetectionData);
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(handleObjectDetectionApiRequest({ type: "get_files_list" }));
  }, [dispatch]);

  // Handler for retry action
//   const handleRetry = (event) => {
//     event.stopPropagation(); // Prevent accordion toggling
//     dispatch(retryUploadAction());
//   };

  // Handler for delete action
  const handleDelete = (file_name) => (event) => {
    const caseKey = `${file_name}`;
    event.stopPropagation(); // Prevent accordion toggling
    switch (caseKey) {
      case "Google Similarity Retrieval-delete_all":
        // dispatch(apiActions.set)
        dispatch(handleOrgChatApiRequest({ type: "deleteCorpus" }));

        break;

      default:
        break;
    }
    // dispatch(deleteFileAction(file_name));
  };
//   const handleSingleFileDelete = (file_name) => (event) => {
//     event.stopPropagation(); // Prevent accordion toggling

//     setDeleteFileIconStatuses((prevStatuses) => ({
//       ...prevStatuses,
//       [file_name]: "loading",
//     }));
//     dispatch(
//       handleOrgChatApiRequest({ type: "deleteSingleFile", message: file_name })
//     );

//     // dispatch(deleteFileAction(file_name));
//   };

//   const statusIcon = {
//     loading: <CircularProgress size={24} />,
//     success: <CheckCircleIcon color="success" />,
//     error: <ErrorIcon color="error" />,
//   };
//   const statusDeleteIcon = {
//     loading: <CircularProgress size={24} />,
//     success: <DeleteIcon color="success" />,
//     error: <DeleteIcon color="error" />,
//     idle: <DeleteIcon />,
//   };
//   const getDeleteIconForFile = (fileName) => {
//     const status = deleteFileIconStatuses[fileName] || "idle";
//     switch (status) {
//       case "loading":
//         return <CircularProgress size={24} />;
//       case "success":
//         return <DeleteIcon color="success" />;
//       case "error":
//         return <DeleteIcon color="error" />;
//       default:
//         return <DeleteIcon />;
//     }
//   };

  const handleVideoClick = (videoName) => {
    // Fetch the signed URL for `videoName` here
    setSelectedFileName(videoName);
    dispatch(
      handleObjectDetectionApiRequest({ type: "get_file", message: videoName })
    );
    setExpanded(false);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{ direction: "rtl", mb: 1, bgcolor: "#f0f0f0", borderRadius: "7px" }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%", gap: 1 }}>
          <Typography>{selectedFileName ? `הקובץ שנבחר -  ${selectedFileName.substring(selectedFileName.indexOf("-") + 1)}` : "קבצים באחסון:"}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ direction: "rtl", textAlign: "right", maxHeight: "50vh", overflowY: "auto" }}>
        <List sx={{ width: "100%" }}>
          {existingFiles.videos.map((videoName, index) => {
            const displayName = videoName.substring(videoName.indexOf("-") + 1);
            const isSelected = videoName === selectedFileName;

            return (
              <React.Fragment key={`${videoName}-${index}`}>
                <ListItem
                  sx={{ direction: "rtl", textAlign: "right", bgcolor: isSelected ? "#cfe8fc" : "inherit" }}
                  button
                  onClick={() => handleVideoClick(videoName)}
                >
                  <ListItemText primary={displayName} />
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(videoName)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
                {index < existingFiles.videos.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
export default VideoList;
