import React from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  Box,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from "@mui/icons-material/Refresh";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileUploader from "./FileUploader";

const MessageInput = ({
  newMessage,
  setNewMessage,
  handleSendMessage,
  handleClearChat,
  handleUploadFile,
  inputFileRef,
  documentLoadStatus,
  linkMap,
  selectedFeature,
  handleFileChange,
  disableCreateNewCorpora,
  datastoreName,
  cloudUploadStatus,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0,
        p: 0,
        direction: "rtl",
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="שלח הודעה..."
        size="small"
        multiline
        minRows={1}
        maxRows={4}
        value={newMessage}
        // autoFocus
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyPress={(e) =>
          e.key === "Enter" && !e.shiftKey && handleSendMessage(e)
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Clear chat history"
                enterDelay={500}
                leaveDelay={200}
              >
                <IconButton onClick={handleClearChat} color="default">
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              <FileUploader
                handleUploadFile={handleUploadFile}
                inputFileRef={inputFileRef}
                documentLoadStatus={documentLoadStatus}
                linkMap={linkMap}
                selectedFeature={selectedFeature}
                handleFileChange={handleFileChange}
                disableCreateNewCorpora={disableCreateNewCorpora}
              />
              <Tooltip
                title={
                  datastoreName === ""
                    ? "Create a Corpus first"
                    : "Send the message"
                }
                enterDelay={500}
                leaveDelay={200}
              >
                <span>
                  <IconButton
                    onClick={handleSendMessage}
                    disabled={newMessage.trim() === "" || cloudUploadStatus !== "success"}
                    // disabled={newMessage.trim() === ""}
                  >
                    <SendIcon style={{ transform: "scaleX(-1)" }} />
                  </IconButton>
                </span>
              </Tooltip>
              {/* <Tooltip
                title="Send the message"
                enterDelay={500}
                leaveDelay={200}
              >
                <IconButton
                  onClick={handleSendMessage}
                  disabled={newMessage.trim() === "" || datastoreName === ""}
                  // disabled={newMessage.trim() === ""}
                >
                  <SendIcon style={{ transform: "scaleX(-1)" }} />
                </IconButton>
              </Tooltip> */}
            </InputAdornment>
          ),
        }}
        dir="rtl"
      />
    </Box>
  );
};

export default MessageInput;
