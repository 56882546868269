import { createSlice } from "@reduxjs/toolkit";
import { handleTranscriptionApiRequest } from "./transcriptionApiHandler";

const transcriptionSlice = createSlice({
  name: "manualTranscribe",
  initialState: {
    transcriptionId: "",
    audioPath: "",
    audioSrcState: "",
    audioFilename: "",
    audioTranscript: "",
    voiceTranscript: "",
    dialogOpen: false,
    microphoneSelected: false,
    isRecording: false,
    audioFileSelected: false,
    audioLoading: false,
    voiceRecordingLoading: false,
    audioError: false,
    voiceRecordingError: false,
    audioErrorMessage: "",
    voiceRecordingErrorMessage: "",
  },
  reducers: {
    setAudioTranscript: (state, action) => {
      state.audioTranscript = action.payload;
    },
    setNewTranscript: (state, action) => {
      state.voiceTranscript = action.payload;
    },
    setDialogOpen: (state, action) => {
      state.dialogOpen = action.payload;
    },
    setMicrophoneSelected: (state, action) => {
      state.microphoneSelected = action.payload;
    },
    setAudioFileSelected: (state, action) => {
      state.audioFileSelected = action.payload;
    },
    setIsRecording: (state, action) => {
      state.isRecording = action.payload;
    },
    setAudioFile: (state, action) => {
      state.audioPath = action.payload.url;
      state.audioFilename = action.payload.filename;
      state.audioSrcState = "success";
    },
    setAudioLoading: (state, action) => {
      state.audioLoading = action.payload;
    },
    setVoiceRecordingLoading: (state, action) => {
      state.voiceRecordingLoading = action.payload;
    },
    setAudioError: (state, action) => {
      state.audioError = action.payload;
    },
    setVoiceRecordingError: (state, action) => {
      state.voiceRecordingError = action.payload;
    },
    setAudioErrorMessage: (state, action) => {
      state.audioErrorMessage = action.payload;
    },
    setVoiceRecordingErrorMessage: (state, action) => {
      state.voiceRecordingErrorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleTranscriptionApiRequest.pending, (state, action) => {
        const { type } = action.meta.arg;

        switch (type) {
          case "get_transcript":
            state.audioFilename = "Loading";
            state.audioTranscript = "Loading";
            state.audioSrcState = "Loading";
            break;
          case "fetch_audio_file":
            // state.audioFilename = "Loading Audio";
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleTranscriptionApiRequest.fulfilled, (state, action) => {
        const { type, data } = action.payload;
        switch (type) {
          case "get_transcript":
            state.audioFilename = data.filename;
            state.transcriptionId = data.transcript_id;
            state.audioTranscript = data.translation;
            state.voiceTranscript = data.translation;
            break;
          case "fetch_audio_file":
            state.audioPath = data;
            state.audioSrcState = "success";
            // console.log("Sdasdasdasdasd");
            break;
          case "save_transcription":
            // console.log(action.meta);
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleTranscriptionApiRequest.rejected, (state, action) => {
        const { type } = action.meta.arg;

        switch (type) {
          case "fetch_audio_file":
            state.audioFilename = "Error fetching file from bucket";
            state.audioSrcState = "error";
            break;
          case "get_transcript":
            // console.log("data");
            state.audioFilename = "Error";
            state.audioTranscript = "Error";
            break;
          case "save_transcription":
            // console.log(action.meta);
            break;
          case "save_error_transcript":
            // console.log(action.meta);
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      });
  },
});

export const transcriptionActions = transcriptionSlice.actions;

export default transcriptionSlice;

// import { createSlice } from "@reduxjs/toolkit";
// import { handleWhisperApiRequest } from "./whisperApiHandler";

// const whisperSlice = createSlice({
//   name: "manualTranscribe",
//   initialState: {
//     transcriptionId: "",
//     audioPath: "",
//     audioSrcState: "",
//     audioFilename: "",
//     originalTranscript: "",
//     newTranscript: "",
//     dialogOpen: false,
//   },
//   reducers: {
//     setOriginalTranscript: (state, action) => {
//       state.originalTranscript = action.payload;
//     },
//     setNewTranscript: (state, action) => {
//       state.newTranscript = action.payload;
//     },
//     setDialogOpen: (state, action) => {
//       state.dialogOpen = action.payload;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(handleWhisperApiRequest.pending, (state, action) => {
//         const { type } = action.meta.arg;

//         switch (type) {
//           case "get_transcript":
//             state.audioFilename = "Loading";
//             state.originalTranscript = "Loading";
//             state.audioSrcState = "Loading";

//             break;
//           case "fetch_audio_file":
//             // state.audioFilename = "Loading Audio";

//             break;

//           default:
//             console.warn(`Unhandled action type: ${type}`);
//         }
//       })
//       .addCase(handleWhisperApiRequest.fulfilled, (state, action) => {
//         const { type, data } = action.payload;
//         switch (type) {
//           case "get_transcript":
//             // state.audioPath = data.audio_path;
//             state.audioFilename = data.filename;
//             state.transcriptionId = data.transcript_id;
//             state.originalTranscript = data.translation;
//             state.newTranscript = data.translation;

//             break;
//           case "fetch_audio_file":
//             state.audioPath = data;
//             state.audioSrcState = "success";

//             break;
//           case "save_transcription":
//             console.log(action.meta);
//             // state.originalTranscript =
//             // state.audioFilename = "Error fetching file from bucket";
//             break;

//           default:
//             console.warn(`Unhandled action type: ${type}`);
//         }
//       })
//       .addCase(handleWhisperApiRequest.rejected, (state, action) => {
//         const { type } = action.meta.arg;

//         switch (type) {
//           case "fetch_audio_file":

//             state.audioFilename = "Error fetching file from bucket";
//             state.audioSrcState = "error";
//             break;
//           case "get_transcript":
//             console.log("data");
//             state.audioFilename = "Error";
//             state.originalTranscript = "Error";

//             break;

//           case "save_transcription":
//             console.log(action.meta);
//             // state.audioFilename = "Error fetching file from bucket";
//             break;
//             case "save_error_transcript":
//               console.log(action.meta);
//               // state.originalTranscript =
//               // state.audioFilename = "Error fetching file from bucket";
//               break;

//           default:
//             console.warn(`Unhandled action type: ${type}`);
//         }
//       });
//   },
// });

// export const whisperActions = whisperSlice.actions;

// export default whisperSlice;
