import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import DatastoreOptions from "../datastores/DatastoreOptions";
import MessageInput from "../ChatComponent/MessageInput";
import MessageList from "../ChatComponent/MessageList";

import { handleLlamaIndexApiRequest } from "./llamaIndexApiHandler";
import { llamaIndexActions } from "./llamaIndex-slice";
import { linkMap } from "../../../Pages/Global/Config/linkMapUtils";

const LlamaIndex = () => {
  const dispatch = useDispatch();

  const {
    chatHistory,
    botIsTyping,
    uploadedDocumentsFiles,
    documentLoadStatus,
    datastoreOption,
    deleteIconStatus,
    deleteFileIconStatuses,
    availableVectorDatastores,
    selectedVectorDB,
    selectedMenuItem,
    loadingAvailableDatastores,
    datastoreName,
    cloudUploadStatus,
    dbToUse,
  } = useSelector((state) => state.llamaIndexData);

  const { selectedFeature } = useSelector((state) => state.featureData);

  const inputFileRef = useRef(null);
  const [newMessage, setNewMessage] = useState("");
  const chatListRef = useRef(null);
  const [newDatastoreNameError, setNewDatastoreNameError] = useState(false);

  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
  }, [chatHistory, botIsTyping]);

  const handleDatastoreNameChange = (event) => {
    const newName = event.target.value;
    dispatch(llamaIndexActions.setDatastoreName({ newName }));
    setNewDatastoreNameError(false);
  };

  const handleSaveNewDatastore = () => {
    const existingDB = availableVectorDatastores.find(
      (db) => db.db_name === datastoreName
    );

    if (existingDB) {
      setNewDatastoreNameError(true);
      return;
    }

    dispatch(
      handleLlamaIndexApiRequest({
        type: "uploadVectorDB",
        message: datastoreName,
      })
    );
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    dispatch(
      llamaIndexActions.updateChatHistory({
        text: newMessage,
        sender: "user",
        dbToUse: dbToUse,
      })
    );
    setNewMessage("");
    dispatch(
      handleLlamaIndexApiRequest({
        type: "sendMessage",
        message: { newMessage },
      })
    );
  };

  const handleClearChat = () => {
    dispatch(handleLlamaIndexApiRequest({ type: "deleteChatHistory" }));
  };

  const handleUploadFile = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const formData = new FormData();
    const filesObject = {};

    for (let i = 0; i < files.length; i++) {
      formData.append(`files`, files[i]);
      filesObject[files[i].name] = "pending";
    }
    dispatch(
      llamaIndexActions.setUploadedDocumentsFiles({
        files: filesObject,
      })
    );

    dispatch(
      handleLlamaIndexApiRequest({
        type: "uploadFiles",
        formData,
        feature: linkMap[selectedFeature],
      })
    );
    event.target.value = null;
  };

  const handleGetDB = () => {
    dispatch(
      handleLlamaIndexApiRequest({
        type: "downloadVectorDB",
        message: selectedVectorDB.vectorDBName,
      })
    );
    dispatch(
      llamaIndexActions.setSelectedMenuItem(selectedVectorDB.vectorDBName)
    );
  };

  const handleLocalDB = useCallback(() => {
    dispatch(
      handleLlamaIndexApiRequest({
        type: "getAvailableDatastores",
      })
    );
  }, [dispatch]);

  const handleGetDBAvailable = useCallback(() => {
    dispatch(
      handleLlamaIndexApiRequest({
        type: "getAvailableDatastores",
      })
    );
  }, [dispatch]);



  const handleSelectDB = (selectedValue) => {
    try {
      const selectedDBFiles = availableVectorDatastores.find(
        (db) => db.db_name === selectedValue
      );
      dispatch(
        llamaIndexActions.updateSelectedVectorDB({
          feature: selectedFeature,
          selectedDB: {
            vectorDBName: selectedValue,
            vectorDBFiles: selectedDBFiles.files,
            created: selectedDBFiles.created,
          },
        })
      );
      dispatch(llamaIndexActions.setDBToUse(selectedValue));
      dispatch(handleLlamaIndexApiRequest({ type: "getHistory" }));
    } catch (error) {
      dispatch(llamaIndexActions.setDBToUse("local"));
    }

  };

  const handleSingleFileDelete = (file_name) => async (event) => {
    event.stopPropagation();
    dispatch(
      handleLlamaIndexApiRequest({
        type: "deleteSingleFile",
        filename: file_name,
      })
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        height: "70vh",
        overflow: "hidden",
      }}
    >
      <DatastoreOptions
        datastoreOption={datastoreOption}
        deleteIconStatus={deleteIconStatus}
        deleteFileIconStatuses={deleteFileIconStatuses}
        datastoreName={datastoreName}
        uploadedDocumentsFiles={uploadedDocumentsFiles}
        documentLoadStatus={documentLoadStatus}
        cloudUploadStatus={cloudUploadStatus}
        loadingAvailableDatastores={loadingAvailableDatastores}
        handleGetDB={handleGetDB}
        handleGetDBAvailable={handleGetDBAvailable}
        handleSelectDB={handleSelectDB}
        availableVectorDatastores={availableVectorDatastores}
        selectedVectorDB={selectedVectorDB}
        selectedMenuItem={selectedMenuItem}
        handleDatastoreNameChange={handleDatastoreNameChange}
        handleSaveNewDatastore={handleSaveNewDatastore}
        newDatastoreNameError={newDatastoreNameError}
        handleSingleFileDelete={handleSingleFileDelete}
        setDatastoreOptions={(value) =>
          dispatch(llamaIndexActions.setDatastoreOption(value))
        }
      />
      <MessageList
        chatHistory={chatHistory}
        chatListRef={chatListRef}
        botIsTyping={botIsTyping}
        dbToUse={dbToUse}
      />
      <MessageInput
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        handleSendMessage={handleSendMessage}
        handleClearChat={handleClearChat}
        handleUploadFile={handleUploadFile}
        inputFileRef={inputFileRef}
        documentLoadStatus={documentLoadStatus}
        selectedFeature={selectedFeature}
        handleFileChange={handleFileChange}
      />
    </Box>
  );
};

export default LlamaIndex;
