import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import ResponsiveNavigation from "../../../Components/Layout/ResponsiveNavigation";
import EnvironmentController from "../../../Components/Layout/EnvironmentController";
import GoogleBanner from "../../../Components/Login/GoogleBanner";



function Topbar(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));



  return (
    <>
    <Grid
      container
      // spacing={8}
      // justifyContent="space-between"
      alignItems="center"
      sx={{
        boxSizing: "border-box",
        background: "#FFFFFF",
        border: "1px solid #E0E0E8",
        padding: "10px",
        
      }}
    >
      <Grid item xs={8} sm={4} md={4} lg={7}>
        <Typography variant="h1" align="left" style={{ fontWeight: 600 }}>
          Innovation Lab
        </Typography>
      </Grid>
      <Grid
        item
        sm={1}
        md={1}
        lg={1}
        // align="right"
        align="left"
        sx={{ display: { sx: "none", } }}

        // align={isMobile ? "center" : "left"}
        // order={isMobile ? 2 : 1}

      >
        {/* <ResponsiveNavigation /> */}
        {/* <EnvironmentController /> */}
      </Grid>
      <Grid
        item
        xs={2}
        sm={3}
        md={4}
        lg={2}
        // align="right"
        // align="center"
        align={isMobile ? "right" : "right"}
        order={isMobile ? 1 : 0}
        
        sx={
          {
            // marginLeft: "10px",
            // marginRight: "10px"
            // paddingRight: "15px"
          }
        }
      >
        {/* <ResponsiveNavigation /> */}
        <GoogleBanner />
      </Grid>
      <Grid
        item
        xs={1}
        sm={4}
        md={3}
        lg={2}
        align="right"
        // align="center"
        sx={
          {
            // marginLeft: "1px"
          }
        }
      >
        {/* <ResponsiveNavigation /> */}
        <EnvironmentController />
      </Grid>
      <Grid
        item
        xs={1}
        sx={{ display: { md: "none", lg: "none", xl: "none" } }}
        // sx={{ display: { md: "none", lg: "none", xl: "none" } }}
      >
        <ResponsiveNavigation />
        {/* <EnvironmentController /> */}
      </Grid>
    </Grid>




    </>
  );
}

export default Topbar;













// <AppBar position="static">
// <Container maxWidth="xl">
//   <Toolbar disableGutters>
//     <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
//     <Typography
//       variant="h6"
//       noWrap
//       component="a"
//       href="#app-bar-with-responsive-menu"
//       sx={{
//         mr: 2,
//         display: { xs: 'none', md: 'flex' },
//         fontFamily: 'monospace',
//         fontWeight: 700,
//         letterSpacing: '.3rem',
//         color: 'inherit',
//         textDecoration: 'none',
//       }}
//     >
//       LOGO
//     </Typography>

//     <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>


//     </Box>
//     <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
//     <Typography
//       variant="h5"
//       noWrap
//       component="a"
//       href="#app-bar-with-responsive-menu"
//       sx={{
//         mr: 2,
//         display: { xs: 'flex', md: 'none' },
//         flexGrow: 1,
//         fontFamily: 'monospace',
//         fontWeight: 700,
//         letterSpacing: '.3rem',
//         color: 'inherit',
//         textDecoration: 'none',
//       }}
//     >
//       LOGO
//     </Typography>
//     {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
//       {pages.map((page) => (
//         <Button
//           key={page}
//           onClick={handleCloseNavMenu}
//           sx={{ my: 2, color: 'white', display: 'block' }}
//         >
//           {page}
//         </Button>
//       ))}
//     </Box> */}

//     <Box sx={{ flexGrow: 0 }}>
//       <Tooltip title="Open settings">
//         <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
//           <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
//         </IconButton>
//       </Tooltip>
//       <Menu
//         sx={{ mt: '45px' }}
//         id="menu-appbar"
//         anchorEl={anchorElUser}
//         anchorOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//         }}
//         keepMounted
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//         }}
//         open={Boolean(anchorElUser)}
//         onClose={handleCloseUserMenu}
//       >
//         {settings.map((setting) => (
//           <MenuItem key={setting} onClick={handleCloseUserMenu}>
//             <Typography textAlign="center">{setting}</Typography>
//           </MenuItem>
//         ))}
//       </Menu>
//     </Box>
//   </Toolbar>
// </Container>
// </AppBar>