import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const handleKubernetesControllerApiRequest = createAsyncThunk(
  "api/handleKubernetesControllerApiRequest",
  async ({ type, deploymentName = null, replicas = 0 }, thunkAPI) => {
    const link = thunkAPI.getState().featureData.controllerLink; // Assume you have a link in your api slice's state
    let url;
    let config = {};
    let payload;
    let METHOD = "post";

    switch (type) {
      case "get_deployments":
        METHOD = "get";
        url = `${link}get-deployments/default`;
        console.log("url")
        console.log(url)
        // config.headers = { "Content-Type": "multipart/form-data" };
        break;
      case "set_replicas":
        METHOD = "post";
        url = `${link}set-replicas`;
        payload = {
          deployment_name: deploymentName,
          namespace: "default",
          replicas: replicas,
        };
        break;


      default:
        throw new Error("Unsupported API request type");
    }
    const response = await axios({
      method: METHOD,
      url: url,
      data: payload,
      ...config,
    });
    return { type, data: response.data,deploymentName };
  }
);
