import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userId: "",
  userToken: "",
  userName: "",
  userPicture: "",
  userAuth: false,
};

const storedUser = JSON.parse(localStorage.getItem("userData"));

const userSlice = createSlice({
  name: "user",
  initialState: storedUser ? { ...initialState, ...storedUser } : initialState,
  reducers: {
    setUser(state, action) {
      const data = action.payload;
      state.userId = data.userId;
      state.userToken = data.userToken;
      state.userName = data.userName;
      state.userPicture = data.userPicture;
      state.userAuth = data.userAuth;
      // Save user data to local storage
      localStorage.setItem("userData", JSON.stringify(state));
    },
    clearUser(state) {
      state.userId = "";
      state.userToken = "";
      state.userName = "";
      state.userPicture = "";
      state.userAuth = false;
      // Remove user data from local storage
      localStorage.removeItem("userData");
    },
    checkAuth(state) {
      const storedUserData = JSON.parse(localStorage.getItem("userData"));
      if (storedUserData) {
        state.userId = storedUserData.userId;
        state.userToken = storedUserData.userToken;
        state.userName = storedUserData.userName;
        state.userPicture = storedUserData.userPicture;
        state.userAuth = storedUserData.userAuth;
      }
    }
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;


// import { createSlice } from "@reduxjs/toolkit";

// const userSlice = createSlice({
//   name: "user",
//   initialState: {
//     userId: "",
//     userToken: "",
//     userName: "Vitali Yosufov jnfsaidygfiusdgfusadnfslgdfslnbgldfsadbnjbaskjdfkjsld",
//     userPicture: "",
//     userAuth: false,
//   },
//   reducers: {

//     setUser(state, action) {
//       const data = action.payload;
//       state.userId = data.userId;
//       state.userToken = data.userToken;
//       state.userName = data.userName;
//       state.userPicture = data.userPicture;
//       state.userAuth = data.userAuth;
//     },
//     clearUser(state) {
//       // new reducer for clearing user data
//       state.userId = "";
//       state.userToken = "";
//       state.userName = "";
//       state.userPicture = "";
//       state.userAuth = false;
//     },

//     // getData(state) {
//     //     state.mode = state.mode
//     //     state.projectName = state.projectName
//     //     state.projectId = state.projectId
//     //     state.creatorUserName = state.creatorUserName
//     //     state.status = state.status
//     //     state.pm_id = state.pm_id
//     //     state.pd_id = state.pd_id
//     // }
//   },
// });

// export const userActions = userSlice.actions;

// export default userSlice;
