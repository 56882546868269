import { createSlice,  } from "@reduxjs/toolkit";
import { handleOrgChatApiRequest } from "./orgChatApiHandler";
import { useDispatch } from "react-redux";

const linkMap = {
  // Organizational Chatbot Links
  "RAG langchain with OpenAI": "openaiLangChain",
  "RAG langchain with Google": "googleLangChain",
  "llama Index": "llamaIndex",
  "Google Documents Search": "googleDocumentSearch",
  "Google Similarity Retrieval": "semanticRetriever",
};

const orgChatSlice = createSlice({
  name: "orgChatHandler",
  initialState: {
    //SECTION - General Organizational Chatbot state
    botIsTyping: false,
    chatHistory: [{ text: "שלום, איך אוכל לעזור?", sender: "bot" }], // Change to chatHistory
    dbToUse: "",
    activeDatabase: "",
    deleteIconStatus: "idle",
    deleteSingleFileIconStatus: "idle",
    deleteFileIconStatuses: {},
    files: {},
    loading: false,
    processing: false,
    uploadStatus: "idle",
    userUniqueId: "",
    // loadingAvailableDatastores: false,
    loadingAvailableDatastores: {
      openaiLangChain: false,
      googleLangChain: false,
    },
    // selectedVectorDB: { vectorDBName: "", vectorDBFiles: [], created: "" },
    selectedMenuItem: "",
    datastoreName: {
      openaiLangChain: "",
      googleLangChain: "",
    },

    uploadedDocumentsFiles: {
      openaiLangChain: {},
      googleLangChain: {},
      llamaIndex: {},
      googleDocumentSearch: {},
      semanticRetriever: {},
    },

    documentLoadStatuss: {
      openaiLangChain: "idle",
      googleLangChain: "idle",
      llamaIndex: "idle",
      googleDocumentSearch: "idle",
      semanticRetriever: "idle",
    },

    cloudUploadStatus: {
      openaiLangChain: "idle",
      googleLangChain: "idle",
      llamaIndex: "idle",
      googleDocumentSearch: "idle",
      semanticRetriever: "idle",
    },

    selectedVectorDB: {
      openaiLangChain: { vectorDBName: "", vectorDBFiles: [], created: "" },
      googleLangChain: { vectorDBName: "", vectorDBFiles: [], created: "" },
    },
    availableVectorDatastores: {
      openaiLangChain: [{ db_name: "", files: [], created: "" }],
      googleLangChain: [{ db_name: "", files: [], created: "" }],
    },
  },
  reducers: {
    setDatastoreName: (state, action) => {
      const { feature, newName } = action.payload;

      if (feature in linkMap) {
        state.datastoreName[linkMap[feature]] = newName;
      }
    },
    setDocumentLoadStatus: (state, action) => {
      const { feature, newStatus } = action.payload;

      if (feature in linkMap) {
        console.log(state.documentLoadStatuss)
        state.documentLoadStatuss[linkMap[feature]] = newStatus;
        console.log(state.documentLoadStatuss)
      }
    },

    setUploadedDocumentsFiles: (state, action) => {
      const { feature, files } = action.payload;
      if (feature in linkMap) {
        state.uploadedDocumentsFiles[linkMap[feature]] = files;
      }
    },
    updateSelectedVectorDB: (state, action) => {
      const { feature, selectedDB } = action.payload;
      state.selectedVectorDB[linkMap[feature]] = selectedDB;
    },

    addFiles: (state, action) => {
      //   state.files = action.payload.files;
      const newFiles = action.payload.files;
      state.files = {
        ...state.files,
        ...newFiles,
      };
      // (state.files = action.payload.files);
      // state.files = { ...state.files, ...action.payload.files }
    },

    setDBToUse: (state, action) => {
      state.dbToUse = action.payload;
      // console.log(state.dbToUse);
    },
    setSelectedMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload;
      // console.log(state.selectedMenuItem);
    },
    setUploadStatus: (state, action) => {
      state.uploadStatus = action.payload.status;
    },
    updateChatHistory: (state, action) => {
      state.chatHistory.push({
        text: action.payload.text,
        sender: action.payload.sender,
      });
    },
    resetChatHistory: (state, action) => {
      const feature = action.payload
      console.log(feature)
      state.chatHistory = [{ text: "שלום, איך אוכל לעזור?", sender: "bot" }];
      state.botIsTyping = false;
    },

    resetLangchain: (state, action) => {
      state.chatHistory = [{ text: "שלום, איך אוכל לעזור?", sender: "bot" }];
      state.botIsTyping = false;
      state.uploadStatus = "idle";
      state.selectedVectorDB = {
        vectorDBName: "",
        vectorDBFiles: [],
        created: "",
      };
      // vectorDB: {vectorDBName:"", vectorDBFiles:[], created:""},
      // state.cloudUploadStatus = "idle"; // 'loading', 'success', 'error'
      state.selectedMenuItem = "";
      state.dbToUse = "";
      state.uploadStatus = "idle";
    },

    //NOTE - set select status

    setBotIsTyping: (state, action) => {
      state.botIsTyping = action.payload;
    },
    setDeleteFileIconStatus: (state, action) => {
      const { fileName, status } = action.payload;
      state.deleteFileIconStatuses[fileName] = status;
    },
    resetDeleteFileIconStatus: (state) => {
      state.deleteFileIconStatuses = {};
    },
  },
  extraReducers: (builder) => {
    // const dispatch = useDispatch();

    builder
      .addCase(handleOrgChatApiRequest.pending, (state, action) => {
        const { type, feature } = action.meta.arg;

        switch (type) {
          case "uploadFiles":
            // state.uploadStatus = "loading";
            state.documentLoadStatuss[feature] = "loading"
            console.log(state.documentLoadStatuss[feature])
            state.deleteIconStatus = "idle";
            break;
          case "sendMessage":
            state.botIsTyping = true;
            break;
          case "deleteCorpus":
            state.deleteIconStatus = "loading";
            break;
          case "deleteSingleFile":
            state.deleteSingleFileIconStatus = "loading";
            break;
          case "deleteChatHistory":
            state.botIsTyping = true;

            break;
          case "getHistory":
            state.botIsTyping = false;
            state.chatHistory = [
              { text: "שלום, איך אוכל לעזור?", sender: "bot" },
            ];
            break;
          case "uploadVectorDB":
            const selectedFeature = action.meta.arg.method;
            // state.cloudUploadStatus = "loading";
            state.cloudUploadStatus[linkMap[selectedFeature]] = "loading";

            break;
          case "getAvailableDatastores":
            state.loadingAvailableDatastores[feature] = true;
            break;

          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleOrgChatApiRequest.fulfilled, (state, action) => {
        const { type, data, feature } = action.payload;
        // state.loading = false;
        // state.uploadStatus = "success";
        switch (type) {
          case "uploadFiles":
            state.uploadStatus = "success";
            state.documentLoadStatuss[feature] = "success"
            state.files = data;

            break;

          case "sendMessage":
            state.botIsTyping = false;
            let sender;
            data.sender === "Chatbot" ? (sender = "bot") : (sender = "error");
            state.chatHistory.push({ text: data.text, sender: sender });
            break;
          case "getHistory":
            state.botIsTyping = false;
            state.chatHistory = data.map((message) => ({
              text: message.text,
              sender: message.sender,
            }));
            break;
          case "deleteCorpus":
            state.deleteIconStatus = "success";
            state.files = [];
            state.uploadStatus = "idle";
            break;
          case "deleteSingleFile":
            state.deleteSingleFileIconStatus = "idle";
            state.files = data;

            const { message } = action.meta.arg;

            if (state.deleteFileIconStatuses.hasOwnProperty(message)) {
              delete state.deleteFileIconStatuses[message];
            }

            break;
          case "deleteChatHistory":
            state.chatHistory = [
              { text: "שלום, איך אוכל לעזור?", sender: "bot" },
            ];
            state.botIsTyping = false;

            break;
          case "cleanup":
            state.chatHistory = [
              { text: "שלום, איך אוכל לעזור?", sender: "bot" },
            ];
            state.botIsTyping = false;
            state.deleteIconStatus = "idle";
            state.deleteSingleFileIconStatus = "idle";
            state.files = [];
            state.uploadStatus = "idle";

            break;
          // case "getDBFiles":
          //   console.log("2")
          //   console.log(data)

          //   state.availableVectorDB = data;
          //   break;
          case "getAvailableDatastores":
            state.availableVectorDatastores[feature] = data;
            state.loadingAvailableDatastores[feature] = false;
            break;
          case "getGoogleLangchainAvailableDatastores":
            state.availableVectorDatastores.googleLangChain = data;
            console.log("1");
            break;
          case "getOpenaiLangchainAvailableDatastores":
            console.log("3");

            state.availableVectorDatastores.openaiLangChain = data;
            break;
          case "uploadVectorDB":
            const selectedFeature = action.meta.arg.method;

            state.cloudUploadStatus[linkMap[selectedFeature]] = "success";

            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleOrgChatApiRequest.rejected, (state, action) => {
        const { type, feature } = action.meta.arg;

        switch (type) {
          case "sendMessage":
            state.botIsTyping = false;
            console.log("rejected action");
            console.log(action);
            // state.chatHistory.push({ text: data, sender: "bot" });
            break;
          case "deleteCorpus":
            state.deleteIconStatus = "error";
            break;
          case "deleteSingleFile":
            console.log("rejected action");

            console.log(action);
            console.log();
            const { message } = action.arg;
            state.deleteFileIconStatuses[message] = "error";

            break;
          case "uploadFiles":
            console.log(action);
            state.documentLoadStatuss[feature] = "error"
            break;
          case "deleteChatHistory":
            state.botIsTyping = false;
            console.log("Error in deleteChatHistory");
            console.log(action);
            break;
          case "uploadVectorDB":
            const selectedFeature = action.meta.arg.method;
            // state.cloudUploadStatus = "loading";
            state.cloudUploadStatus[linkMap[selectedFeature]] = "error";
            // state.cloudUploadStatus["openaiLangChain"] = "error";
            // state.cloudUploadStatus = "error";

            break;
          case "getGoogleLangchainAvailableDatastores":
            state.botIsTyping = false;
            console.log("Error in deleteChatHistory");
            console.log(action);
            break;
          case "getOpenaiLangchainAvailableDatastores":
            state.botIsTyping = false;
            console.log("Error in deleteChatHistory");
            console.log(action);
            break;
          case "getHistory":
            state.botIsTyping = false;
            state.chatHistory = [
              { text: "שלום, איך אוכל לעזור?", sender: "bot" },
            ];
            console.log("Chat history error");
            // state.chatHistory = data.map((message) => ({
            //   text: message.text,
            //   sender: message.sender,
            // }));
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      });
  },
});

export const orgChatActions = orgChatSlice.actions;

export default orgChatSlice;
