import ThreeSectionLayout from "../Components/Layout/ThreeSectionLayout";
import ManualTranscribeComponent from "../Components/Transcription/ManualTranscribe/ManualTranscribeComponent";
import TranscriptionComponent from "../Components/Transcription/Transcription/TranscriptionComponent";
import { Typography } from "@mui/material";

import manualTranscribe from "../Pages/Global/Icons/manualTranscribe.webp";
import whisperTranscription from "../Pages/Global/Icons/whisperTranscription.webp";
import ACEComponent from "../Components/Transcription/ACE/ACEComponent";
import FileManagement from "../Components/Transcription/ManualTranscribe/FileManagement";



import { useSelector } from "react-redux";

function Transcription() {
  const { selectedFeature } = useSelector((state) => state.featureData);


  const leftSection = {
    title: "Features",
    features: [
      {
        label: "Manual Transcribe",
        image: manualTranscribe,
        value: "feature1",
        description: "TBA",
      },
      {
        label: "File Management",
        image: manualTranscribe,
        value: "feature1",
        description: "TBA",
      },
      {
        label: "Transcription",
        image: whisperTranscription,
        value: "feature1",
        description: "TBA",
      },
      {
        label: "ACE",
        image: whisperTranscription,
        value: "feature1",
        description: "TBA",
      },

    ],
    action: (feature) => console.log(`${feature.value} clicked`),
  };

  const middleSection = {
    
    content:
      selectedFeature === "Manual Transcribe" ? (
        <ManualTranscribeComponent />
      ) : selectedFeature === "Transcription" ? (
        <TranscriptionComponent />
      ) : selectedFeature === "File Management" ? (
        <FileManagement />

      ) : selectedFeature === "ACE" ? (
        <ACEComponent />

      ) : (
        <TranscriptionComponent />
      ),
  };
  // const middleSection = {
  //   content:
  //     selectedFeature === "Manual Transcription" ? (
  //       <ManualTranscribeComponent />
  //     ) : selectedFeature === "Transcription" ? (
  //       <TranscriptionComponent />
  //     ) : selectedFeature === "llama Index" ? (
  //       <LlamaIndex />
  //     ) : (
  //       <ChatComponent />
  //     ),
  // };
  // const middleSection = {
  //   content: (
  //       <ManualTranscribeComponent />
  //   ),
  // };

  const rightSection = {
    title: "Transcription",
    content: (
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        sx={{ width: "100%", textAlign: "center" }}
      >
        TBA
      </Typography>
    ),
  };

  return (
    <>
      <ThreeSectionLayout
        leftSection={leftSection}
        middleSection={middleSection}
        rightSection={rightSection}
      />
    </>
  );
}

export default Transcription;
