import { createSlice } from "@reduxjs/toolkit";
import { handleObjectDetectionApiRequest } from "./objectDetectionApiHandler";

// const handleFormData = (files) => {
//   const formData = new FormData();
//   const fileArray = Array.from(files);
//   fileArray.forEach((file) => formData.append("files", file));
//   return formData;
// };

const objectDetectionSlice = createSlice({
  name: "objectDetectionHandler",
  initialState: {
    videoResponseData: [],
    imageResponseData: [],
    videoElement: 0,
    uploadStatus: "idle",
    loadingTableStatus: false,
    error: null,
    videoSrc: "",
    fileName: "No file selected",
    videoLoadingState: false,
    imageSrc: "",
    imageLoadingState: false,
    imageQuestion: "",
    imageAnswer: "",
    existingFiles: { videos: [] },
  },
  reducers: {
    navigateToTimestamp: (state, action) => {
      const timestamp = action.payload;
      const [hours, minutes, seconds] = timestamp.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60 + seconds;
      // if (videoElement) {
      //   videoElement.currentTime = totalSeconds;
      // }
      state.videoElement = totalSeconds;
    },
    resetObjectDetection: (state, action) => {
      state.videoResponseData = [];
      state.loadingTableStatus = false;
      state.error = null;
      state.fileName = "No file selected";
      state.videoSrc = "";
    },
    setVideoSrc: (state, action) => {
      state.videoSrc = action.payload;
    },
    setImageSrc: (state, action) => {
      state.imageSrc = action.payload;
    },
    setFileName: (state, action) => {
      state.fileName = action.payload;
    },
    setImageQuestion: (state, action) => {
      state.imageQuestion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleObjectDetectionApiRequest.pending, (state, action) => {
        const { type } = action.meta.arg;

        switch (type) {
          case "uploadFile":
            state.uploadStatus = "loading";
            state.loadingTableStatus = true;
            break;
          case "process_video":
            // state.videoLoadingState = true
            // const videoLink = "https://storage.googleapis.com/innovation-lab-storage/yolo/d4feb0011a6a4793a4c1a7e78404ebc6-Screen%20Recording%202023-06-12%20at%2017.34.53.mp4?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=readonly-secrets%40innovation-lab-416312.iam.gserviceaccount.com%2F20240327%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240327T092024Z&X-Goog-Expires=600&X-Goog-SignedHeaders=host&X-Goog-Signature=211e19dabc80e07394014ac56a6649861528958d5b5f972a4ae073a95f33cc813ccd8c7729c536c426656cd2b14f2ad270fad53fc76512cf6372b65e52490f12e6684fff2a1117823fa970716768bae77d05311a7a8d5f0718780ac54e077de926008f0359d79776f08b16e7de3b7a4baa4e4ec6f7158528e8b0645ca8288912c15d48e63139d29b271b7514b694252f61943c070f0de69e7ce497ae5c740f3180454c037b1a6cb295bf106efea8685935292b41ace3706e56afa0863540f635bf708886121db91f9cf69e06cbb6d71cde8ece8e9786e33c76ada4cd1c662d5a1101abd3d7646b0d1a34a0626bfcc19cb2487640016259cc9ce272333c5e1dd9"
            // state.videoSrc = videoLink;
            // console.log("From redux ")
            // console.log(videoLink)
            break;
          case "uploadImage":
            // state.videoLoadingState = true
            state.loadingTableStatus = true;
            break;
          case "ask_my_image":
            // state.videoLoadingState = true
            state.imageLoadingState = true;
            break;
          case "get_file":
            // state.imageLoadingState = false;
            // state.imageAnswer = data;
            state.videoSrc = "";

            break;

          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleObjectDetectionApiRequest.fulfilled, (state, action) => {
        const { type, data } = action.payload;

        switch (type) {
          case "uploadFile":
            state.loadingTableStatus = false;
            if (
              !data ||
              typeof data !== "object" ||
              Object.keys(data).length === 0
            ) {
              console.error("Data is empty or not an object:", data);
              return; // Stop processing if data is empty or not as expected
            }

            const formattedData = [];
            const timestamps = Object.values(data).flatMap((d) =>
              Array.isArray(d.timestamps) ? d.timestamps : []
            );

            if (timestamps.length === 0) {
              console.error("No timestamps found in data:", data);
              return; // Stop processing if no timestamps are found
            }

            // const videoStart = Math.min(...timestamps);

            state.uploadStatus = "success";

            Object.entries(data).forEach(([objectName, details]) => {
              if (!Array.isArray(details.timestamps)) {
                console.warn(
                  `Skipping ${objectName}: timestamps is not an array`
                );
                return; // Skip this object if timestamps is not an array
              }

              details.timestamps.forEach((timestamp, index) => {
                // Directly use the timestamp as it's already in hh:mm:ss format
                formattedData.push({
                  timestamp: timestamp,
                  object:
                    objectName.charAt(0).toUpperCase() + objectName.slice(1),
                  accuracy: `${Number(
                    details.confidence / details.frequency
                  ).toFixed(2)}%`, // Assuming you want to average the confidence over occurrences
                });
              });
            });

            formattedData.sort((a, b) =>
              a.timestamp.localeCompare(b.timestamp)
            );
            state.videoResponseData = formattedData;

            break;
            //ANCHOR - process video - complete
          case "process_video":
            //   const videoLink = ""
            //  state.videoSrc = videoLink;
            state.videoSrc = data;
            state.videoLoadingState = false;
            break;

          case "ask_my_image":
            state.imageLoadingState = false;
            state.imageAnswer = data;
            break;
          case "get_files_list":
            state.imageLoadingState = false;
            state.existingFiles = data;
            // console.log("This is the files from gcp")
            // console.log(data["videos"])
            break;

          case "get_file":
            // state.imageLoadingState = false;
            // state.imageAnswer = data;
            console.log(data)
            console.log(data.video)
            console.log(data.json)
            state.videoSrc = data.video;







            state.loadingTableStatus = false;

            if (!data.json || typeof data.json !== "object" || Object.keys(data.json).length === 0) {
              console.error("Data is empty or not an object:", data.json);
              return; // Stop processing if data.json is empty or not as expected
            }
          
            const formattedTableData = [];
            const allTimestamps = [];
          
            // Iterate through each key in the data.json.json object
            Object.entries(data.json).forEach(([key, obj]) => {
              if (!obj.timestamps || !Array.isArray(obj.timestamps)) {
                console.warn(`Skipping ${key}: timestamps is not available or not an array`);
                return; // Skip this object if timestamps is not an array
              }
          
              // Collect all timestamps
              allTimestamps.push(...obj.timestamps);
          
              // Process each timestamp for the current object
              obj.timestamps.forEach((timestamp, index) => {
                formattedTableData.push({
                  timestamp: timestamp,
                  object: key.charAt(0).toUpperCase() + key.slice(1),
                  accuracy: `${(obj.confidence * 100).toFixed(2)}%`,
                  position: obj.positions[index] || []
                });
              });
            });
          
            if (allTimestamps.length === 0) {
              console.error("No timestamps found in data.json");
              return; // Stop processing if no timestamps are found
            }
          
            // Sort the formatted data.json by timestamp
            formattedTableData.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
            
            state.videoResponseData = formattedTableData;
            state.uploadStatus = "success";
            console.log("Processed data.json:", formattedTableData);











            break;

          case "uploadImage":
            state.loadingTableStatus = false;

            if (
              !data.json ||
              typeof data.json !== "object" ||
              Object.keys(data.json).length === 0
            ) {
              state.error = "Data is empty or not an object";
              return; // Stop processing if data.json is empty or not as expected
            }

            // Process the text detection results
            const ImageFormattedData = Object.entries(data.json).map(
              ([key, { Text, "Bounding Box": BoundingBox, Confidence }]) => {
                return {
                  id: key, // Keeping the original key for reference
                  text: Text,
                  boundingBox: BoundingBox, // Assuming you want to keep the bounding box information as is
                  confidence: `${(parseFloat(Confidence) * 100).toFixed(2)}%`, // Convert confidence to percentage
                };
              }
            );

            // Since there's no natural ordering like tableTimestamps here, we might not need to sort, but you could sort by confidence if desired
            // formattedTableData.sort((a, b) => parseFloat(b.confidence) - parseFloat(a.confidence));

            state.imageResponseData = ImageFormattedData;
            state.uploadStatus = "success";
            console.log("ImageFormattedData");
            console.log(ImageFormattedData);

            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleObjectDetectionApiRequest.rejected, (state, action) => {
        const { type } = action.meta.arg;

        switch (type) {
          case "uploadFile":
            console.log(action);
            state.uploadStatus = "error";
            state.loadingTableStatus = false;
            state.error = "Something went wrong";
            console.log("Something went wrong");
            break;
          case "process_video":
            console.log("process_video ERROR");
            console.log(action);
            state.videoLoadingState = false;
            break;

          case "uploadImage":
            state.loadingTableStatus = false;
            break;

          case "ask_my_image":
            state.imageLoadingState = false;
            console.log("This is the error");
            console.log(action);
            break;

            case "get_files_list":
              // state.imageLoadingState = false;
              // state.existingFiles = data.json;
              // console.log("This is the files from gcp")
              // console.log(data.json["videos"])
              console.log("This is the error");
              console.log(action);
              break;

          case "get_file":
            state.videoSrc = "";

            break;

          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      });
  },
});

export const objectDetectionActions = objectDetectionSlice.actions;

export default objectDetectionSlice;

