import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const handleACEApiRequest = createAsyncThunk(
  "api/handleACEApiRequest",
  async ({ type, transcript_id = "",filename="", transcript="", options={} }, thunkAPI) => {
    // const link = thunkAPI.getState().featureData.link; // Assume you have a link in your api slice's state
    const dbToUse = thunkAPI.getState().openaiRagchainData.dbToUse; // Assume you have a link in your api slice's state
    const userUniqueId = thunkAPI.getState().featureData.userUniqueId; // Assume you have a link in your api slice's state
    let url;
    let config = {};
    let payload;
    let METHOD = "post";
    // const link = "http://34.165.214.245:8080/" || "manual-transcribe-api/";
    const link = "manual-transcribe-api/" || "http://34.165.214.245:8080/" ;

    switch (type) {
      case "get_transcript":
        url = `${link}get_transcript`;
        config.headers = { "Content-Type": "application/json" };
        // console.log("Asdasd")

        break;
      case "fetch_audio_file":
        METHOD = "get";
        url = `${link}audio/${transcript_id}`;
        config.headers = { "Content-Type": "multipart/form-data" };
        break;
      case "save_transcription":
        METHOD = "put";
        payload = {
          transcript: transcript,
          filename: filename,
          transcript_id: transcript_id,
        };
        // console.log(payload)

        url = `${link}save_transcript`;
        config.headers = { "Content-Type": "application/json" };
        break;
      case "save_error_transcript":
        METHOD = "put";

        payload = {
          transcript_id: transcript_id,
          options: options,
        };

        // console.log(payload)

        url = `${link}save_error_transcript`;
        config.headers = { "Content-Type": "application/json" };
        break;

      default:
        throw new Error("Unsupported API request type");
    }

    const response = await axios({
      method: METHOD,
      url: url,
      data: payload,
      ...config,
    });
    return { type, data: response.data };
  }
);
