import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Menu,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Divider,
  useMediaQuery,
  useTheme,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { handleKubernetesControllerApiRequest } from "../../store/kubernetes-controller/kubernetesControllerApiHandler";
import { kubernetesControllerActions } from "../../store/kubernetes-controller/kubernetesController-slice";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import InfoDialog from "../../Dialog/InfoDialog";

// const WEBSOCKET_URL = "wss://34.118.227.56:8080/ws";
// const WEBSOCKET_URL = "wss://controller-service.default:8080/ws";
// const WEBSOCKET_URL = 'wss://innovation-lab.it.kaleidoo-dev.com/controller-api/ws'
// const WEBSOCKET_URL = 'wss://controller-service.default.svc.cluster.local:8080/ws'



// const WEBSOCKET_URL = "ws://localhost:8081/ws";

function EnvironmentController() {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { deployments } = useSelector(
    (state) => state.kubernetesControllerData
  );
  const { controllerLink,controllerLinkWS } = useSelector(
    (state) => state.featureData
  );
  const [featureToggleStates, setFeatureToggleStates] = useState({});
  const [expandedSections, setExpandedSections] = useState({});
  const ws = useRef(null);
  const reconnectInterval = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const pathToSection = {
    "/orgChat": "Organizational Chatbot",
    "/noiseReduction": "Noise Reduction",
    "/voiceRecognition": "Voice Recognition",
    "/signLanguage": "Sign Language Translation",
    "/objectDetection": "Object Detection",
    "/transcription": "Transcription",
    // Add other mappings as necessary
  };



  // const WEBSOCKET_URL = `wss://${controllerLinkWS}`;
  // const WEBSOCKET_URL = `ws://controller-api-ws`;
  const WEBSOCKET_URL = `wss://innovation-lab.it.kaleidoo-dev.com/controller-api/ws`;
  // const WEBSOCKET_URL = `ws://34.165.34.232:8080/ws`;
  
  const handleToggleAccordion = useCallback((sectionName) => {
    setExpandedSections((prevSections) => ({
      ...prevSections,
      [sectionName]: !prevSections[sectionName],
    }));
  }, []);

  // useEffect(() => {
  //   dispatch(handleKubernetesControllerApiRequest({ type: "get_deployments" }));
  // }, [dispatch]);

  useEffect(() => {
    const defaultOpenSection = pathToSection[location.pathname];
    if (defaultOpenSection) {
      setExpandedSections({ [defaultOpenSection]: true });
    }
  }, [location.pathname]);

  useEffect(() => {
    const initialFeatureStates = {};
    Object.keys(deployments).forEach((section) => {
      deployments[section].forEach((feature) => {
        initialFeatureStates[feature.name] = {
          isLoading: false,
          isAvailable: feature.state === "Available",
        };
      });
    });
    setFeatureToggleStates(initialFeatureStates);
  }, [deployments]);

  const connectWebSocket = () => {
    ws.current = new WebSocket(WEBSOCKET_URL);

    ws.current.onopen = () => {
      console.log("WebSocket connection established");
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "replica_update") {
        dispatch(
          kubernetesControllerActions.updateFeatureState({
            deploymentsObject: deployments,
            deploymentName: message.data.deployment_name,
            newDeploymentState: message.data.status,
            newReplicas: message.data.replicas,
          })
        );
      } else if (message.type === "deployment_update") {


      } else if (message.type === "ping") {
        ws.current.send(JSON.stringify({ type: "pong" }));
    



      } else {
        console.log("deployment_update");

        dispatch(
          kubernetesControllerActions.updateDeployments(message.deployments)
        );
      }
      // console.log("333333:::::::::::::::::::::::::::::"); // Log received messages
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.current.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
      if (!event.wasClean) {
        console.error("WebSocket closed unexpectedly:", event.reason);
        if (!reconnectInterval.current) {
          reconnectInterval.current = setInterval(connectWebSocket, 5000);
        }
      }
    };
  };

  const sendSetReplicasMessage = (deploymentName, replicas) => {
    if (ws.current) {
      ws.current.send(
        JSON.stringify({
          type: "set_replicas",
          deployment_name: deploymentName,
          replicas: replicas,
        })
      );
    }
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
    };
  }, []);

  const toggleFeature = async (featureName, deploymentInfo) => {
    setFeatureToggleStates((prevState) => ({
      ...prevState,
      [featureName]: { ...prevState[featureName], isLoading: true },
    }));
    let newState =
      deploymentInfo["state"] === "Off" ? "Scaling Up" : "Scaling Down";

    const newReplicaCount = deploymentInfo["replicas"] === 0 ? 1 : 0;

    sendSetReplicasMessage(deploymentInfo.deploymentName, newReplicaCount);
    const handleDialogOpen = () => {
      setDialogOpen(true);
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    try {
      const response = await dispatch(
        handleKubernetesControllerApiRequest({
          type: "set_replicas",
          deploymentName: deploymentInfo.deploymentName,
          replicas: newReplicaCount,
        })
      );
      // ).unwrap();
      // dispatch(
      //   kubernetesControllerActions.updateFeatureState({
      //     deploymentsObject: deployments,
      //     deploymentName: deploymentInfo.deploymentName,
      //     newDeploymentState: response.data.status,
      //     newReplicas: response.data.replicas,
      //   })
      // );
    } catch (error) {
      console.error("Error toggling feature:", error);

    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderFeatureToggle = (feature, showDivider = true) => {
    const { isLoading, isAvailable } = featureToggleStates[feature.name] || {};
    const featureError =
      feature.state === "Error" || feature.state === "CrashLoopBackOff";
    const featureNotFound = feature.replicas === "None";
    const featureOff =
      feature.state === "Off" && parseInt(feature.replicas) === 0;
    // const featureOff =
    //   feature.state === "Off" && parseInt(feature.replicas) === 0;
    const featureAvailable = feature.state == "Available";
    // const featureAvailable =
    //   feature.state === "Available" && parseInt(feature.replicas) > 0;
    const featureState = feature.state;
    const featureScalingUp = feature.state === "Scaling Up";
    const featureScalingDown = feature.state === "Scaling Down";
    // console.log(deployments);
    if (featureNotFound) {
      return null;
    }

    const textColor = featureError ? "red" : "inherit";

    const toggleProps = {
      disabled:
        isLoading || featureError || featureScalingUp || featureScalingDown,
      sx: {
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: featureScalingUp
            ? "green"
            : featureScalingDown
            ? "red"
            : "default",
          "+ .MuiSwitch-track": {
            backgroundColor: featureScalingUp
              ? "green"
              : featureScalingDown
              ? "red"
              : "default",
          },
        },
        "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track": {
          backgroundColor: featureOff
            ? "rgba(255, 0, 0, 0.7)"
            : featureScalingUp
            ? "rgba(0, 128, 0, 0.7)"
            : featureScalingDown
            ? "rgba(255, 0, 0, 0.7)"
            : "default",
        },
      },
    };

    const toggleDisabled =
      isLoading || featureError || featureScalingUp || featureScalingDown;
    const toggleColor = toggleDisabled
      ? featureScalingUp
        ? "green"
        : featureScalingDown
        ? "red"
        : "default"
      : featureAvailable
      ? "green"
      : "red";

    return (
      <>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{
            p: "0rem 0rem 1rem 1rem ",
          }}
        >
          <ListItem key={feature.name} style={{ color: textColor }}>
            <Grid item xs={6} sm={4} md={5}>
              <ListItemText primary={feature.name} />
            </Grid>
            <Grid item xs={5} sm={6} md={4}>
              <Typography variant="body2" style={{ marginRight: 16 }}>
                {isLoading
                  ? isAvailable
                    ? feature.state
                    : feature.state
                    // ? "Scaling Down..."
                    // : "Scaling Up..."
                  : featureError
                  ? feature.state
                  : // ? "Deployment Error"
                    `${feature.state}`}
                {/* `Replicas: ${feature.replicas}`} */}
              </Typography>
            </Grid>

            {/*             
            <Grid item xs={5} sm={6} md={4}>
              <Typography variant="body2" style={{ marginRight: 16 }}>

                {feature.state}
                {feature.name}
              </Typography>
            </Grid>
 */}

            <Grid item xs={1} sm={2} md={3}>
              {featureError ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconButton
                      edge="end"
                      color="error"
                      onClick={handleDialogOpen}
                    >
                      <InfoOutlinedIcon />
                    </IconButton>
                    <Dialog open={dialogOpen} onClose={handleDialogClose}>
                      <DialogTitle>Error Information</DialogTitle>
                      <DialogContent>{feature.reason}</DialogContent>
                    </Dialog>
                  </Box>
                  {/* <IconButton
                    edge="end"
                    color="error"
                    onClick={handleDialogOpen}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                  <Dialog open={dialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>Error Information</DialogTitle>
                    <DialogContent>{feature.reason}</DialogContent>
                  </Dialog> */}
                </>
              ) : (
                // <>
                //   <ListItemSecondaryAction>
                //     {feature.reason}
                //   </ListItemSecondaryAction>
                // </>
                <>
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={() => toggleFeature(feature.name, feature)}
                      checked={isAvailable}
                      disabled={toggleDisabled}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: toggleColor,
                          "& + .MuiSwitch-track": {
                            backgroundColor: toggleColor,
                          },
                        },
                        "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track":
                          {
                            backgroundColor: toggleColor,
                          },
                      }}
                    />
                  </ListItemSecondaryAction>
                </>
              )}

              {/* {!featureError && (
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={() => toggleFeature(feature.name, feature)}
                    checked={isAvailable}
                    disabled={toggleDisabled}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: toggleColor,
                        "& + .MuiSwitch-track": {
                          backgroundColor: toggleColor,
                        },
                      },
                      "& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track":
                        {
                          backgroundColor: toggleColor,
                        },
                    }}
                  />
                </ListItemSecondaryAction>
              ) 
              
              } */}
            </Grid>
          </ListItem>
          {showDivider && (
            <Divider
              sx={{
                p: 0,
                width: "90%",
                borderRadius: 2,
                border: "1px solid",
                borderColor: "divider",
                backgroundColor: "background.paper",
              }}
            />
          )}
        </Grid>
      </>
    );
  };

  return (
    <div>
      {isMobile ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleClick}
          sx={{ marginRight: 2 }}
        >
          <SettingsIcon />
        </IconButton>
      ) : (
        <Button
          id="env-control-button"
          aria-controls={open ? "env-control-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          variant="contained"
          sx={{ textTransform: "none" }}
        >
          Environment Control
        </Button>
      )}

      <Menu
        id="env-control-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "env-control-button" }}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "rgba(129, 65, 65, 0)",
          },
          "& .MuiPaper-root": {
            borderRadius: 3,
            marginTop: 1,
            width: "24rem",
            color: "rgb(55, 65, 81)",
            boxShadow:
              "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
              padding: "0px 0",
            },
            "& .MuiMenuItem-root": {
              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: "grey",
                marginRight: 1.5,
              },
              "&:active": {
                backgroundColor: "rgb(55, 65, 181)",
              },
            },
          },
        }}
      >
        {Object.entries(deployments).map(([sectionName, features]) => (
          <Accordion
            key={sectionName}
            expanded={expandedSections[sectionName] || false}
            onChange={() => handleToggleAccordion(sectionName)}
            sx={{
              "&.MuiPaper-root.MuiAccordion-root:before": {
                backgroundColor: "rgb(251 7 7 / 0)", // Your desired color
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                padding: "0rem 1rem 0rem 1rem ",
              }}
            >
              <Typography>{sectionName}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "0",
              }}
            >
              <List dense>
                {features.map((feature, index) =>
                  renderFeatureToggle(feature, index !== features.length - 1)
                )}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </Menu>
    </div>
  );
}

export default EnvironmentController;
