import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FileList from "../../../Components/Dialog/FileList";
import FilterSidebar from "../../../Components/Dialog/FilterSidebar";
import TranscriberFilterTab from "../../../Components/Dialog/TranscriberFilterTab";
import useFilterManager from "../../../Hooks/useFilterManager";
import { handleManualTranscribeApiRequest } from "./manualTranscribeApiHandler";

import { debounce } from "lodash";

const tabLabels = [
  "All",
  "Untranscribed",
  "In Process",
  "Transcribed",
  "With error",
];

const ITEMS_PER_PAGE = 20;

const SkeletonLoader = () => (
  <Box sx={{ width: "100%" }}>
    {[...Array(5)].map((_, index) => (
      <Skeleton key={index} variant="rectangular" height={60} sx={{ my: 1 }} />
    ))}
  </Box>
);

const FileManagement = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const { filesStatus, loadingMongoFiles, totalWER } = useSelector(
    (state) => state.manualTranscribeData
  );
  const [transcriberFilter, setTranscriberFilter] =
    useState("All Transcribers");
  const [page, setPage] = useState(1);
  const [formatedTotalWER, setFormatedTotalWER] = useState("Calculating..");
  const [selectedTranscriptId, setSelectedTranscriptId] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);

  const handleTabMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTabMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTabMenuItemClick = (index) => {
    handleTabChangeWithReset(null, index);
    handleTabMenuClose();
  };

  const menuItems = tabLabels.map((label, index) => (
    <MenuItem key={index} onClick={() => handleTabMenuItemClick(index)}>
      {label}
    </MenuItem>
  ));

  const formatWER = (totalWER) => {
    if (totalWER !== "" && totalWER !== 0 && totalWER !== undefined) {
      const number = parseFloat(totalWER);
      if (!isNaN(number) && number >= 0 && number <= 1) {
        // setFormatedTotalWER(`${(number * 100).toFixed(2)}%`);

        return `${(number * 100).toFixed(2)}%`;
      }
    }
    return "N/A";
  };

  const {
    searchQuery,
    filters,
    tabValue,
    sidebarOpen,
    setSearchQuery,
    setFilters,
    handleTabChange,
    toggleSidebar,
    resetFilters,
  } = useFilterManager(filesStatus);

  useEffect(() => {
    dispatch(handleManualTranscribeApiRequest({ type: "get_files_status" }));
    dispatch(handleManualTranscribeApiRequest({ type: "get_average_wer" }));
  }, [dispatch]);
  useEffect(() => {
    if (totalWER !== "Calculating.." && totalWER !== "N/A") {
      setFormatedTotalWER(formatWER(totalWER));
    } else {
      setFormatedTotalWER(totalWER);
    }
  }, [totalWER]);

  const handleTranscriberFilterChange = useCallback((transcriber) => {
    setTranscriberFilter(transcriber);
    setPage(1);
  }, []);

  const debouncedSetSearchQuery = useMemo(
    () =>
      debounce((value) => {
        setSearchQuery(value);
        setPage(1);
      }, 300),
    [setSearchQuery]
  );

  const handleSearchChange = useCallback(
    (e) => {
      debouncedSetSearchQuery(e.target.value);
    },
    [debouncedSetSearchQuery]
  );

  const handleTabChangeWithReset = useCallback(
    (event, newValue) => {
      handleTabChange(event, newValue);
      setPage(1);
      if (newValue === 1) {
        setTranscriberFilter("All Transcribers");
      }
    },
    [handleTabChange]
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleFileSelect = useCallback(
    async (transcriptId) => {
      setSelectedTranscriptId(transcriptId);
      setIsLoadingDetails(true);
      try {
        dispatch(
          handleManualTranscribeApiRequest({
            type: "get_file_details",
            transcriptId: transcriptId,
          })
        );
      } catch (error) {
        console.error("Error fetching file details:", error);
      } finally {
        setIsLoadingDetails(false);
      }
    },
    [dispatch]
  );

  const finalFilteredFiles = useMemo(() => {
    let filtered = filesStatus;

    if (tabValue === 1) {
      filtered = filtered.filter((file) => file.status === "REQUIRED");
    } else if (tabValue === 2) {
      filtered = filtered.filter((file) => file.status === "IN_PROCESS");
    } else if (tabValue === 3) {
      filtered = filtered.filter((file) => file.status === "DONE");
    } else if (tabValue === 4) {
      filtered = filtered.filter(
        (file) => Object.keys(file.options_audio_error || {}).length > 0
      );
    }

    if (searchQuery) {
      filtered = filtered.filter((file) =>
        file.audio_filename.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (transcriberFilter !== "All Transcribers") {
      if (transcriberFilter === "No Transcriber") {
        filtered = filtered.filter(
          (file) =>
            !file.transcriber_username ||
            file.transcriber_username.trim() === ""
        );
      } else {
        filtered = filtered.filter(
          (file) => file.transcriber_username === transcriberFilter
        );
      }
    }

    return filtered;
  }, [filesStatus, tabValue, searchQuery, transcriberFilter]);

  const paginatedFiles = useMemo(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    return finalFilteredFiles.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [finalFilteredFiles, page]);

  const pageCount = Math.max(
    1,
    Math.ceil(finalFilteredFiles.length / ITEMS_PER_PAGE)
  );

  const selectedFile = useMemo(
    () =>
      filesStatus.find((file) => file.transcriptId === selectedTranscriptId),
    [filesStatus, selectedTranscriptId]
  );

  return (
    <Container maxWidth="xl">
      <Box
        sx={{ mb: 1, justifyContent: "space-between" }}
        display="flex"
        flexDirection="row-reverse"
        justifyContent="space-between"
      >
        <TextField
          placeholder="Search files..."
          onChange={handleSearchChange}
          fullWidth
          justifyContent="center"
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />

        <Typography
          sx={{
            alignContent: "center",
            justifyContent: "center",
            width: "20%",
          }}
        >
          Total WER: {formatedTotalWER}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", borderBottom: 1, borderColor: "divider" }}>
        {isSmallScreen ? (
          <>
            <Button
              onClick={handleTabMenuClick}
              endIcon={<ArrowDropDownIcon />}
              sx={{
                textTransform: "none",
                minHeight: 48,
                justifyContent: "flex-start",
                px: 2.5,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              {tabLabels[tabValue]}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleTabMenuClose}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: 1,
                  marginTop: 1,
                  color: "rgb(55, 65, 81)",
                  boxShadow:
                    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                  "& .MuiMenu-list": {
                    padding: "5px 0",
                  },
                },
              }}
            >
              {menuItems}
            </Menu>
          </>
        ) : (
          <Tabs
            value={tabValue}
            onChange={handleTabChangeWithReset}
            sx={{
              flexGrow: 1,
              "& .MuiTabs-flexContainer": { justifyContent: "flex-start" },
              "& .MuiTab-root": {
                minWidth: "auto",
                textTransform: "capitalize",
              },
              "& .MuiTabs-indicator": { transition: "none" },
            }}
          >
            {tabLabels.map((label, index) => (
              <Tab key={index} label={label} />
            ))}
          </Tabs>
        )}
        {tabValue !== 1 && (
          <TranscriberFilterTab
            files={filesStatus}
            onFilterChange={handleTranscriberFilterChange}
            currentFilter={transcriberFilter}
          />
        )}
        <IconButton onClick={toggleSidebar}>
          {/* <FilterListIcon /> */}
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", mt: 2 }}>
        <Box sx={{ flexGrow: 1, mr: sidebarOpen ? 2 : 0 }}>
          {loadingMongoFiles === "loading" ? (
            <SkeletonLoader />
          ) : (
            <FileList
              files={paginatedFiles}
              onFileSelect={handleFileSelect}
              isLoadingDetails={isLoadingDetails}
              selectedTranscriptId={selectedTranscriptId}
              formatWER={formatWER}
            />
          )}
        </Box>
        {sidebarOpen && (
          <Box sx={{ width: 300 }}>
            <FilterSidebar
              sidebarOpen={sidebarOpen}
              filters={filters}
              setFilters={setFilters}
              resetFilters={resetFilters}
              toggleSidebar={toggleSidebar}
            />
          </Box>
        )}
      </Box>

      <Paper
        elevation={1}
        sx={{
          p: 2,
          mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="body2" sx={{ mb: 1 }}>
          Showing {paginatedFiles.length} of {finalFilteredFiles.length} files
        </Typography>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          color="primary"
          siblingCount={isSmallScreen ? 0 : 1}
          boundaryCount={isSmallScreen ? 1 : 2}
        />
      </Paper>
    </Container>
  );
};

export default FileManagement;
