import React from "react";
import { FormControlLabel, Checkbox, Grid } from "@mui/material";

const SentimentFilterControls = ({
  showAgent,
  setShowAgent,
  showClient,
  setShowClient,
  showPositive,
  setShowPositive,
  showNeutral,
  setShowNeutral,
  showNegative,
  setShowNegative,
  showEmojis,
  setShowEmojis,
}) => (
  <Grid item xs={12} sm={12} md={12}>
    <FormControlLabel
      control={
        <Checkbox
          checked={showAgent}
          onChange={() => setShowAgent(!showAgent)}
        />
      }
      label="Show סוכן"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={showClient}
          onChange={() => setShowClient(!showClient)}
        />
      }
      label="Show לקוח"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={showPositive}
          onChange={() => setShowPositive(!showPositive)}
        />
      }
      label="Show Positive Sentiment"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={showNeutral}
          onChange={() => setShowNeutral(!showNeutral)}
        />
      }
      label="Show Neutral Sentiment"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={showNegative}
          onChange={() => setShowNegative(!showNegative)}
        />
      }
      label="Show Negative Sentiment"
    />
    <FormControlLabel
      control={
        <Checkbox
          checked={showEmojis}
          onChange={() => setShowEmojis(!showEmojis)}
        />
      }
      label="Show Emojis"
    />
  </Grid>
);

export default SentimentFilterControls;
