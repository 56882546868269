import React, { useEffect, useRef } from "react";
import { IconButton, Box, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload"; // Changed to CloudUploadIcon
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { styled } from "@mui/material/styles";
import { objectDetectionActions } from "../../store/object-detection/objectDetection-slice";
import { useDispatch, useSelector } from "react-redux";
import { handleObjectDetectionApiRequest } from "../../store/object-detection/objectDetectionApiHandler";
import CircularProgress from "@mui/material/CircularProgress";

const WhiteIconButton = styled(IconButton)({
  color: "#FFFFFF",
});

const VideoPlayer = () => {
  const dispatch = useDispatch();
  const { videoSrc, fileName, videoLoadingState } = useSelector(
    (state) => state.objectDetectionData
  );
  const { selectedFeature } = useSelector((state) => state.featureData);

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoSrc]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append(`file`, file);
    dispatch(handleObjectDetectionApiRequest({ type: "uploadFile", formData }));
    if (selectedFeature === "YOLO") {
      dispatch(
        handleObjectDetectionApiRequest({ type: "process_video", formData })
      );
    }

    if (file) {
      const videoUrl = URL.createObjectURL(file);
      if (selectedFeature !== "YOLO") {
        dispatch(objectDetectionActions.setVideoSrc(videoUrl));
      }
      dispatch(objectDetectionActions.setFileName(file.name));
      if (videoRef.current) {
        videoRef.current.load();
      }
    }
  };

  const handlePlayVideo = () => {
    if (videoRef.current) videoRef.current.play();
  };

  const handlePauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          margin: "0rem 0rem 0rem 0rem",
        }}
      >
        {videoLoadingState ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ flex: 1, p: 0 }}>
            <video ref={videoRef} width="100%" controls>
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
                p: 1,
                backgroundColor: "#0277bd",
                border: "2px solid black",
                borderRadius: "9px",
              }}
            >
              <WhiteIconButton color="primary" component="label">
                <CloudUploadIcon />
                <input
                  type="file"
                  hidden
                  accept="video/*"
                  onChange={handleFileChange}
                />
              </WhiteIconButton>
              <Typography sx={{ color: "#FFFFFF" }}>{fileName}</Typography>
              <Box>
                <WhiteIconButton onClick={handlePlayVideo}>
                  <PlayArrowIcon />
                </WhiteIconButton>
                <WhiteIconButton onClick={handlePauseVideo}>
                  <PauseIcon />
                </WhiteIconButton>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default VideoPlayer;
