import React, { useEffect, useRef } from "react";
import { Box, Typography, Grid } from "@mui/material";
import AudioLoading from "../AudioLoading";
import { useSelector } from "react-redux";

const AudioPlayer = ({ filename, onTimeUpdate, seekTo,handleExtractAudioDuration }) => {
  const audioRef = useRef(null);
  const { audioPath, audioSrcState } = useSelector((state) => state.manualTranscribeData);
  const { words_transcript_timestamp,  } =
    useSelector((state) => state.manualTranscribeData);

  useEffect(() => {
    if (audioRef.current && seekTo !== null) {
      audioRef.current.currentTime = seekTo;
    }
  }, [seekTo]);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      if (audioRef.current) {
        handleExtractAudioDuration(audioRef.current.duration);
      }
    };

    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      return () => {
        audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [handleExtractAudioDuration, audioPath]);


  const handleTimeUpdate = () => {
    if (audioRef.current) {
      onTimeUpdate(audioRef.current.currentTime);
    }
  };

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            <span style={{ fontWeight: "bold" }}>Audio File</span>: {filename}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {audioSrcState === "Loading" ? (
            <AudioLoading />
          ) : audioSrcState === "error" ? (
            <Typography variant="body2" color="error">
              Failed to load audio.
            </Typography>
          ) : audioPath ? (
            <audio
              controls
              style={{ width: "100%" }}
              ref={audioRef}
              onTimeUpdate={handleTimeUpdate}
              key={audioPath}
            >
              <source src={audioPath} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          ) : (
            "No audio file available"
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AudioPlayer;
