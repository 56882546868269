import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const handleSemanticRetrieverApiRequest = createAsyncThunk(
  "api/handleSemanticRetrieverApiRequest",
  async ({ type, formData = null, message = "", feature = "" }, thunkAPI) => {
    const link = thunkAPI.getState().featureData.link; // Assume you have a link in your api slice's state
    const dbToUse = thunkAPI.getState().semanticRetrieverData.dbToUse; // Assume you have a link in your api slice's state
    const userUniqueId = thunkAPI.getState().featureData.userUniqueId; // Assume you have a link in your api slice's state
    let url;
    let config = {};
    let payload;
    let METHOD = "post";

    switch (type) {
      case "uploadFiles":
        url = `${link}uploadfiles/${userUniqueId}`;
        config.headers = { "Content-Type": "multipart/form-data" };
        payload = formData;
        break;
      case "getHistory":
        METHOD = "get";
        url = `${link}get_history/${userUniqueId}/${dbToUse}`;
        config.headers = { "Content-Type": "multipart/form-data" };
        break;
      case "sendMessage":
        url = `${link}interact`;
        payload = {
          userUniqueId: userUniqueId,
          user_input: message.newMessage,
          db_to_use: dbToUse,
        };
        config.headers = { "Content-Type": "application/json" };
        break;
      case "deleteSingleFile":
        url = `${link}deletefile`;
        payload = { userUniqueId: userUniqueId, user_input: message };
        config.headers = { "Content-Type": "application/json" };
        break;

      case "deleteChatHistory":
        METHOD = "get";
        url = `${link}delete_chat_history/${userUniqueId}/${dbToUse}`;
        break;

      case "cleanup":
        url = `${feature}cleanup`;
        payload = { userUniqueId: userUniqueId };
        config.headers = { "Content-Type": "application/json" };
        break;

      case "downloadVectorDB":
        payload = { userUniqueId: userUniqueId, user_input: message };
        url = `${link}download_from_storage`;
        config.headers = { "Content-Type": "application/json" };
        break;
      case "uploadVectorDB":
        payload = { userUniqueId: userUniqueId, user_input: message };
        url = `${link}upload_db_to_storage`;
        config.headers = { "Content-Type": "application/json" };
        break;


      case "fetch_corpus_data":
        METHOD = "get";
        url = `${link}fetch_corpus_data`;
        config.headers = { "Content-Type": "application/json" };
        break;

        case "deleteCorpus":
          url = `${link}delete_corpus`;
          payload = { userUniqueId: userUniqueId, user_input: message };
          config.headers = { "Content-Type": "application/json" };
          break;




        case "createCorpus":
          url = `${link}create_user_corpus`;
          payload = { userUniqueId: userUniqueId, user_input: message  };
          config.headers = { "Content-Type": "application/json" };
          break;



      default:
        throw new Error("Unsupported API request type");
    }

    const response = await axios({
      method: METHOD,
      url: url,
      data: payload,
      ...config,
    });
    return { type, data: response.data, feature, message };
  }
);
