import React from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CreateDatastore from "./CreateNewDatastore";
import SelectDatastore from "./SelectExistingDatastore";

const DatastoreOptions = ({
  datastoreOption,
  setDatastoreOptions,
  deleteIconStatus,
  deleteFileIconStatuses,
  availableVectorDatastores,
  selectedVectorDB,
  selectedMenuItem,
  datastoreName,
  uploadedDocumentsFiles,
  documentLoadStatus,
  cloudUploadStatus,
  loadingAvailableDatastores,
  handleGetDB,
  handleGetDBAvailable,
  handleSelectDB,
  handleDatastoreNameChange,
  handleSaveNewDatastore,
  newDatastoreNameError,
  handleSingleFileDelete,
  disableCreateNewCorpora,
  handleDeleteDB,
  nameChangeDisabled,
}) => {
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setDatastoreOptions(selectedValue);
  };

  return (
    <Box sx={{ display: "flex", width: "100%", gap: 1 }}>
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        alignItems="center"
        sx={{
          boxSizing: "border-box",
          padding: "0rem 0rem 1rem 0rem",
          m: 0,
          mb: 1,
          bgcolor: "#f0f0f0",
          borderRadius: "7px",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="datastore-select-label">
              Datastore Options
            </InputLabel>
            <Select
              labelId="datastore-select-label"
              id="datastore-select"
              value={datastoreOption}
              label="Datastore Options"
              onChange={handleSelectChange}
            >
              <MenuItem value="create">Create New Datastore</MenuItem>
              <MenuItem value="select">Select Existing Datastore</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={9}>
          {datastoreOption === "create" && (
            <CreateDatastore
              datastoreOption={datastoreOption}
              deleteIconStatus={deleteIconStatus}
              deleteFileIconStatuses={deleteFileIconStatuses}
              availableVectorDatastores={availableVectorDatastores}
              datastoreName={datastoreName}
              uploadedDocumentsFiles={uploadedDocumentsFiles}
              documentLoadStatus={documentLoadStatus}
              cloudUploadStatus={cloudUploadStatus}
              handleDatastoreNameChange={handleDatastoreNameChange}
              handleSaveNewDatastore={handleSaveNewDatastore}
              newDatastoreNameError={newDatastoreNameError}
              handleSelectDB={handleSelectDB}
              handleSingleFileDelete={handleSingleFileDelete}
              disableCreateNewCorpora={disableCreateNewCorpora}
              nameChangeDisabled={nameChangeDisabled}
              handleDeleteDB={handleDeleteDB}
              
            />
          )}
          {datastoreOption === "select" && (
            <SelectDatastore
              availableVectorDatastores={availableVectorDatastores}
              selectedVectorDB={selectedVectorDB}
              selectedMenuItem={selectedMenuItem}
              loadingAvailableDatastores={loadingAvailableDatastores}
              handleGetDB={handleGetDB}
              handleGetDBAvailable={handleGetDBAvailable}
              handleSelectDB={handleSelectDB}
              handleDeleteDB={handleDeleteDB}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DatastoreOptions;

