import { createSlice, current } from "@reduxjs/toolkit";
import { handleKubernetesControllerApiRequest } from "./kubernetesControllerApiHandler";

const initialState = {
  deployments: {},
  loading: false,
  error: null,
};

const sections = {
  "Organizational Chatbot": [
    "llama-index",
    "RAG langchain with OpenAI",
    "RAG langchain with Google",
    "Google Documents Search",
    "Google Similarity Retrieval",
  ],
  Transcription: ["Manual Transcribe","File Management", "ACE Handler"],
  "Noise Reduction": [
    "Auto Encoder",
    "Spectral Gating",

  ],
  "Voice Recognition": [
    "Pyannote Embedding",
    "Multichannel Separation",
    "KNN Clustering",
    "Deep Clustering",
  ],
  "Sign Language Translation": ["TBA"],
  "Object Detection": ["YOLO", "DETR", "Easy OCR", "Donut Processor"],

  MongoDB: ["MongoDB Server", "MongoDB GUI (Express)"],
};

const deploymentToSectionMap = {
  "openai-langchain-deployment": {
    section: "Organizational Chatbot",
    feature: "RAG langchain with OpenAI",
  },
  "google-langchain-deployment": {
    section: "Organizational Chatbot",
    feature: "RAG langchain with Google",
  },
  "llama-index-deployment": {
    section: "Organizational Chatbot",
    feature: "llama Index",
  },
  "Google Documents Search-deployment": {
    section: "Organizational Chatbot",
    feature: "Google Documents Search",
  },
  "semantic-retriever-deployment": {
    section: "Organizational Chatbot",
    feature: "Google Similarity Retrieval",
  },
  "auto-encoder-deployment": {
    section: "Noise Reduction",
    feature: "Auto Encoder",
  },

  "spectral-gating-deployment": {
    section: "Noise Reduction",
    feature: "Spectral Gating",
  },
  "pyannote-embedding-deployment": {
    section: "Voice Recognition",
    feature: "Pyannote Embeddings",
  },


  "knn-clustering-deployment": {
    section: "Voice Recognition",
    feature: "KNN Clustering",
  },
  "deep-clustering-deployment": {
    section: "Voice Recognition",
    feature: "Deep Clustering",
  },
  // "multichannel-separation-deployment": {
  //   section: "Voice Recognition",
  //   feature: "Multichannel Separation",
  // },
  "TBA-deployment": {
    section: "Sign Language Translation",
    feature: "TBA",
  },
  "yolo-deployment": {
    section: "Object Detection",
    feature: "YOLO",
  },
  "detr-deployment": {
    section: "Object Detection",
    feature: "DETR",
  },
  "image-ocr-deployment": {
    section: "Object Detection",
    feature: "EasyOCR",
  },
  "donut-processor-deployment": {
    section: "Object Detection",
    feature: "Donut Processor",
  },
  "transcript-server-deployment": {
    section: "Transcription",
    features: ["File Management", "Manual Transcribe"]
  },
  "ace-deployment": {
    section: "Transcription",
    feature: "ACE",
  },
  mongo: {
    section: "MongoDB",
    feature: "MongoDB Server",
  },
  "mongo-express": {
    section: "MongoDB",
    feature: "MongoDB GUI (Express)",
  },

};

const findAndUpdateDeployment = (
  state,
  deploymentName,
  newDeploymentState,
  newReplicas
) => {
  const newState = { ...state };

  Object.entries(newState).forEach(([section, sectionDeployments]) => {
    const updatedDeployments = sectionDeployments.map((deployment) => {
      if (deployment.deploymentName === deploymentName) {
        return {
          ...deployment,
          state: newDeploymentState,
          replicas: newReplicas,
        };
      }
      return deployment; 
    });

    newState[section] = updatedDeployments;
  });

  return newState; 
};




function mapDeploymentsToSections(deployments) {
  let sectionStructure = Object.entries(deploymentToSectionMap).reduce(
    (acc, [deploymentName, info]) => {
      const { section, feature, features } = info;

      if (!acc[section]) acc[section] = [];

      const deploymentInfo = deployments[deploymentName];

      if (features) {
        features.forEach(feat => {
          if (deploymentInfo) {
            acc[section].push({
              name: feat,
              state: deploymentInfo.state,
              replicas: deploymentInfo.replicas,
              reason: deploymentInfo.reason,
              deploymentName: deploymentName,
            });
          } else {
            acc[section].push({
              name: feat,
              state: "Loading",
              replicas: "None",
            });
          }
        });
      } else if (feature) {
        if (deploymentInfo) {
          acc[section].push({
            name: feature,
            state: deploymentInfo.state,
            replicas: deploymentInfo.replicas,
            reason: deploymentInfo.reason,
            deploymentName: deploymentName,
          });
        } else {
          acc[section].push({
            name: feature,
            state: "Loading",
            replicas: "None",
          });
        }
      }

      return acc;
    },
    {}
  );

  Object.keys(sections).forEach((section) => {
    if (!sectionStructure[section]) sectionStructure[section] = [];
  });

  return sectionStructure;
}

const kubernetesControllerSlice = createSlice({
  name: "kubernetesControllerHandler",
  initialState,

  reducers: {
    setLoading: (state, action) => {
      state.loading = state;
    },
    updateFeatureState: (state, action) => {

      const {
        deploymentsObject,
        deploymentName,
        newDeploymentState,
        newReplicas,
      } = action.payload;
      state.deployments = findAndUpdateDeployment(
        current(state.deployments),
        deploymentName,
        newDeploymentState,
        newReplicas
      );
    },
    updateDeployments: (state, action) => {
      const updatedDeployments = action.payload;

      state.deployments = mapDeploymentsToSections(updatedDeployments);

    },
  },
  extraReducers: (builder) => {

    builder
      .addCase(
        handleKubernetesControllerApiRequest.pending,
        (state, action) => {
          const { type } = action.meta.arg;

          switch (type) {
            case "get_deployments":
              state.loading = true;

              break;
            case "set_replicas":
              state.loading = true;

              break;

            default:
              console.warn(`Unhandled action type: ${type}`);
          }
        }
      )
      .addCase(
        handleKubernetesControllerApiRequest.fulfilled,
        (state, action) => {
          const { type, data, deploymentName } = action.payload;
          state.loading = false;
          switch (type) {
            case "get_deployments":
              state.loading = false;
              break;
            case "set_replicas":
              state.loading = false;

              break;

            default:
              console.warn(`Unhandled action type: ${type}`);
          }
        }
      )
      .addCase(
        handleKubernetesControllerApiRequest.rejected,
        (state, action) => {
          const { type } = action.meta.arg;

          console.error("Error processing request:", action.error);
          switch (type) {
            case "get_deployments":
              state.loading = false;
              state.error = action.error.message;
              break;
            case "set_replicas":
              state.loading = false;
              state.error = action.error.message;

              break;
            default:
              console.warn(`Unhandled action type: ${type}`);
          }
        }
      );
  },
});

export const kubernetesControllerActions = kubernetesControllerSlice.actions;

export default kubernetesControllerSlice;
