import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { handleManualTranscribeApiRequest } from "../Transcription/ManualTranscribe/manualTranscribeApiHandler";
import FileListItem from "./FileListItem";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const FileList = React.memo(
  ({
    files,
    onFileSelect,
    isLoadingDetails,
    selectedTranscriptId,
    formatWER,
  }) => {
    const dispatch = useDispatch();
    const { audioPath, newTranscript, originalTranscript } = useSelector(
      (state) => state.manualTranscribeData
    );
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [playingFile, setPlayingFile] = useState(null);
    const [filesData, setFilesData] = useState({});

    const handlePlayPause = useCallback((file) => {
      setPlayingFile((prevFile) =>
        prevFile && prevFile._id === file._id ? null : file
      );
    }, []);

    const handleFileSelect = useCallback(
      (transcriptId) => {
        if (selectedTranscriptId !== transcriptId) {
          onFileSelect(transcriptId);
          dispatch(
            handleManualTranscribeApiRequest({
              type: "fetch_transcripts",
              transcript_id: transcriptId,
            })
          );
          dispatch(
            handleManualTranscribeApiRequest({
              type: "fetch_audio_file",
              transcript_id: transcriptId,
            })
          );
        }
      },
      [selectedTranscriptId, onFileSelect, dispatch]
    );

    useEffect(() => {
      if (selectedTranscriptId) {
        setFilesData((prevFilesData) => ({
          ...prevFilesData,
          [selectedTranscriptId]: {
            originalTranscript: originalTranscript,
            newTranscript: newTranscript,
            audioSrc: audioPath,
          },
        }));
      }
    }, [newTranscript, originalTranscript, audioPath, selectedTranscriptId]);

    const sortedFiles = useMemo(() => {
      return [...files].sort((a, b) => {
        const dateA = new Date(a.update_time || a.upload_time);
        const dateB = new Date(b.update_time || b.upload_time);
        return dateB - dateA;
      });
    }, [files]);

    return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>File Name</StyledTableCell>
              {!isSmallScreen && (
                <>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>User</StyledTableCell>
                  <StyledTableCell>Last Update Date</StyledTableCell>
                  <StyledTableCell>WER</StyledTableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedFiles.map((file) => (
              <FileListItem
                key={file._id}
                file={file}
                isPlaying={playingFile && playingFile._id === file._id}
                onPlayPause={handlePlayPause}
                filesData={filesData}
                onFileSelect={handleFileSelect}
                isSelected={selectedTranscriptId === file.transcriptId}
                formatedWER={formatWER(file.wer)}
                isLoadingDetails={
                  isLoadingDetails && selectedTranscriptId === file.transcriptId
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

export default FileList;
