import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  List,
  Paper,
  Avatar,
  Tooltip,
  CircularProgress,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { orgChatActions } from "../../store/org-chat/orgChat-slice";
import SendIcon from "@mui/icons-material/Send";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RefreshIcon from "@mui/icons-material/Refresh";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CustomAccordion from "./CustomAccordion";
import { handleOrgChatApiRequest } from "../../store/org-chat/orgChatApiHandler";
import DatastoreOptions from "./datastores/DatastoreOptions";
import { linkMap } from "../../Pages/Global/Config/linkMapUtils";
import { NoEncryption } from "@mui/icons-material";

const ChatComponent = () => {
  const dispatch = useDispatch();

  const {
    chatHistory,
    botIsTyping,
    uploadedDocumentsFiles,
    documentLoadStatuss,
  } = useSelector((state) => state.orgChatData);
  const { selectedFeature } = useSelector((state) => state.featureData);
  const [documentSelectStatus, setDocumentSelectStatus] = useState("idle");

  const inputFileRef = useRef(null);
  const [newMessage, setNewMessage] = useState("");
  const chatListRef = useRef(null);

  useEffect(() => {
    const linkMap = {
      // Organizational Chatbot Links
      "RAG langchain with OpenAI": "openaiLangChain",
      "RAG langchain with Google": "googleLangChain",
      "Llama Index": "llamaIndex",
      "Google Document Search": "googleDocumentSearch",
      "Semantic Retriever": "semanticRetriever",
    };
    if (selectedFeature in linkMap) {
      setDocumentSelectStatus(uploadedDocumentsFiles[linkMap[selectedFeature]]);
    }
  }, [selectedFeature, uploadedDocumentsFiles]);

  useEffect(() => {
    // Adjusted to control the scroll within the chat window
    if (chatListRef.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
  }, [chatHistory, botIsTyping]);

  const handleSendMessage = async (e) => {
    e.preventDefault(); // Prevent the default action to avoid adding a new line.

    if (!newMessage.trim()) return;

    dispatch(
      orgChatActions.updateChatHistory({ text: newMessage, sender: "user" })
    );
    setNewMessage("");
    dispatch(
      handleOrgChatApiRequest({ type: "sendMessage", message: { newMessage } })
    );
  };

  const handleClearChat = () => {
    dispatch(handleOrgChatApiRequest({ type: "deleteChatHistory" }));
    // dispatch(orgChatActions.resetChatHistory());
  };

  const handleUploadFile = () => {
    inputFileRef.current.click();
  };


  // console.log(uploadedDocumentsFiles)
  // console.log(uploadedDocumentsFiles[linkMap[selectedFeature]])
  const handleFileChange = async (event) => {
    // event.preventDefault()
    const files = event.target.files;
    const formData = new FormData();
    const filesObject = {};

    for (let i = 0; i < files.length; i++) {
      formData.append(`files`, files[i]);
      // Add each file to the object with "pending" status
      filesObject[files[i].name] = "pending";
    }
    dispatch(
      orgChatActions.setUploadedDocumentsFiles({
        feature: selectedFeature,
        files: filesObject,
      })
    );

    dispatch(
      handleOrgChatApiRequest({
        type: "uploadFiles",
        formData,
        feature: linkMap[selectedFeature],
      })
    );
    event.target.value = null;
  };
  return (
    <Typography >
      Nothing Here

    </Typography>
   )

  // return (
  //   <Box
  //     sx={{
  //       width: "100%",
  //       margin: "auto",
  //       display: "flex",
  //       flexDirection: "column",
  //       height: "70vh",
  //       overflow: "hidden",
  //     }}
  //   >
  //     {selectedFeature === "RAG langchain with Google" ||
  //     selectedFeature === "RAG langchain with OpenAI" ? (
  //       // <DBAccordion />
  //       <DatastoreOptions />
  //     ) : (
  //       // <CustomAccordion />
  //       // <DatastoreOptions />
  //       "Nothing here"
  //     )}

  //     <List
  //       sx={{
  //         mb: 2,
  //         overflow: "auto",
  //         flexGrow: 1,
  //         maxHeight: "80%",
  //         bgcolor: "#f0f0f0",
  //         p: 2,
  //         borderRadius: "7px",
  //       }}
  //       ref={chatListRef}
  //     >
  //       {chatHistory.map((message, index) => (
  //         <Box
  //           key={index}
  //           sx={{
  //             display: "flex",
  //             mb: 2,
  //             flexDirection: message.sender === "user" ? "row-reverse" : "row",
  //           }}
  //         >
  //           <Avatar sx={{ color: "#000", bgcolor: "transparent" }}>
  //             {message.sender === "error" ? (
  //               <ErrorOutlineIcon style={{ color: "#DA0016" }}>
  //                 !
  //               </ErrorOutlineIcon> // Assuming you have a CircleIcon for the error
  //             ) : (
  //               <RecordVoiceOverIcon
  //                 style={{
  //                   transform:
  //                     message.sender === "user" ? "scaleX(-1)" : "scaleX(1)",
  //                 }}
  //               />
  //             )}
  //           </Avatar>
  //           <Paper
  //             elevation={3}
  //             sx={{
  //               p: 2,
  //               maxWidth: "70%",
  //               bgcolor:
  //                 message.sender === "user"
  //                   ? "#add8e6"
  //                   : message.sender === "error"
  //                   ? "#DA0016"
  //                   : "#fff",
  //               borderRadius: "20px",
  //               marginLeft:
  //                 message.sender === "user" || message.sender === "error"
  //                   ? 1
  //                   : 2,
  //               marginRight:
  //                 message.sender === "user" || message.sender === "error"
  //                   ? 2
  //                   : 1,
  //             }}
  //           >
  //             <Typography
  //               variant="body1"
  //               sx={{
  //                 color: message.sender === "error" ? "#fff" : "", // Ensure text is visible on the red background
  //                 textAlign: message.sender === "user" ? "right" : "left",
  //                 direction: message.sender === "user" ? "rtl" : "ltr",
  //               }}
  //             >
  //               {message.text}
  //             </Typography>
  //           </Paper>
  //         </Box>
  //       ))}

  //       {botIsTyping && (
  //         <Box
  //           sx={{
  //             display: "flex",
  //             justifyContent: "flex-start",
  //             p: 1,
  //             alignItems: "center",
  //           }}
  //         >
  //           <CircularProgress size={20} />
  //           <Typography variant="body1" sx={{ ml: 1 }}>
  //             typing...
  //           </Typography>
  //         </Box>
  //       )}
  //     </List>

  //     <Box
  //       sx={{
  //         display: "flex",
  //         alignItems: "center",
  //         gap: 0,
  //         p: 0,
  //         direction: "rtl",
  //       }}
  //     >
  //       <TextField
  //         fullWidth
  //         variant="outlined"
  //         placeholder="שלח הודעה..."
  //         size="small"
  //         multiline
  //         minRows={1}
  //         maxRows={4}
  //         value={newMessage}
  //         autoFocus // Auto-focus on the text field when entering the chat
  //         onChange={(e) => setNewMessage(e.target.value)}
  //         onKeyPress={(e) =>
  //           e.key === "Enter" && !e.shiftKey && handleSendMessage(e)
  //         }
  //         InputProps={{
  //           startAdornment: (
  //             <InputAdornment position="end">
  //               <Tooltip
  //                 title="Clear chat history"
  //                 enterDelay={500}
  //                 leaveDelay={200}
  //               >
  //                 <IconButton onClick={handleClearChat} color="default">
  //                   <RefreshIcon />
  //                 </IconButton>
  //               </Tooltip>
  //             </InputAdornment>
  //           ),
  //           endAdornment: (
  //             <InputAdornment position="start">
  //               <div>
  //                 <input
  //                   type="file"
  //                   multiple
  //                   style={{ display: "none" }}
  //                   ref={inputFileRef}
  //                   onChange={handleFileChange}
  //                 />
  //                 <IconButton
  //                   onClick={handleUploadFile}
  //                   style={{
  //                     color:
  //                       documentLoadStatuss[linkMap[selectedFeature]] ===
  //                       "success"
  //                         ? "#06A77D"
  //                         : documentLoadStatuss[linkMap[selectedFeature]] ===
  //                           "error"
  //                         ? "red"
  //                         : "inherit",
  //                   }}
  //                 >
  //                   <UploadFileIcon />
  //                 </IconButton>
  //               </div>

  //               <Tooltip
  //                 title="Send the message"
  //                 enterDelay={500}
  //                 leaveDelay={200}
  //               >
  //                 <IconButton
  //                   onClick={handleSendMessage}
  //                   disabled={newMessage.trim() === "" || botIsTyping}
  //                   style={{
  //                     color:
  //                       newMessage.trim() !== "" && !botIsTyping
  //                         ? "#052F5F"
  //                         : undefined,
  //                   }}
  //                 >
  //                   <SendIcon style={{ transform: "scaleX(-1)" }} />
  //                 </IconButton>
  //               </Tooltip>
  //             </InputAdornment>
  //           ),
  //         }}
  //         dir="rtl"
  //       />
  //     </Box>
  //   </Box>
  // );
};

export default ChatComponent;
