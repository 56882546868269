import VideoPlayer from "./VideoPlayer";
import ObjectTable from "./ObjectTable";
import QnAComponent from "./QnAComponent";
import ImageObjectTable from "./ImageObjectTable";
import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import ImageDisplay from "./ImageDisplay";
import VideoList from "./VideoList";

function ObjectDetectionComponent() {
  const { selectedFeature } = useSelector(
    (state) => state.featureData
  );

  return (
    <>
      {selectedFeature === "YOLO" ? (
        <Grid container spacing={2} justifyContent="center" paddingBottom={2}>
          <Grid item xs={12} sm={12} md={12}>
            <VideoList />
          </Grid>
        </Grid>
      ) : null}

      {selectedFeature === "Donut Processor" ? (
        <QnAComponent />
      ) : (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={8} md={8}>
            {selectedFeature === "EasyOCR" ? <ImageDisplay /> : <VideoPlayer />}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            {selectedFeature === "EasyOCR" ? (
              <ImageObjectTable />
            ) : (
              <ObjectTable />
            )}
          </Grid>
        </Grid>
      )}

    </>
  );
}

export default ObjectDetectionComponent;
