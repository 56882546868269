import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import LoadingDots from "../../LoadingDots";

const TypingIndicator = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        p: 1,
        alignItems: "center",
      }}
    >
      {/* <CircularProgress size={20} /> */}
      <LoadingDots />
      {/* <Typography variant="body1" sx={{ ml: 1 }}>
        typing...
      </Typography> */}
    </Box>
  );
};

export default TypingIndicator;
