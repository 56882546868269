import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
} from "@mui/material";

const DatastoreSelectItems = ({
  handleSelectDB,
  selectedMenuItem,
  availableVectorDatastores,
  loadingAvailableDatastores,
  
}) => {
  const [datastoreValue, setDatastoreValue] = useState(selectedMenuItem);

  useEffect(() => {
    setDatastoreValue(selectedMenuItem);
  }, [selectedMenuItem]);

  const handleChangeSelection = (event) => {
    const selectedValue = event.target.value;
    setDatastoreValue(selectedValue);
    handleSelectDB(selectedValue); // Pass the selected value to handleSelectDB
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="select-existing-datastore-label">
        Select Existing Datastore
      </InputLabel>
      <Select
        labelId="select-existing-datastore-label"
        id="select-existing-datastore"
        value={datastoreValue}
        label="Select Existing Datastore"
        onChange={handleChangeSelection}
      >
        {availableVectorDatastores.map((db) => (
          <MenuItem key={db.db_name} value={db.db_name}>
            {selectedMenuItem === db.db_name && (
              <span style={{ marginRight: "8px", color: "green" }}>
                &#10004;
              </span>
            )}
            {db.db_name}
          </MenuItem>
        ))}
        
        {loadingAvailableDatastores && (
          <MenuItem>
            <Skeleton variant="text" width={200} />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default DatastoreSelectItems;
