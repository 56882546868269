import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterControl from "./FilterControl";
import { validTags } from "../../Components/Transcription/ManualTranscribe/config/validTags";

const FilterAccordion = ({ title, role, filters, setFilters }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: "0 1rem" }}>
      <Typography>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ padding: 0 }}>
      {Object.entries(validTags[role]).map(([key, value]) => (
        <FilterControl
          key={key}
          name={key}
          options={value}
          value={filters[role]?.[key] || []}
          onChange={(newValue) =>
            setFilters({
              ...filters,
              [role]: { ...filters[role], [key]: newValue },
            })
          }
        />
      ))}
    </AccordionDetails>
  </Accordion>
);

export default FilterAccordion;