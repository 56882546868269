
export const linkMap = {
    // Organizational Chatbot Links
    "RAG langchain with OpenAI": "openaiLangChain",
    "RAG langchain with Google": "googleLangChain",
    "llama Index": "llamaIndex",
    "Google Document Search": "googleDocumentSearch",
    "Google Similarity Retrieval": "semanticRetriever",
    "Auto Encoder": "autoEncoder",
    "Spectral Gating": "spectralGating",
    "Pyannote Embeddings": "pyannoteEmbeddings",
    "KNN Clustering": "KNNClustering",
    "Deep Clustering": "deepClustering",
    "YOLO": "YOLO",
    "DETR": "DETR",
    "EasyOCR": "easyOCR",
    "Donut Processor": "donutProcessor",
  };