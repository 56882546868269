import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Menu, MenuItem, Divider } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { handleManualTranscribeApiRequest } from "../Transcription/ManualTranscribe/manualTranscribeApiHandler";

const TranscriberFilterTab = ({ files, onFilterChange, currentFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const { hoursTranscribed } = useSelector((state) => state.manualTranscribeData);

  const uniqueTranscribers = useMemo(() => [
    "All Transcribers",
    "No Transcriber",
    ...new Set(
      files
        .map((file) => file.transcriber_username)
        .filter((username) => username && username.trim() !== "")
    ),
  ], [files]);

  useEffect(() => {
    uniqueTranscribers.forEach((transcriber) => {
      if (transcriber !== "All Transcribers" && transcriber !== "No Transcriber") {
        dispatch(
          handleManualTranscribeApiRequest({
            type: "get_hours_transcribed",
            user_name_to_fetch: transcriber,
          })
        );
      }
    });
  }, [uniqueTranscribers, dispatch]);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelect = useCallback((transcriber) => {
    onFilterChange(transcriber);
    handleClose();
  }, [onFilterChange, handleClose]);

  const menuItems = useMemo(() => 
    uniqueTranscribers.flatMap((transcriber, index, array) => [
      <MenuItem key={transcriber} onClick={() => handleSelect(transcriber)}>
        {transcriber === "All Transcribers" || transcriber === "No Transcriber"
          ? transcriber
          : `${transcriber}: ${hoursTranscribed?.[transcriber] || 'N/A'}`}
      </MenuItem>,
      index < array.length - 1 ? <Divider key={`divider-${transcriber}`} sx={{ my: 1 }} /> : null
    ]).filter(Boolean),
  [uniqueTranscribers, handleSelect, hoursTranscribed]);

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          textTransform: "none",
          minHeight: 48,
          justifyContent: "flex-start",
          px: 2.5,
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        {currentFilter}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 1,
            marginTop: 1,
            color: "rgb(55, 65, 81)",
            boxShadow:
              "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            "& .MuiMenu-list": {
              padding: "5px 0",
            },
          },
        }}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default TranscriberFilterTab;