// AudioLoading.jsx
import React from 'react';
import './AudioLoading.css';

const AudioLoading = () => {
  return (
    <div className="audio-loading">
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
};

export default AudioLoading;
