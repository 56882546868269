import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import DatastoreOptions from "../datastores/DatastoreOptions";
import MessageInput from "../ChatComponent/MessageInput";
import MessageList from "../ChatComponent/MessageList";

import { handleSemanticRetrieverApiRequest } from "./semanticRetrieverApiHandler";
import { semanticRetrieverActions } from "./semanticRetriever-slice";
import { linkMap } from "../../../Pages/Global/Config/linkMapUtils";

import { alertActions } from "../../Alert/alertSlice";

const SemanticRetriever = () => {
  const dispatch = useDispatch();

  const {
    chatHistory,
    botIsTyping,
    uploadedDocumentsFiles,
    documentLoadStatus,
    datastoreOption,
    deleteIconStatus,
    deleteFileIconStatuses,
    availableVectorDatastores,
    selectedVectorDB,
    selectedMenuItem,
    loadingAvailableDatastores,
    datastoreName,
    cloudUploadStatus,
    dbToUse,
    disableCreateNewCorpora,
    messageText,
    messageType,
    nameChangeDisabled,
  } = useSelector((state) => state.semanticRetrieverData);

  const { selectedFeature } = useSelector((state) => state.featureData);

  const inputFileRef = useRef(null);
  const [newMessage, setNewMessage] = useState("");
  const chatListRef = useRef(null);
  const [newDatastoreNameError, setNewDatastoreNameError] = useState(false);

  useEffect(() => {
    if (messageText !== "" && messageType !== "") {
      dispatch(
        alertActions.showAlert({
          type: messageType,
          message: messageText,
        })
      );
      dispatch(semanticRetrieverActions.resetAlertMessage());
    }
  }, [messageText, messageType]);

  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
  }, [chatHistory, botIsTyping]);

  const handleDatastoreNameChange = (event) => {
    const newName = event.target.value;
    dispatch(semanticRetrieverActions.setDatastoreName({ newName }));
    setNewDatastoreNameError(false);
  };

  const handleSaveNewDatastore = () => {
    const existingDB = availableVectorDatastores.find(
      (db) => db.db_name === datastoreName
    );

    if (existingDB) {
      setNewDatastoreNameError(true);
      return;
    }

    dispatch(
      handleSemanticRetrieverApiRequest({
        type: "createCorpus",
        message: datastoreName,
      })
    );
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    // console.log("!1111");
    dispatch(
      semanticRetrieverActions.updateChatHistory({
        text: newMessage,
        sender: "user",
        dbToUse: dbToUse,
      })
    );
    // console.log("!1111");
    setNewMessage("");
    // console.log("!1111");
    dispatch(
      handleSemanticRetrieverApiRequest({
        type: "sendMessage",
        message: { newMessage },
      })
    );
  };

  const handleClearChat = () => {
    dispatch(handleSemanticRetrieverApiRequest({ type: "deleteChatHistory" }));
  };

  const handleCloudUploadStatus = () => {
    dispatch(handleSemanticRetrieverApiRequest({ type: "deleteChatHistory" }));
  };

  const handleUploadFile = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const formData = new FormData();
    const filesObject = {};

    for (let i = 0; i < files.length; i++) {
      formData.append(`files`, files[i]);
      filesObject[files[i].name] = "pending";
    }
    dispatch(
      semanticRetrieverActions.setUploadedDocumentsFiles({
        files: filesObject,
      })
    );

    dispatch(
      handleSemanticRetrieverApiRequest({
        type: "uploadFiles",
        formData,
        feature: linkMap[selectedFeature],
      })
    );
    event.target.value = null;
  };

  const handleGetDB = () => {
    console.log(selectedVectorDB.vectorDBName);
    dispatch(
      handleSemanticRetrieverApiRequest({
        type: "downloadVectorDB",
        message: selectedVectorDB.vectorDBName,
      })
    );
    dispatch(
      semanticRetrieverActions.setSelectedMenuItem(
        selectedVectorDB.vectorDBName
      )
    );
  };

  useEffect(() => {
    handleGetDBAvailable();
  }, []);

  const handleGetDBAvailable = useCallback(() => {
    dispatch(
      handleSemanticRetrieverApiRequest({
        type: "fetch_corpus_data",
      })
    );
  }, [dispatch]);

  const handleSingleFileDelete = (event) => {};

  const handleDeleteCorpus = (selectedDB) => {


    dispatch(
      handleSemanticRetrieverApiRequest({
        type: "deleteCorpus",
        message: selectedDB,
        // message: selectedVectorDB.vectorDBName,
      })
    );
    if (selectedDB === datastoreName) {
      dispatch(semanticRetrieverActions.deleteLocalCorpus());
    }

    dispatch(
      handleSemanticRetrieverApiRequest({
        type: "fetch_corpus_data",
      })
    );
  };

  const handleSelectDB = (selectedValue) => {
    try {
      const selectedDBFiles = availableVectorDatastores.find(
        (db) => db.db_name === selectedValue
      );
      dispatch(
        semanticRetrieverActions.updateSelectedVectorDB({
          feature: selectedFeature,
          selectedDB: {
            vectorDBName: selectedValue,
            vectorDBFiles: selectedDBFiles.files,
            created: selectedDBFiles.created,
          },
        })
      );
      dispatch(semanticRetrieverActions.setDBToUse(selectedValue));
      dispatch(handleSemanticRetrieverApiRequest({ type: "getHistory" }));
    } catch (error) {
      dispatch(semanticRetrieverActions.setDBToUse(datastoreName));
    }
  };

  useEffect(() => {
    if (disableCreateNewCorpora) {
      dispatch(
        alertActions.showAlert({
          type: "warning",
          message: "There are already 5 corpora, which is the maximum allowed.",
        })
      );

    }
  }, [disableCreateNewCorpora]);

  return (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        height: "70vh",
        overflow: "hidden",
      }}
    >
      <DatastoreOptions
        datastoreOption={datastoreOption}
        deleteIconStatus={deleteIconStatus}
        deleteFileIconStatuses={deleteFileIconStatuses}
        datastoreName={datastoreName}
        uploadedDocumentsFiles={uploadedDocumentsFiles}
        documentLoadStatus={documentLoadStatus}
        cloudUploadStatus={cloudUploadStatus}
        loadingAvailableDatastores={loadingAvailableDatastores}
        handleGetDB={handleGetDB}
        handleGetDBAvailable={handleGetDBAvailable}
        handleSelectDB={handleSelectDB}
        availableVectorDatastores={availableVectorDatastores}
        selectedVectorDB={selectedVectorDB}
        selectedMenuItem={selectedMenuItem}
        handleDatastoreNameChange={handleDatastoreNameChange}
        handleSaveNewDatastore={handleSaveNewDatastore}
        newDatastoreNameError={newDatastoreNameError}
        handleSingleFileDelete={handleSingleFileDelete}
        disableCreateNewCorpora={disableCreateNewCorpora}
        handleDeleteDB={handleDeleteCorpus}
        nameChangeDisabled={nameChangeDisabled}
        setDatastoreOptions={(value) =>
          dispatch(semanticRetrieverActions.setDatastoreOption(value))
        }
      />

      <MessageList
        chatHistory={chatHistory}
        chatListRef={chatListRef}
        botIsTyping={botIsTyping}
        dbToUse={dbToUse}
      />
      <MessageInput
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        handleSendMessage={handleSendMessage}
        handleClearChat={handleClearChat}
        handleUploadFile={handleUploadFile}
        inputFileRef={inputFileRef}
        documentLoadStatus={documentLoadStatus}
        selectedFeature={selectedFeature}
        handleFileChange={handleFileChange}
        disableCreateNewCorpora={disableCreateNewCorpora}
        datastoreName={datastoreName}
        cloudUploadStatus={cloudUploadStatus}
      />
    </Box>
  );
};

export default SemanticRetriever;
