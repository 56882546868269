import ThreeSectionLayout from "../Components/Layout/ThreeSectionLayout";
import ReductionComponent from "../Components/VoiceReduction/ReductionComponent";
import autoencoderImage from "../Pages/Global/Icons/autoencoder.webp"
import SpectralGatingImage from "../Pages/Global/Icons/SpectralGating.webp";

import { Typography } from "@mui/material";


function Reduction() {

  const leftSection = {
    title: "Features",
    features: [
      { label: "Auto Encoder", value: "feature1", image:autoencoderImage },
      { label: "Spectral Gating", value: "feature2", image: SpectralGatingImage },

    ],
    action: (feature) => console.log(`${feature.value} clicked`),
  };

  const middleSection = {
    content: (
      // <Typography variant="h1" gutterBottom>
      //   Voice Transcript Component
      // </Typography>
      <ReductionComponent />
    ),
  };

  const rightSection = {
    title: "Noise Reduction",
    content: (
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        sx={{ width: "100%", textAlign: "center" }}
      >
        Noise reduction for transcription cleans audio recordings by filtering
        out background noise like chatter, hum, or wind. This improves the
        clarity of speech, making it easier for automatic transcription software
        to understand and accurately convert audio to text. Different techniques
        like spectral subtraction or deep learning models can be used, each with
        its own strengths and weaknesses depending on the noise type and desired
        level of accuracy.
      </Typography>
    ),
  };

  return (
    <>
      <ThreeSectionLayout
        leftSection={leftSection}
        middleSection={middleSection}
        rightSection={rightSection}
      />
    </>
  );
}

export default Reduction;
