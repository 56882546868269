import React from "react";
import { IconButton, Avatar } from "@mui/material";

function FeatureIcon({  handleFeatureClick, feature, border }) {
  return (
    <div>

        <IconButton
          onClick={() => handleFeatureClick(feature)}
          sx={{
            borderRadius: "50%",
            padding: "3px",
            alignItems:"flex-start",
            border: border,
            boxShadow: "1px 1px 3px rgba(0,0,0,0.1)",
            ":active": {
              boxShadow: "inset 0 0 8px rgba(0,0,0,0.3)",
              backgroundColor: "#f0f0f0",
            },
            transition: "box-shadow 0.3s ease, background-color 0.3s ease",
          }}
        >
          <Avatar
            src={feature.image}
            alt={feature.label}
            sx={{ width: 56, height: 56 }} // Adjust size as needed
          />
        </IconButton>

    </div>
  );
}

export default FeatureIcon;
