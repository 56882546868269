import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { featureActions } from "../../store/feature-slice";
import { useLocation } from "react-router-dom";
import FeatureIcon from "./FeatureIcon";
import { alertActions } from "../Alert/alertSlice";

const ThreeSectionLayout = ({ leftSection, middleSection, rightSection }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { deployments } = useSelector(
    (state) => state.kubernetesControllerData
  );
  const { featureDescription,selectedFeature } = useSelector((state) => state.featureData);

  const [selectedFeaturePage, setSelectedFeaturePage] = useState("");
  const [deploymentState, setDeploymentState] = useState({});

  useEffect(() => {
    const updateBorders = () => {
      setDeploymentState(deployments);
    };

    updateBorders();
  }, [deployments]);

  const determineBorder = (feature) => {
    const state = getStateHandler(feature.label);
    const deployment = deploymentState?.[selectedFeaturePage]?.find(
      (d) => d.name.replace(/ /g, "-") === feature.label.replace(/ /g, "-")
    );
    if (state === "Off" && deployment?.replicas === 0) {
      return "3px solid black";
    } else if (
      state === "Pending" ||
      state === "ContainerCreating" ||
      state === "Running"
    ) {
      return "3px solid yellow";
    } else if (
      state === "Available" &&
      parseInt(deployment?.replicas || 0) > 0
    ) {
      return "3px solid green";
    } else if (state === "Loading") {
      return "none";
    }
    return "3px solid red";
  };

  const getStateHandler = (featureLabel) => {
    const state =
      deploymentState[selectedFeaturePage]?.find((d) => d.name === featureLabel)
        ?.state || "Unknown";
    return state;
  };

  const handleFeatureClick = (feature) => {
    dispatch(featureActions.setSelectedFeature(feature.label))
    localStorage.setItem(
      `selectedFeature_${selectedFeaturePage}`,
      feature.label
    );
    dispatch(featureActions.setFeatureLink(feature.label));
    if (leftSection.action) {
      leftSection.action(feature);
    }
  };

  useEffect(() => {
    const defaultFeatureMap = {
      "/orgChat": "RAG langchain with OpenAI",
      "/noiseReduction": "Auto Encoder",
      "/voiceRecognition": "Pyannote Embeddings",
      "/signLanguage": "TBA",
      "/objectDetection": "YOLO",
      "/transcription": "Manual Transcribe",
    };
    const setPage = {
      "/orgChat": "Organizational Chatbot",
      "/noiseReduction": "Noise Reduction",
      "/voiceRecognition": "Voice Recognition",
      "/objectDetection": "Object Detection",
      "/transcription": "Transcription",
    };
    const page = setPage[location.pathname] || "";
    setSelectedFeaturePage(page);
    const savedFeature = localStorage.getItem(`selectedFeature_${page}`);

    if (savedFeature) {
      dispatch(featureActions.setSelectedFeature(savedFeature))
      dispatch(featureActions.setFeatureLink(savedFeature));
    } else {
      const defaultFeature = defaultFeatureMap[location.pathname] || "";
      dispatch(featureActions.setSelectedFeature(defaultFeature))
      dispatch(featureActions.setFeatureLink(defaultFeature));

    }
  }, [location, dispatch]);

  useEffect(() => {
    if (selectedFeature && deploymentState[selectedFeaturePage]) {
      const deployment = deploymentState[selectedFeaturePage]?.find(
        (d) => d.name === selectedFeature
      );

      let type;
      let message;

      if (deployment) {
        switch (deployment.state) {
          case "Available":
            message = "Service is running";
            type = "success";
            break;
          case "Off":
            message = "Service is off";
            type = "info";
            break;
          case "Pending":
          case "ContainerCreating":
            message = "Service is Loading";
            type = "warning";
            break;
          case "Loading":
            message = "Service not found";
            type = "warning";
            break;
          case "Unknown":
            message = "Unknown Error";
            type = "error";
            break;
          default:
            message = `Error with service: ${deployment.state}`;
            type = "error";
        }
      } else {
        message = `Service not found`;
        type = "error";
      }

      if (message && type) {
        dispatch(
          alertActions.showAlert({
            type: type,
            message: message,
          })
        );
      }
    }
  }, [selectedFeature, deploymentState, selectedFeaturePage, dispatch]);

  return (
    <Box sx={{ p: { xs: "1rem 1rem", sm: "1rem 1rem", md: "1rem 1rem" } }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={4} md={2} lg={2}>
          <Paper
            elevation={3}
            sx={{
              paddingTop: 2,
              paddingRight: 5,
              paddingLeft: 5,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: { xs: "auto", sm: "calc(75vh - 48px)" },
              width: "100%",

            }}
          >
            <Typography
              variant="h2"
              gutterBottom
              component="div"
              sx={{ width: "100%", textAlign: "center", pb: "1rem" }}
            >
              {leftSection.title}
            </Typography>
            <Grid
              container
              spacing={{ xs: 3, lg: 9 }}
              justifyContent="space-around"
              alignItems="start"
            >
              {leftSection.features.map((feature, index) => (
                <Grid item xs={4} sm={6} md={12} lg={4} key={index}>
                  {" "}
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <FeatureIcon
                      key={index}
                      border={
                        selectedFeature === feature.label
                          ? determineBorder(feature)
                          : "none"
                      }
                      handleFeatureClick={handleFeatureClick}
                      feature={feature}
                    />
                    <Typography
                      variant="caption"
                      sx={{ mt: 1, textAlign: "center" }}
                    >
                      {feature.label}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: { xs: "auto", sm: "calc(75vh - 48px)" },
              my: { xs: 2, sm: 0 },
            }}
          >
            {middleSection.content}
          </Paper>
        </Grid>

        <Grid item xs={12} sm={4} md={2} lg={2}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: { xs: "auto", sm: "calc(75vh - 48px)" },
              my: { xs: 2, sm: 0 },
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              component="div"
              sx={{ width: "100%", textAlign: "center", paddingBottom: "1rem" }}
            >
              {rightSection.title}
            </Typography>
            {rightSection.content}
            <Typography
              variant="h3"
              gutterBottom
              component="div"
              sx={{ width: "100%", textAlign: "center", paddingTop: "1rem" }}
            >
              {selectedFeature}
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              sx={{
                width: "100%",
                textAlign: "center",
                paddingTop: "0rem",
                paddingBottom: "1rem",
              }}
            >
              {featureDescription}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ThreeSectionLayout;
