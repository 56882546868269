// LoadingDots.jsx
import React from 'react';
import './LoadingDots.css';

const LoadingDots = () => {
  return (
    <div className="loading-container">
      <span className="typing-text">Typing</span>
      <div className="loading-dots">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default LoadingDots;
