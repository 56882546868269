import Topbar from "./Topbar";
import { Outlet, useNavigate } from "react-router-dom";
import NavigationThumbnails from "./NavigationThumbnails";
import { useLocation } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import ScrollableContainer from "../../../Components/Layout/ScrollableContainer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback } from "react";
import jwt_decode from "jwt-decode";
import { userActions } from "../../../Components/Login/userSlice";

import AlertComponent from "../../../Components/Alert/AlertComponent";

function RootLayout() {
  const location = useLocation();
  const navigate = useNavigate();

  const { thumbnails } = useSelector((state) => state.featureData);
  const dispatch = useDispatch();

  const { userAuth, userToken } = useSelector(
    (state) => state.userData
  );

  const isTokenExpired = useCallback(
    (token) => {
      try {
        const decodedToken = jwt_decode(token);
        if (!decodedToken || !decodedToken.exp) {
          return true;
        }

        const dateNow = new Date();
        const expirationDate = new Date(decodedToken.exp * 1000); 

        return dateNow > expirationDate;
      } catch (error) {
        return true; 
      }
    },
    []
  );

  useEffect(() => {
    dispatch(userActions.checkAuth());
    if (!userAuth || isTokenExpired(userToken)) {
      navigate("/login");
    }
  }, [userAuth, userToken, navigate, isTokenExpired]);


  return (
    <div className="app">
      <main className="content">
        <AlertComponent />
        <Topbar />
        <Outlet />
        <Box
          sx={{
            display: { xs: "none", md: "block", sm: "block", lg: "block" },
            padding: "0rem 3rem 1rem 3rem",
          }}
        >
          <Grid container justifyContent="center">
            <ScrollableContainer>
              {thumbnails.map((thumb, index) => (
                <Grid item key={index}>
                  <NavigationThumbnails
                    title={thumb.title}
                    imageSrc={thumb.imageSrc}
                    navigateTo={thumb.navigateTo}
                    highlight={location.pathname === thumb.navigateTo} 
                  />
                </Grid>
              ))}
            </ScrollableContainer>
          </Grid>
        </Box>
      </main>
    </div>
  );
}

export default RootLayout;
