import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  Button,
  CircularProgress,
  IconButton
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { objectDetectionActions } from "../../store/object-detection/objectDetection-slice";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { handleObjectDetectionApiRequest } from "../../store/object-detection/objectDetectionApiHandler";


const WhiteIconButton = styled(IconButton)({
    color: "#FFFFFF",
  });

const QnAComponent = () => {
  const dispatch = useDispatch();
  const { imageSrc, fileName, imageLoadingState, imageAnswer } =
  useSelector((state) => state.objectDetectionData);

  const [question, setQuestion] = useState("");
  // const [loading, setLoading] = useState(false);
  // const [answer, setAnswer] = useState("");
  const [uploadFile, setUploadFile] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // const formData = new FormData();
    // formData.append("file", file);
    // dispatch(
    //   handleObjectDetectionApiRequest({ type: "uploadImage", formData })
    // );

    if (file) {
      const imageSrc = URL.createObjectURL(file);
      setUploadFile(file)
      dispatch(objectDetectionActions.setImageSrc(imageSrc));
      dispatch(objectDetectionActions.setFileName(file.name));
    }
  };
  const handleAsk = async () => {

    const formData = new FormData();

    formData.append("file", uploadFile);
    formData.append("question", question);
    dispatch(handleObjectDetectionApiRequest({ type: "ask_my_image", formData }));

    // setLoading(true);
    dispatch(objectDetectionActions.setImageQuestion(question));
    // Simulate an API call
    // setTimeout(() => {
    //   // Mock answer received from the server
    //   const mockAnswer = `This is an answer for: ${question}`;
    //   setAnswer(mockAnswer);
    // //   setLoading(false);
    // }, 2000);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            //   margin: "5rem 0rem 0rem 0rem",
            }}
          >
        <img
          src={imageSrc}
          alt="Uploaded"
          style={{
            maxWidth: "90%",
            maxHeight: "350px",
            display: "block", // Make sure the image is not stretching beyond its aspect ratio
          }}
        />

            <Box
              sx={{
                flex: 1,
                p: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                  p: 1,
                  backgroundColor: "#0277bd",
                  border: "2px solid black",
                  borderRadius: "9px",
                  width: "fit-content",
                }}
              >
                <WhiteIconButton color="primary" component="label">
                  <CloudUploadIcon />
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </WhiteIconButton>
                <Typography sx={{ color: "#FFFFFF", marginLeft: "8px" }}>
                  {fileName || "No file selected"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
              marginTop: "20px",
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    paddingRight: "20px",
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Question:
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginY: 2 }}
                  />
                  <Typography variant="h4" gutterBottom>
                    Answer:
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={1}>
                <Divider orientation="vertical" flexItem />
              </Grid>

              <Grid item xs={7}>
                <TextField
                  fullWidth
                  placeholder="Type your question here..."
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAsk}
                  disabled={imageLoadingState}
                  sx={{ marginTop: "20px" }}
                >
                  Ask
                </Button>
                {imageLoadingState ? (
                  <CircularProgress
                    sx={{ display: "block", margin: "20px auto" }}
                  />
                ) : (
                  imageAnswer && (
                    <Box
                      sx={{
                        border: "1px solid #ccc",
                        marginTop: "20px",
                        padding: "10px",
                        borderRadius: "4px",
                      }}
                    >
                      {imageAnswer}
                    </Box>
                  )
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default QnAComponent;
