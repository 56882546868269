import { createSlice } from "@reduxjs/toolkit";
import { handleManualTranscribeApiRequest } from "./manualTranscribeApiHandler";

const alignWordsWithTimestamps = (translation, wordsTranscriptTimestamp) => {
  const translationWords = translation.split(" ");
  const transcriptWords = wordsTranscriptTimestamp.words;

  const alignedWords = transcriptWords.map((word, index) => {
    const translationWord = translationWords[index];
    return {
      start: word.start,
      end: word.end,
      word: translationWord || word.word,
    };
  });

  return alignedWords;
};
const formatTimeNumber = (timeNumber) => {
  if (typeof timeNumber !== "number") {
    console.error("Input must be a number");
    return "Invalid input";
  }
  const remainingSeconds = Math.round(timeNumber % 60);

  const hours = Math.floor(timeNumber / 3600);
  const minutes = Math.floor((timeNumber % 3600) / 60);

  let result = [];

  if (hours > 0) {
    result.push(`${hours} Hour${hours !== 1 ? "s" : ""}`);
  }

  if (minutes > 0 || (hours > 0 && remainingSeconds > 0)) {
    result.push(`${minutes} Min${minutes !== 1 ? "s" : ""}`);
  }

  if (remainingSeconds > 0 || result.length === 0) {
    result.push(`${remainingSeconds} Sec${remainingSeconds !== 1 ? "s" : ""}`);
  }
  return result.join(", ");
};

const manualTranscribeSlice = createSlice({
  name: "manualTranscribe",
  initialState: {
    transcriptionId: "",
    audioPath: "",
    audioSrcState: "",
    audioFilename: "",
    originalTranscript: "",
    newTranscript: "",
    dialogOpen: false,
    messageType: "",
    messageText: "",
    transcriptStatus: "",
    uploadButtonDisableStatusMessage: "",
    audiofileLength: 0,
    uploadButtonDisableStatus: true,
    transcriptData: [],
    hoursTranscribed: {},
    loadingMongoFiles: null,
    filesStatus: [],
    fetchingTranscriptStatus: "idle",
    totalWER: "Calculating..",
    agentTags: {
      gender: "",
      age: "",
      accent: "",
      conversation_type: "",
      subject: "",
      emotion: "",
      intention: "",
      intention_success: "",
      names_or_terms: "",
      double_meaning: "",
    },
    clientTags: {
      gender: "",
      age: "",
      accent: "",
      conversation_type: "",
      subject: "",
      emotion: "",
      intention: "",
      intention_success: "",
      names_or_terms: "",
      double_meaning: "",
    },
    agentErrors: {},
    clientErrors: {},
    agentTouched: {},
    clientTouched: {},
    isAgentEnabled: true,
    isClientEnabled: true,
    hasErrors: true,

    words_transcript_timestamp: {
      words: [],
    },
  },
  reducers: {
    setAgentTags: (state, action) => {
      state.agentTags = { ...state.agentTags, ...action.payload };
    },
    setClientTags: (state, action) => {
      state.clientTags = { ...state.clientTags, ...action.payload };
    },
    setAgentErrors: (state, action) => {
      state.agentErrors = { ...state.agentErrors, ...action.payload };
    },
    setClientErrors: (state, action) => {
      state.clientErrors = { ...state.clientErrors, ...action.payload };
    },
    setAgentTouched: (state, action) => {
      state.agentTouched = { ...state.agentTouched, ...action.payload };
    },
    setClientTouched: (state, action) => {
      state.clientTouched = { ...state.clientTouched, ...action.payload };
    },
    setIsAgentEnabled: (state, action) => {
      state.isAgentEnabled = action.payload;
    },
    setIsClientEnabled: (state, action) => {
      state.isClientEnabled = action.payload;
    },
    setHasErrors: (state, action) => {
      state.hasErrors = action.payload;
    },

    setAudiofileLength: (state, action) => {
      const data = action.payload;
      state.audiofileLength = data;
    },
    setWordsTranscriptTimestamp: (state, action) => {
      const data = action.payload;
      state.words_transcript_timestamp = data;
      console.log(state.words_transcript_timestamp);
    },
    setAudioFile: (state, action) => {
      const data = action.payload;
      state.audioPath = data;
      state.audioSrcState = "success";
    },
    setTranscriptionToEdit: (state, action) => {
      const data = action.payload;
      state.transcriptionId = data.transcriptionId;
      state.originalTranscript = data.originalTranscript;
      state.newTranscript = data.newTranscript;
      state.audioFilename = data.audioFilename;
      state.transcriptStatus = data.status;
      state.words_transcript_timestamp.words = alignWordsWithTimestamps(
        state.originalTranscript,
        data.words_transcript_timestamp
      );
    },
    setOriginalTranscript: (state, action) => {
      state.originalTranscript = action.payload;
    },
    setNewTranscript: (state, action) => {
      state.newTranscript = action.payload;
    },
    setDialogOpen: (state, action) => {
      state.dialogOpen = action.payload;
    },
    resetAlertMessage: (state, action) => {
      state.messageType = "";
      state.messageText = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleManualTranscribeApiRequest.pending, (state, action) => {
        const { type } = action.meta.arg;

        switch (type) {
          case "get_files_status":
            state.loadingMongoFiles = "loading";
            break;
          case "get_average_wer":
            state.totalWER = "Calculating..";
            break;
          case "fetch_transcripts":
            state.fetchingTranscriptStatus = "Loading";
            state.originalTranscript = "";
            state.newTranscript = "";

            break;
          case "get_hours_transcribed":
            state.hoursTranscribed[action.meta.arg.transcriber_username] =
              "Loading";

            break;
          case "get_transcript":
            state.audioFilename = "Loading";
            state.originalTranscript = "Loading";
            state.audioSrcState = "Loading";

            break;
          case "fetch_audio_file":
            state.audioSrcState = "Loading";
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleManualTranscribeApiRequest.fulfilled, (state, action) => {
        const { type, data } = action.payload;
        switch (type) {
          case "get_average_wer":
            console.log(data);
            state.totalWER = data;
            break;
          case "fetch_transcripts":
            state.fetchingTranscriptStatus = "success";
            state.originalTranscript = data.original_transcript;
            state.newTranscript = data.new_transcript;

            break;
          case "update_available_status":
            state.transcriptionId = "";
            break;
          case "get_files_status":
            state.loadingMongoFiles = null;
            state.filesStatus = data;
            break;
          case "get_hours_transcribed":
            const userName = data.transcriber_username;
            state.hoursTranscribed[userName] = formatTimeNumber(
              data.total_audiofile_length_seconds
            );
            break;
          case "get_transcript":
            state.audioFilename = data.filename;
            state.transcriptionId = data.transcript_id;
            state.originalTranscript = data.translation;
            state.newTranscript = data.translation;
            state.words_transcript_timestamp.words = alignWordsWithTimestamps(
              state.newTranscript,
              data.words_transcript_timestamp
            );

            break;
          case "check_transcript_server_status":
            state.uploadButtonDisableStatus = false;
            break;
          case "fetch_audio_file":
            state.audioPath = data;
            state.audioSrcState = "success";

            break;
          case "save_transcription":
            state.messageType = "success";
            state.messageText = `Transcription saved successfully`;
            break;
          case "save_error_transcript":
            state.messageType = "success";
            state.messageText = `Transcription errors saved successfully`;
            break;

          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleManualTranscribeApiRequest.rejected, (state, action) => {
        const { type, data } = action.meta.arg;

        switch (type) {
          case "get_average_wer":
            // state.totalWER = "0.07547169811320754";
            state.totalWER = "N/A";
            break;
          case "get_files_status":
            state.loadingMongoFiles = "error";
            break;
          case "fetch_transcripts":
            state.fetchingTranscriptStatus = "error";

            break;
          case "get_files_status":
            break;
          case "update_available_status":
            break;

          case "get_hours_transcribed":
            state.hoursTranscribed = "Fetching Failed";
            break;
          case "fetch_audio_file":
            state.audioFilename = "Error fetching file from bucket";
            state.audioSrcState = "error";
            break;
          case "check_transcript_server_status":
            state.uploadButtonDisableStatus = true;
            state.uploadButtonDisableStatusMessage =
              "Transcript Server Is Down";
            break;
          case "get_transcript":
            state.audioFilename = "Error";
            state.originalTranscript = "Error";
            break;

          case "save_transcription":
            state.messageType = "error";
            state.messageText = `Error saving transcription`;
            break;
          case "save_error_transcript":
            state.messageType = "error";
            state.messageText = `Error saving transcription errors`;
            break;

          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      });
  },
});

export const manualTranscribeActions = manualTranscribeSlice.actions;

export default manualTranscribeSlice;
