import { createAsyncThunk,  } from "@reduxjs/toolkit";
import axios from "axios";

export const handleObjectDetectionApiRequest = createAsyncThunk(
  "api/handleObjectDetectionApiRequest",
  async ({ type, formData = null, message = "" }, thunkAPI) => {
    const link = thunkAPI.getState().featureData.link; // Assume you have a link in your api slice's state
    const userUniqueId = thunkAPI.getState().featureData.userUniqueId; // Assume you have a link in your api slice's state
    let url;
    let config = {};
    let payload;
    console.log("link")
    console.log(link)
    let METHOD = "post"


    switch (type) {
      case "uploadFile":
        METHOD = "post"
        url = `${link}proccess_file/${userUniqueId}`;
        config.headers = { "Content-Type": "multipart/form-data" };
        payload = formData;
        break;
      case "process_video":
        METHOD = "post"
        url = `${link}process_video/${userUniqueId}`;
        config.headers = { "Content-Type": "multipart/form-data" };
        payload = formData;
        break;
      case "uploadImage":
        url = `${link}proccess_image`;
        config.headers = { "Content-Type": "multipart/form-data" };
        payload = formData;
        break;
      case "ask_my_image":
        url = `${link}ask_my_image`;
        config.headers = { "Content-Type": "multipart/form-data" };
        payload = formData;

        break;
      case "get_files_list":
        METHOD = "get"
        url = `${link}get_all_video`;
        config.headers = { "Content-Type": "multipart/form-data" };
        // payload = formData;
        break;
      case "get_file":
        METHOD = "get"
        url = `${link}load-video/${message}`;
        console.log(`This is the link from load-video: ${url}`)
        config.headers = { "Content-Type": "multipart/form-data" };
        payload = formData;
        break;
      default:
        throw new Error("Unsupported API request type");
    }
    // const response = await axios.METHOD(url, payload, config);
    const response = await axios({
      method: METHOD,
      url: url,
      data: payload,
      ...config,
    });
    return { type, data: response.data };
  }
);
