import React, { useRef, useState } from 'react';
// import axios from 'axios';

const SignLanguageComponent = () => {
  const videoRef = useRef(null);
  const [streaming, setStreaming] = useState(false);
  const [mediaStream, setMediaStream] = useState(null); // Use state to manage mediaStream

  const startVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setMediaStream(stream); // Update mediaStream state
      videoRef.current.srcObject = stream;
      videoRef.current.play();
      setStreaming(true);
    } catch (error) {
      console.error('Error accessing the webcam:', error);
    }
  };

  const stopVideo = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop());
      setMediaStream(null); // Clear mediaStream state
    }
    setStreaming(false);
  };

  // const captureFrame = () => {
  //   if (!streaming) return;

  //   const canvas = document.createElement('canvas');
  //   canvas.width = videoRef.current.videoWidth;
  //   canvas.height = videoRef.current.videoHeight;

  //   const ctx = canvas.getContext('2d');
  //   ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

  //   // Convert canvas to JPEG
  //   canvas.toBlob((blob) => {
  //     sendFrameToApi(blob);
  //   }, 'image/jpeg');
  // };

  // const sendFrameToApi = async (blob) => {
  //   const formData = new FormData();
  //   formData.append('frame', blob);

  //   try {
  //     const response = await axios.post('YOUR_API_ENDPOINT', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     console.log('Frame sent successfully', response.data);
  //   } catch (error) {
  //     console.error('Error sending frame to API:', error);
  //   }
  // };

  return (
    <div style={{ width: '95%', height: '95%' }}>
      <video 
        ref={videoRef} 
        style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
        autoPlay
        playsInline
      ></video>
      <div>
        <button onClick={startVideo} disabled={streaming}>Start</button>
        <button onClick={stopVideo} disabled={!streaming}>Stop</button>
      </div>
    </div>
  );
};

export default SignLanguageComponent;
