import React, { useEffect, useRef, useState } from "react";
import { Container, Paper, ThemeProvider, createTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "../../Alert/alertSlice";
import TranscriptionControls from "../TranscriptionControls";
import TagSelectionComponent from "../TagSelectionComponent";
import ReasonDialog from "../ReasonDialog";
import ActionButtons from "../ActionButtons";
import { useTranscription } from "../../../Hooks/Transcription/useTranscription";
import { useTags } from "../../../Hooks/Transcription/useTags";
import { manualTranscribeActions } from "./manualTranscribe-slice";
import { handleManualTranscribeApiRequest } from "./manualTranscribeApiHandler";
import useAudioFileUpload from "../../../Hooks/useAudioFileUpload";
// import FileFilterManager from "../../Dialog/FileFilterManager";

const theme = createTheme({
  direction: "rtl",
});

const ManualTranscribeComponent = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [seekTime, setSeekTime] = useState(null);

  const { link } = useSelector((state) => state.featureData);
  const { handleAudioFileUpload } = useAudioFileUpload(link, dispatch);

  const { uploadButtonDisableStatus, uploadButtonDisableStatusMessage } =
    useSelector((state) => state.manualTranscribeData);
  const {
    agentTags,
    clientTags,
    setTags,
    isAgentEnabled,
    setIsAgentEnabled,
    isClientEnabled,
    setIsClientEnabled,
    hasErrors,
  } = useTags();

  const {
    transcriptionId,
    originalTranscript,
    newTranscript,
    dialogOpen,
    audioFilename,
    audioPath,
    audioSrcState,
    messageText,
    messageType,
    words_transcript_timestamp,
    words_transcript_timestamp_Test,
    originalTranscriptTest,
    fetchTranscript,
    fetchAudioFile,
    handleSave,
    handleUnableToTranscribe,
    handleClose,
    handleDialogSubmit,
    handleNewTranscriptChange,
    handleWordClick,
    diffText,
    transcriptStatus,
  } = useTranscription(
    dispatch,
    agentTags,
    clientTags,
    setTags,
    isAgentEnabled,
    isClientEnabled
  );

  useEffect(() => {
    dispatch(
      handleManualTranscribeApiRequest({
        type: "check_transcript_server_status",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (transcriptionId === "") {
      fetchTranscript();
    }
  }, [fetchTranscript, transcriptionId, dispatch]);

  useEffect(() => {
    const cleanup = async () => {
      try {
        if (transcriptionId && transcriptStatus !== "DONE") {
          dispatch(
            handleManualTranscribeApiRequest({
              type: "update_available_status",
              transcript_id: transcriptionId,
            })
          );
        }
      } catch (error) {
        console.error("Failed to change status:", error);
      }
    };

    // Run the cleanup function when the component is unmounted
    window.addEventListener("beforeunload", cleanup);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  });

  useEffect(() => {
    if (messageText && messageType) {
      dispatch(
        alertActions.showAlert({
          type: messageType,
          message: messageText,
        })
      );
      dispatch(manualTranscribeActions.resetAlertMessage());
    }
  }, [messageText, messageType, dispatch]);

  useEffect(() => {
    if (transcriptionId) {
      fetchAudioFile(transcriptionId);
    }
  }, [transcriptionId, fetchAudioFile]);

  const handleExtractAudioDuration = (audioDuration) => {
    dispatch(
      manualTranscribeActions.setAudiofileLength(audioDuration.toFixed(2))
    );
  };

  const handleTimeUpdate = (time) => {
    setCurrentTime(time);
  };

  const handleWordClickWrapper = (word, index) => {
    const start = handleWordClick(word, index);
    if (start !== null) {
      setSeekTime(start);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ width: "100%" }}>
        <Paper
          elevation={0}
          sx={{ padding: 0, paddingTop: 3, margin: 0, width: "100%" }}
        >
          <TranscriptionControls
            audioFilename={audioFilename}
            audioPath={audioPath}
            audioSrcState={audioSrcState}
            handleTimeUpdate={handleTimeUpdate}
            seekTime={seekTime}
            originalTranscript={originalTranscript}
            originalTranscriptTest={originalTranscriptTest}
            currentTime={currentTime}
            handleWordClick={handleWordClickWrapper}
            wordsTranscriptTimestamp={words_transcript_timestamp}
            words_transcript_timestamp_Test={words_transcript_timestamp_Test}
            diff={diffText()}
            newTranscript={newTranscript}
            handleNewTranscriptChange={handleNewTranscriptChange}
            handleExtractAudioDuration={handleExtractAudioDuration}
          />
          {/* <TagSelectionComponent
            agentTags={agentTags}
            setAgentTags={(tags) =>
              setTags([{ speaker_role: "Agent", ...tags }])
            }
            clientTags={clientTags}
            setClientTags={(tags) =>
              setTags([{ speaker_role: "Client", ...tags }])
            }
            setHasErrors={(hasErrors) =>
              dispatch(manualTranscribeActions.setHasErrors(hasErrors))
            }

            isAgentEnabled={isAgentEnabled}
            setIsAgentEnabled={setIsAgentEnabled}
            isClientEnabled={isClientEnabled}
            setIsClientEnabled={setIsClientEnabled}
          /> */}
          <ActionButtons
            onSave={handleSave}
            onUnableToTranscribe={handleUnableToTranscribe}
            fileInputRef={fileInputRef}
            handleAudioFileUpload={handleAudioFileUpload}
            disabledSaveTranscript={hasErrors}
            disabledUploadButton={uploadButtonDisableStatus}
            tooltipMessage={uploadButtonDisableStatusMessage}
          />
        </Paper>
        <ReasonDialog
          open={dialogOpen}
          onClose={handleClose}
          onSubmit={handleDialogSubmit}
        />
        {/* <FileFilterManager
          open={filterPopupOpen}
          onClose={() => setFilterPopupOpen(false)}
        /> */}
      </Container>
    </ThemeProvider>
  );
};

export default ManualTranscribeComponent;
