import { createSlice, } from "@reduxjs/toolkit";
import { handleSemanticRetrieverApiRequest } from "./semanticRetrieverApiHandler";

const transformData = (data) => {
  return data.map((item) => ({
    db_name: item.corpus_display_name,
    files: Object.keys(item.documents).map((docName) =>
      docName.replace(/\.[^/.]+$/, "")
    ),
    created: new Date().toISOString(),  
  }));
};

const checkNumberOfObjects = (array) => {
  try {
    if (Array.isArray(array)) {
      let result = array.length;
      // The number of objects in the array is simply the length of the array
      return result;
    }
    // If the input is not an array, return 0
    return 0;
  } catch (error) {
    console.log(error);
  }
};

const semanticRetrieverSlice = createSlice({
  name: "semanticRetrieverHandler",
  initialState: {
    botIsTyping: false,
    chatHistory: {}, 
    uploadedDocumentsFiles: {},
    documentLoadStatus: "idle",
    cloudUploadStatus: "idle",
    availableVectorDatastores: [{ db_name: "", files: [], created: "" }],
    datastoreName: "",
    selectedVectorDB: { vectorDBName: "", vectorDBFiles: [], created: "" },
    loadingAvailableDatastores: false,
    deleteSingleFileIconStatus: "idle",
    datastoreOption: "create",
    deleteIconStatus: "idle",
    deleteFileIconStatuses: {},
    selectedMenuItem: "",
    dbToUse: "",

    files: {},
    disableCreateNewCorpora: false,

    messageType: "",
    messageText: "",
    nameChangeDisabled: false,
  },
  reducers: {
    resetAlertMessage: (state, action) => {
      state.messageType = "";
      state.messageText = "";
    },
    setDatastoreName: (state, action) => {
      const { newName } = action.payload;
      state.datastoreName = newName;
    },

    setUploadedDocumentsFiles: (state, action) => {
      const { files } = action.payload;
      state.uploadedDocumentsFiles = files;
    },

    updateSelectedVectorDB: (state, action) => {
      const { selectedDB } = action.payload;
      state.selectedVectorDB = selectedDB;
    },

    setDatastoreOption: (state, action) => {
      const datastoreOption = action.payload;
      state.datastoreOption = datastoreOption;
    },

    setDBToUse: (state, action) => {
      state.dbToUse = action.payload;
    },
    setSelectedMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload;
    },

    deleteLocalCorpus: (state, action) => {
      state.dbToUse = "";
      state.datastoreName = "";
      state.files = {}
      state.documentLoadStatus = "idle"
      state.cloudUploadStatus = "idle"
      state.chatHistory = {};

      state.uploadedDocumentsFiles = {};
      state.nameChangeDisabled= false
    },

    updateChatHistory: (state, action) => {
      const { dbToUse, text, sender } = action.payload;
      if (!state.chatHistory[dbToUse]) {
        state.chatHistory[dbToUse] = [];
      }
      state.chatHistory[dbToUse].push({ text, sender });
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(handleSemanticRetrieverApiRequest.pending, (state, action) => {
        const { type } = action.meta.arg;

        switch (type) {
          case "uploadFiles":
            state.documentLoadStatus = "loading";
            state.deleteIconStatus = "idle";
            break;
          case "sendMessage":
            state.botIsTyping = true;
            break;

          case "deleteSingleFile":
            state.deleteSingleFileIconStatus = "loading";
            break;
          case "deleteChatHistory":
            state.botIsTyping = true;

            break;
          case "getHistory":
            state.botIsTyping = false;
            break;
          case "uploadVectorDB":
            state.cloudUploadStatus = "loading";
            break;
          case "fetch_corpus_data":
            state.loadingAvailableDatastores = true;
            break;
          case "createCorpus":
            state.cloudUploadStatus = "loading";
            break;

          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleSemanticRetrieverApiRequest.fulfilled, (state, action) => {
        const { type, data } = action.payload;

        switch (type) {
          case "uploadFiles":
            state.uploadStatus = "success";
            state.documentLoadStatus = "success";
            state.files = data;
            state.uploadedDocumentsFiles = data;

            break;
          case "sendMessage":
            state.botIsTyping = false;
            let sender;
            data.sender === "Chatbot" ? (sender = "bot") : (sender = "error");
            if (!state.chatHistory[state.dbToUse]) {
              state.chatHistory[state.dbToUse] = [];
            }
            state.chatHistory[state.dbToUse].push({ text: data.text, sender });
            // console.log(state.chatHistory);
            break;
          case "getHistory":
            state.botIsTyping = false;
            state.chatHistory[state.dbToUse] = data.map((message) => ({
              text: message.text,
              sender: message.sender,
            }));
            break;

          case "deleteSingleFile":
            state.deleteSingleFileIconStatus = "idle";
            state.files = data;

            const { message } = action.meta.arg;

            if (state.deleteFileIconStatuses.hasOwnProperty(message)) {
              delete state.deleteFileIconStatuses[message];
            }

            break;
          case "deleteChatHistory":
            state.chatHistory = [
              { text: "שלום, איך אוכל לעזור?", sender: "bot" },
            ];
            state.botIsTyping = false;

            break;
          case "cleanup":
            state.botIsTyping = false;
            state.deleteIconStatus = "idle";
            state.deleteSingleFileIconStatus = "idle";
            state.files = [];
            state.uploadStatus = "idle";
            break;
          case "fetch_corpus_data":
            if (data.length >= 5) {
              state.disableCreateNewCorpora = true;
            } else {
              state.disableCreateNewCorpora = false;
            }
            transformData(data);
            let result = checkNumberOfObjects(data);

            state.availableVectorDatastores = transformData(data);
            state.loadingAvailableDatastores = false;
            break;
          case "uploadVectorDB":
            state.cloudUploadStatus = "success";
            break;
          case "deleteCorpus":
            state.messageType = "success";
            state.messageText = `Corpus:  deleted successfully`;
            state.selectedVectorDB = {
              vectorDBName: "",
              vectorDBFiles: [],
              created: "",
            };
            state.selectedMenuItem = "";

            break;
          case "createCorpus":
            state.cloudUploadStatus = "success";
            state.nameChangeDisabled = true;
            state.dbToUse = state.datastoreName;
            state.messageType = "success";
            state.messageText = `Corpus created successfully`;
            state.uploadedDocumentsFiles = data.file_data;
            console.log(data)
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleSemanticRetrieverApiRequest.rejected, (state, action) => {
        const { type } = action.meta.arg;
        // console.log(action.meta.arg)

        switch (type) {
          case "fetch_corpus_data":
            console.log(action.meta)
            break;
          case "sendMessage":
            state.botIsTyping = false;
            break;
          case "deleteSingleFile":
            const { message } = action.arg;
            state.deleteFileIconStatuses[message] = "error";
            break;
          case "uploadFiles":
            state.documentLoadStatus = "error";
            break;
          case "deleteChatHistory":
            state.botIsTyping = false;
            break;
          case "uploadVectorDB":
            state.cloudUploadStatus = "error";
            break;
          case "getHistory":
            state.botIsTyping = false;
            break;

          case "deleteCorpus":
            state.messageType = "error";
            state.messageText = `Failed to delete Corpus`;
            console.log("ERROROR");
            break;
          case "createCorpus":
            state.cloudUploadStatus = "error";
            state.messageType = "error";
            state.messageText = `Error creating Corpus`;
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      });
  },
});

export const semanticRetrieverActions = semanticRetrieverSlice.actions;

export default semanticRetrieverSlice;
