import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton, Box, LinearProgress } from "@mui/material";

const TranscriptTable = ({ errorMessage,filteredTranscript, showEmojis, loading, error }) => (
  <TableContainer component={Paper} sx={{ direction: "rtl", position: 'relative' }}>
    <Table>
      <TableHead>

        <TableRow>
          <TableCell align="center">Speaker</TableCell>
          <TableCell align="center">Transcript</TableCell>
          <TableCell align="center" sx={{ width: '250px' }}>Sentiment (Probability)</TableCell>
        </TableRow>
        {loading && (
          <TableRow>
            <TableCell colSpan={3} style={{ padding: 0 }}>
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {loading ? (
          <>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <Skeleton animation="wave" height={40} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <Skeleton animation="wave" height={40} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <Skeleton animation="wave" height={40} />
              </TableCell>
            </TableRow>
          </>
        ) : error ? (
          <TableRow>
            <TableCell align="center" colSpan={3} sx={{ color: 'red' }}>
              {errorMessage}
            </TableCell>
          </TableRow>
        ) : (
          filteredTranscript.map((row, index) => (
            <TableRow
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
              }}
            >
              <TableCell align="center">{row.speaker}</TableCell>
              <TableCell align="right">{row.transcript}</TableCell>
              <TableCell align="center">
                {showEmojis
                  ? `${row.sentiment.emoji} ${row.sentiment.value}%`
                  : `${row.sentiment.label}: ${row.sentiment.value}%`}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TranscriptTable;
