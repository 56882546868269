import { useState, useMemo, useCallback, useEffect } from "react";

const useFilterManager = (files) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({ agent: {}, client: {} });
  const [tabValue, setTabValue] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [isFilteringComplete, setIsFilteringComplete] = useState(true);

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prev) => !prev);
  }, []);

  const resetFilters = useCallback(() => {
    setFilters({ agent: {}, client: {} });
  }, []);


  


  useEffect(() => {
    setIsFilteringComplete(false);
    const filterFiles = async () => {
      const filtered = await new Promise((resolve) => {
        setTimeout(() => {
          const result = files.filter(file => {
            if (tabValue === 1 && file.status !== 'REQUIRED') return false;
            if (tabValue === 2 && file.status !== 'DONE') return false;
            if (tabValue === 3 && Object.keys(file.options_audio_error || {}).length === 0) return false;
            
            if (searchQuery && !file.audio_filename.toLowerCase().includes(searchQuery.toLowerCase())) return false;
            
            if (file.tags && file.tags.length > 0) {
              const agentTag = file.tags.find(tag => tag.speaker_role === 'Agent');
              const clientTag = file.tags.find(tag => tag.speaker_role === 'Client');
              
              for (const [role, roleFilters] of Object.entries(filters)) {
                const tag = role === 'agent' ? agentTag : clientTag;
                if (tag) {
                  for (const [key, values] of Object.entries(roleFilters)) {
                    if (values.length > 0 && !values.includes(tag[key])) return false;
                  }
                }
              }
            } else if (Object.values(filters.agent).some(v => v.length > 0) || Object.values(filters.client).some(v => v.length > 0)) {
              return false;
            }
            
            return true;
          });
          resolve(result);
        }, 300); // Simulate async operation
      });
      setFilteredFiles(filtered);
      setIsFilteringComplete(true);
    };

    filterFiles();
  }, [files, tabValue, searchQuery, filters]);

  return {
    searchQuery,
    filters,
    tabValue,
    sidebarOpen,
    setSearchQuery,
    setFilters,
    handleTabChange,
    toggleSidebar,
    filteredFiles,
    resetFilters,
    isFilteringComplete,
  };
};

export default useFilterManager;