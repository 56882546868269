import { useSelector, useDispatch } from "react-redux";
import { manualTranscribeActions } from "../../Components/Transcription/ManualTranscribe/manualTranscribe-slice";
import  { useCallback } from "react";

export const useTags = () => {
  const dispatch = useDispatch();
  const {
    agentTags,
    clientTags,
    agentErrors,
    clientErrors,
    agentTouched,
    clientTouched,
    isAgentEnabled,
    isClientEnabled,
    hasErrors,
  } = useSelector((state) => state.manualTranscribeData);

  const setAgentTags = (tags) => {
    dispatch(manualTranscribeActions.setAgentTags(tags));
  };

  const setClientTags = (tags) => {
    dispatch(manualTranscribeActions.setClientTags(tags));
  };

  const setAgentErrors = (errors) => {
    dispatch(manualTranscribeActions.setAgentErrors(errors));
  };

  const setClientErrors = (errors) => {
    dispatch(manualTranscribeActions.setClientErrors(errors));
  };

  const setAgentTouched = (touched) => {
    dispatch(manualTranscribeActions.setAgentTouched(touched));
  };

  const setClientTouched = (touched) => {
    dispatch(manualTranscribeActions.setClientTouched(touched));
  };

  const setIsAgentEnabled = (isEnabled) => {
    dispatch(manualTranscribeActions.setIsAgentEnabled(isEnabled));
  };

  const setIsClientEnabled = (isEnabled) => {
    dispatch(manualTranscribeActions.setIsClientEnabled(isEnabled));
  };

  const setHasErrors = (hasErrors) => {
    dispatch(manualTranscribeActions.setHasErrors(hasErrors));
  };


  const setTags = useCallback((tags) => {
    if (tags.length === 0) {
      dispatch(manualTranscribeActions.setAgentTags({
        gender: "",
        age: "",
        accent: "",
        conversation_type: "",
        subject: "",
        emotion: "",
        intention: "",
        intention_success: "",
        names_or_terms: "",
        double_meaning: "",
      }));
      dispatch(manualTranscribeActions.setClientTags({
        gender: "",
        age: "",
        accent: "",
        conversation_type: "",
        subject: "",
        emotion: "",
        intention: "",
        intention_success: "",
        names_or_terms: "",
        double_meaning: "",
      }));
    } else {
      tags.forEach((tag) => {
        if (tag.speaker_role === "Agent") {
          dispatch(manualTranscribeActions.setAgentTags({
            gender: tag.gender || "",
            age: tag.age || "",
            accent: tag.accent || "",
            conversation_type: tag.conversation_type || "",
            subject: tag.subject || "",
            emotion: tag.emotion || "",
            intention: tag.intention || "",
            intention_success: tag.intention_success || "",
            names_or_terms: tag.names_or_terms || "",
            double_meaning: tag.double_meaning || "",
          }));
        } else if (tag.speaker_role === "Client") {
          dispatch(manualTranscribeActions.setClientTags({
            gender: tag.gender || "",
            age: tag.age || "",
            accent: tag.accent || "",
            conversation_type: tag.conversation_type || "",
            subject: tag.subject || "",
            emotion: tag.emotion || "",
            intention: tag.intention || "",
            intention_success: tag.intention_success || "",
            names_or_terms: tag.names_or_terms || "",
            double_meaning: tag.double_meaning || "",
          }));
        }
      });
    }
  }, [dispatch])

  return {
    agentTags,
    clientTags,
    setAgentTags,
    setClientTags,
    agentErrors,
    setAgentErrors,
    clientErrors,
    setClientErrors,
    agentTouched,
    setAgentTouched,
    clientTouched,
    setClientTouched,
    isAgentEnabled,
    setIsAgentEnabled,
    isClientEnabled,
    setIsClientEnabled,
    hasErrors,
    setHasErrors,
    setTags,
  };
};
