import ThreeSectionLayout from "../Components/Layout/ThreeSectionLayout";
import KNNImage from "../Pages/Global/Icons/KNN.webp";
import pyanoteImage from "../Pages/Global/Icons/pyanote.webp";
import DeepClusteringImage from "../Pages/Global/Icons/DeepClustering.webp";
import RecognitionComponent from "../Components/Recognition/RecognitionComponent";

import { Typography } from "@mui/material";

function VoiceRecognition() {
  const leftSection = {
    title: "Features",
    features: [
      { label: "Pyannote Embeddings", value: "feature1", image: pyanoteImage },
      { label: "KNN Clustering", value: "feature2", image: KNNImage },
      {
        label: "Deep Clustering",
        value: "feature3",
        image: DeepClusteringImage,
      },
    ],
    action: (feature) => console.log(`${feature.value} clicked`),
  };

  const middleSection = {
    content: <RecognitionComponent />,
  };

  const rightSection = {
    title: "Voice Recognition",
    content: (
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        sx={{ width: "100%", textAlign: "center" }}
      >
        Voice recognition for transcription converts spoken audio into written
        text using specialized software. It analyzes sound waves, identifies
        phonetic patterns, and translates them into corresponding words and
        phrases.
      </Typography>
    ),
  };

  return (
    <>
      <ThreeSectionLayout
        leftSection={leftSection}
        middleSection={middleSection}
        rightSection={rightSection}
      />
    </>
  );
}

export default VoiceRecognition;
