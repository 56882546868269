import { diffWords } from "diff";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { manualTranscribeActions } from "../../Components/Transcription/ManualTranscribe/manualTranscribe-slice";
import { handleManualTranscribeApiRequest } from "../../Components/Transcription/ManualTranscribe/manualTranscribeApiHandler";

export const useTranscription = () => {
  const dispatch = useDispatch();
  const {
    transcriptionId,
    originalTranscript,
    newTranscript,
    dialogOpen,
    audioFilename,
    audioPath,
    audioSrcState,
    messageText,
    messageType,
    words_transcript_timestamp,
    words_transcript_timestamp_Test,
    originalTranscriptTest,
    audiofileLength,
    agentTags,
    clientTags,
    isAgentEnabled,
    isClientEnabled,

    hoursTranscribed,
  } = useSelector((state) => state.manualTranscribeData);
  const { userName } = useSelector((state) => state.userData);
  const [wordMap, setWordMap] = useState(new Map());

  useEffect(() => {
    if (words_transcript_timestamp && words_transcript_timestamp.words) {
      const initialWordMap = new Map();
      words_transcript_timestamp.words.forEach((wordObj, index) => {
        const key = `${wordObj.word.trim()}-${index}`;
        initialWordMap.set(key, wordObj);
      });
      setWordMap(initialWordMap);
    }
  }, [words_transcript_timestamp]);

  const fetchTranscript = useCallback(() => {
    dispatch(handleManualTranscribeApiRequest({ type: "get_transcript" }));
  }, [dispatch, transcriptionId]);

  const fetchAudioFile = useCallback(
    (transcriptionId) => {
      dispatch(
        handleManualTranscribeApiRequest({
          type: "fetch_audio_file",
          transcript_id: transcriptionId,
        })
      );
    },
    [dispatch, audioPath]
  );

  const handleSave = () => {
    const filterDisabledTags = (tags) => {
      const filteredTags = {};
      for (const [key, value] of Object.entries(tags)) {
        if (value) {
          filteredTags[key] = value;
        }
      }
      return filteredTags;
    };

    const tags = [];
    if (isClientEnabled) {
      tags.push({ speaker_role: "Client", ...filterDisabledTags(clientTags) });
    }
    if (isAgentEnabled) {
      tags.push({ speaker_role: "Agent", ...filterDisabledTags(agentTags) });
    }

    dispatch(
      handleManualTranscribeApiRequest({
        type: "save_transcription",
        transcript: newTranscript,
        original_transcript: originalTranscript,
        filename: audioFilename,
        transcript_id: transcriptionId,
        tags: tags,
        audiofile_length: audiofileLength,
      })
    );
    fetchTranscript();
    dispatch(
      manualTranscribeActions.setAgentTags({
        gender: "",
        age: "",
        accent: "",
        conversation_type: "",
        subject: "",
        emotion: "",
        intention: "",
        intention_success: "",
        names_or_terms: "",
        double_meaning: "",
      })
    );
    dispatch(
      manualTranscribeActions.setClientTags({
        gender: "",
        age: "",
        accent: "",
        conversation_type: "",
        subject: "",
        emotion: "",
        intention: "",
        intention_success: "",
        names_or_terms: "",
        double_meaning: "",
      })
    );
  };

  useEffect(() => {
    if (!hoursTranscribed[userName]) {
      dispatch(
        handleManualTranscribeApiRequest({
          type: "get_hours_transcribed",
          transcriber_username: userName,
        })
      );
    }
  }, [dispatch, hoursTranscribed, userName]);

  const handleUnableToTranscribe = () => {
    dispatch(manualTranscribeActions.setDialogOpen(true));
  };

  const handleClose = () => {
    dispatch(manualTranscribeActions.setDialogOpen(false));
  };

  const handleDialogSubmit = (reasons) => {
    dispatch(
      handleManualTranscribeApiRequest({
        type: "save_error_transcript",
        transcript_id: transcriptionId,
        options: reasons,
      })
    );
    dispatch(manualTranscribeActions.setDialogOpen(false));
    fetchTranscript();
  };

  const handleNewTranscriptChange = (e) => {
    const newText = e.target.value;
    updateTimestamps(newText);
  };

  const updateTimestamps = useCallback(
    (newText) => {
      const newWords = newText.split(/\s+/);
      const updatedTimestamps = [...words_transcript_timestamp.words];
      const newWordMap = new Map();

      newWords.forEach((word, index) => {
        const key = `${word.trim()}-${index}`;

        if (index < updatedTimestamps.length) {
          const updatedTimestamp = {
            ...updatedTimestamps[index],
            word: word.trim(),
          };
          updatedTimestamps[index] = updatedTimestamp;
          newWordMap.set(key, updatedTimestamp);
        } else {
          newWordMap.set(key, null);
        }
      });

      if (newWords.length < updatedTimestamps.length) {
        updatedTimestamps.length = newWords.length;
      }

      dispatch(manualTranscribeActions.setNewTranscript(newText));
      dispatch(
        manualTranscribeActions.setWordsTranscriptTimestamp({
          words: updatedTimestamps,
        })
      );
      setWordMap(newWordMap);
    },
    [dispatch, words_transcript_timestamp]
  );

  const handleWordClick = useCallback(
    (word, index) => {
      const key = `${word.trim()}-${index}`;
      const timestamp = wordMap.get(key);

      if (timestamp && typeof timestamp.start === "number") {
        console.log(
          `Clicked word "${word}" at index ${index}, start time: ${timestamp.start}`
        );
        return timestamp.start;
      } else {
        console.warn(`No timestamp found for word "${word}" at index ${index}`);

        let nearestTimestamp = null;
        let nearestDistance = Infinity;

        wordMap.forEach((value, key) => {
          if (value && typeof value.start === "number") {
            const [, keyIndex] = key.split("-");
            const distance = Math.abs(parseInt(keyIndex) - index);
            if (distance < nearestDistance) {
              nearestDistance = distance;
              nearestTimestamp = value;
            }
          }
        });

        if (nearestTimestamp) {
          console.log(
            `Using nearest timestamp for word "${word}": ${nearestTimestamp.start}`
          );
          return nearestTimestamp.start;
        }

        console.warn(
          `No valid timestamp found for word "${word}" or nearby words`
        );
        return null;
      }
    },
    [wordMap]
  );

  const diffText = useCallback(() => {
    const diff = diffWords(originalTranscript, newTranscript);
    return diff.map((part) => {
      if (part.added) {
        return { value: part.value, added: true };
      } else if (part.removed) {
        return { value: part.value, removed: true };
      } else {
        return { value: part.value };
      }
    });
  }, [originalTranscript, newTranscript]);

  useEffect(() => {
    const agentHasErrors = Object.values(agentTags).some((value) => !value);
    const clientHasErrors = Object.values(clientTags).some((value) => !value);
    dispatch(
      manualTranscribeActions.setHasErrors(agentHasErrors || clientHasErrors)
    );
  }, [agentTags, clientTags, dispatch]);

  return {
    transcriptionId,
    originalTranscript,
    newTranscript,
    dialogOpen,
    audioFilename,
    audioPath,
    audioSrcState,
    messageText,
    messageType,
    words_transcript_timestamp,
    words_transcript_timestamp_Test,
    originalTranscriptTest,
    fetchTranscript,
    fetchAudioFile,
    handleSave,
    handleUnableToTranscribe,
    handleClose,
    handleDialogSubmit,
    handleNewTranscriptChange,
    handleWordClick,
    diffText,
  };
};
