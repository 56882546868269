import ThreeSectionLayout from "../Components/Layout/ThreeSectionLayout";
import ObjectDetectionComponent from "../Components/ObjectDetection/ObjectDetectionComponent";
import { Typography } from "@mui/material";
import DETRImage from "../Pages/Global/Icons/DETR.webp";
import yoloImage from "../Pages/Global/Icons/yolo.webp";
import imageOCRImage from "../Pages/Global/Icons/imageOCR.webp";
import DonutProcessorImage from "../Pages/Global/Icons/DonutProcessor.webp";
import { useDispatch } from "react-redux";
import { objectDetectionActions } from "../store/object-detection/objectDetection-slice";

function ObjectDetection() {
  const dispatch = useDispatch();
  // const { selectedFeature } = useSelector((state) => state.featureData);



  const leftSection = {
    title: "Features",
    features: [
      { label: "YOLO", value: "feature1", image: yoloImage },
      { label: "DETR", value: "feature2", image: DETRImage },
      { label: "EasyOCR", value: "feature3", image: imageOCRImage },
      { label: "Donut Processor", value: "feature4", image: DonutProcessorImage },
    ],
    action: (feature) => {
      console.log(`${feature.label} clicked`);
      // Here you can also dispatch actions or perform other logic based on the clicked feature
      dispatch(objectDetectionActions.resetObjectDetection());
    },
  };

  const middleSection = {
    content: (
      // <Typography variant="h1" gutterBottom>
      //   Voice Transcript Component
      // </Typography>

      <ObjectDetectionComponent />
    ),
  };

  const rightSection = {
    title: "Object Detection",
    content: (
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        sx={{ width: "100%", textAlign: "center" }}
      >
        TBA
      </Typography>
    ),
  };

  return (
    <>
      <ThreeSectionLayout
        leftSection={leftSection}
        middleSection={middleSection}
        rightSection={rightSection}
      />
    </>
  );
}

export default ObjectDetection;
