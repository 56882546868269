import React from "react";
import { Button, Tooltip } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const AudioUploadButton = ({
  disabledUploadButton = false,
  fileInputRef,
  handleAudioFileUpload,
  tooltipMessage,
}) => (
  <Tooltip
    title={disabledUploadButton ? tooltipMessage : ""}
    disableHoverListener={!disabledUploadButton}
  >
    <span>
      <Button
        variant="contained"
        onClick={() => fileInputRef.current.click()}
        disabled={disabledUploadButton} 
        sx={{
          textTransform: "capitalize",
          width: "100%",
          backgroundColor: "white",
          color: "#1976D3",
          padding: "6px 6px",
          border: "1px solid #1976D3",
          "&:hover": {
            backgroundColor: "white",
            borderColor: "#1976D3",
          },
        }}
        startIcon={<UploadFileIcon sx={{ color: "#1976D3" }} />}
      >
        Upload audio file
        <input
          type="file"
          accept=".mp3, .wav, .ogg, .m4a, .flac"
          hidden
          multiple
          ref={fileInputRef}
          onChange={handleAudioFileUpload}
        />
      </Button>
    </span>
  </Tooltip>
);

export default AudioUploadButton;
