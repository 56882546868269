import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AudioPlayer from "../AudioPlayer";
import { ACEActions } from "./ACE-slice";
import AudioUploadButton from "../../Buttons/AudioUploadButton";
import SentimentFilterControls from "../SentimentFilterControls";
import TranscriptTable from "../TranscriptTable";

const theme = createTheme({
  direction: "rtl",
});

const ACEComponent = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const ws = useRef(null);
  const [showAgent, setShowAgent] = useState(true);
  const [showClient, setShowClient] = useState(true);
  const [showPositive, setShowPositive] = useState(true);
  const [showNeutral, setShowNeutral] = useState(true);
  const [showNegative, setShowNegative] = useState(true);
  const [showEmojis, setShowEmojis] = useState(false);

  const {
    agentAudioPath,
    agentAudioSrcState,
    agentAudioFilename,
    clientAudioPath,
    clientAudioSrcState,
    clientAudioFilename,
    callGuid,
    dialogID,
    transcript,
    loading,
    error,
    errorMessage,
  } = useSelector((state) => state.ACEData);

  const handleAudioFileUpload = async (event) => {
    const files = event.target.files;
    const formData = new FormData();
    const newFiles = Array.from(files).map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
      audioFile: file,
    }));
    // setAudioFiles(newFiles);
    dispatch(
      ACEActions.setAudioFile({
        filename: newFiles[0].name,
        url: newFiles[0].url,
      })
    );
    formData.append("file", files[0]);
    // console.log(formData)

    try {
      dispatch(ACEActions.setLoading(true));

      const response = await axios.post(
        // /ace-remote-api/,
        "http://192.168.35.190:7100/upload_mono_file",
        // "https://192.168.35.190:7100/upload_mono_file",

        // "http://localhost:8090/process",
        formData,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response)

      if (response.status === 200) {
        openWebSocket();
        try {
        } catch (error) {
          console.warn("Invalid JSON data in response headers:", error);
        }
      }
    } catch (error) {
      console.error("Error processing file:", error);
      dispatch(ACEActions.setLoading(false));
      dispatch(ACEActions.setError({errorState:true, errorMessage:"Error processing file"}));
    }
  };

  const openWebSocket = () => {
    let temp_link = /ace-api/
    // console.log("This is temp link")
    // console.log(temp_link)
    // console.log(`ws://${temp_link}ws`)
    // ws.current = new WebSocket(`ws://${temp_link}ws`);
    ws.current = new WebSocket("ws://34.165.49.114:8080/ws");

    ws.current.onopen = () => {
      const searchParams = JSON.stringify({
        DialogID: dialogID,
        CallGuid: callGuid,
      });
      // console.log(searchParams)
      // console.log("This is temp link")

      ws.current.send(searchParams);
    };

    ws.current.onmessage = (event) => {
      // console.log("This is temp link")
      try {
        const data = JSON.parse(event.data);
        // console.log("This is temp link")
        if (data.CallTranscript) {
          // console.log(data.CallTranscript)
          // console.log("This is temp link")

          // setTranscript(formatTranscript(data.CallTranscript));
          dispatch(
            ACEActions.setTranscription(formatTranscript(data.CallTranscript))
          );
          dispatch(ACEActions.setLoading(false));

          ws.current.close(); // Close the WebSocket connection after receiving the result
        } else if (data.error) {
          console.error(`Error from server: ${data.error}`);
          dispatch(ACEActions.setLoading(false));
          dispatch(ACEActions.setError({errorState:true, errorMessage:"Error from the server"}));

        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
        dispatch(ACEActions.setLoading(false));
        dispatch(ACEActions.setError({errorState:true, errorMessage:"Error with the response"}));

      }
    };

    ws.current.onclose = () => {
      // console.log("WebSocket connection closed");
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
      // console.log("--->WebSocket error:");
      dispatch(ACEActions.setLoading(false));
      dispatch(ACEActions.setError({errorState:true, errorMessage:"WebSockets error"}));

    };
  };

  const formatTranscript = (callTranscript) => {
    const { conversation_output } = callTranscript;
    return Object.keys(conversation_output)
      .map((key) => {
        const { Agent, Client, sentiment } = conversation_output[key];
        const highestSentiment = getHighestSentiment(sentiment);
        if (Agent) {
          return {
            speaker: "סוכן",
            transcript: Agent,
            sentiment: highestSentiment,
          };
        } else if (Client) {
          return {
            speaker: "לקוח",
            transcript: Client,
            sentiment: highestSentiment,
          };
        }
        return null;
      })
      .filter((item) => item !== null);
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const getHighestSentiment = (sentiment) => {
    const sentimentEntries = Object.entries(sentiment);
    const highestSentiment = sentimentEntries.reduce((acc, curr) => {
      return curr[1] > acc[1] ? curr : acc;
    });
    const label = capitalize(highestSentiment[0]); // Capitalize the label
    const emojiMap = {
      Positive: "😃",
      Neutral: "😐",
      Negative: "😟",
    };
    const emoji = emojiMap[label];
    return {
      label,
      emoji,
      value: (highestSentiment[1] * 100).toFixed(2),
    };
  };

  const filteredTranscript = Array.isArray(transcript)
    ? transcript.filter((item) => {
        if (
          (item.speaker === "סוכן" && !showAgent) ||
          (item.speaker === "לקוח" && !showClient)
        ) {
          return false;
        }
        if (
          (item.sentiment.label === "Positive" && !showPositive) ||
          (item.sentiment.label === "Neutral" && !showNeutral) ||
          (item.sentiment.label === "Negative" && !showNegative)
        ) {
          return false;
        }
        return true;
      })
    : [];

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ width: "100%" }}>
        <Paper
          elevation={0}
          sx={{ padding: 0, paddingTop: 3, margin: 0, width: "100%" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={6}>
              <AudioUploadButton
                fileInputRef={fileInputRef}
                handleAudioFileUpload={handleAudioFileUpload}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            paddingTop={2}
          >
            <Grid item xs={12} sm={12} md={12}>
              <AudioPlayer
                title={"Agent Audio File"}
                filename={agentAudioFilename}
                audio_path={agentAudioPath}
                audioSrcState={agentAudioSrcState}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <AudioPlayer
                title={"Client Audio File"}
                filename={clientAudioFilename}
                audio_path={clientAudioPath}
                audioSrcState={clientAudioSrcState}
              />
            </Grid>
            <SentimentFilterControls
              showAgent={showAgent}
              setShowAgent={setShowAgent}
              showClient={showClient}
              setShowClient={setShowClient}
              showPositive={showPositive}
              setShowPositive={setShowPositive}
              showNeutral={showNeutral}
              setShowNeutral={setShowNeutral}
              showNegative={showNegative}
              setShowNegative={setShowNegative}
              showEmojis={showEmojis}
              setShowEmojis={setShowEmojis}
            />
            <Grid item xs={12} sm={12} md={12}>
              <TranscriptTable
                filteredTranscript={filteredTranscript}
                showEmojis={showEmojis}
                loading={loading}
                error={error}
                errorMessage={errorMessage}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default ACEComponent;
