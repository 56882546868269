import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { alertActions } from "./alertSlice";
import { Stack } from "@mui/material";

const AlertComponent = () => {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alertData);

  useEffect(() => {
    alerts.forEach((alert) => {
      if (alert.open) {
        const timer = setTimeout(() => {
          dispatch(alertActions.hideAlert({ id: alert.id }));
        }, 10000);

        return () => clearTimeout(timer);
      }
    });
  }, [alerts, dispatch]);

  const getBackgroundColor = (type) => {
    switch (type) {
      case "success":
        return "#06D6A0";
      case "warning":
        return "#FFC43D";
      case "error":
        return "#EF476F";
      default:
        return "#2196f3"; 
    }
  };

  const getTextColor = (type) => {
    return type === "error" ? "white" : "black";
    // return type === "error" ? "white" : "black";
  };

  return (
    <Stack spacing={8} sx={{ width: "100%", mt: 0 }}>
      {alerts.map((alert, index) => (
        <Snackbar
          key={alert.id}
          open={true}
          autoHideDuration={10000}
          onClose={() => dispatch(alertActions.hideAlert({ id: alert.id }))}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ mb: `${index * 80}px` }} 
        >
          <Alert
            onClose={() => dispatch(alertActions.hideAlert({ id: alert.id }))}
            severity={alert.type}
            sx={{
              width: "30rem", 
              backgroundColor: getBackgroundColor(alert.type),
              color: getTextColor(alert.type),
              fontSize: "1.2rem", 
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              '& .MuiAlert-icon': {
                color: getTextColor(alert.type),
              },
            }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      ))}
    </Stack>
  );
};

export default AlertComponent;
