import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationThumbnails from "../../Pages/Global/Layout/NavigationThumbnails";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ResponsiveNavigation = () => {
  const location = useLocation();
  const { thumbnails } = useSelector((state) => state.featureData);

  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const enhancedThumbnails = thumbnails.map((thumbnail) => ({
    ...thumbnail,
    highlight: location.pathname === thumbnail.navigateTo,
  }));

  const renderNavigationThumbnails = () =>
    enhancedThumbnails.map((item, index) => (
      <NavigationThumbnails key={index} {...item} />
    ));

  return (
    <Box  sx={{ display: { xs: "block", sm: "none", lg: "none" } }}>
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // edge="start"
            onClick={toggleDrawer(true)}
            // sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor={"right"} open={isOpen} onClose={toggleDrawer(false)}>
            <Box
              // sx={{ width: 380 }}
              padding="0rem 2rem 0rem 2rem"
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              {renderNavigationThumbnails()}
            </Box>
          </Drawer>
        </>
      ) : (
        <Box
          display="flex"
          padding="0rem 2rem 0rem 0rem"
          flexDirection="row"
          justifyContent="center"
          flexWrap="wrap"
        >
          {renderNavigationThumbnails()}
        </Box>
      )}
    </Box>
  );
};

export default ResponsiveNavigation;
