import { createSlice } from "@reduxjs/toolkit";



const alertSlice = createSlice({
  name: "alertHandler",
  initialState: [],
  reducers: {

    showAlert: (state, action) => {
      state.push({
        id: new Date().getTime(),
        type: action.payload.type,
        message: action.payload.message,
      });
    },
    hideAlert: (state, action) => {
      return state.filter(alert => alert.id !== action.payload.id);
    },
  },


});

export const alertActions = alertSlice.actions;

export default alertSlice;
