import ThreeSectionLayout from "../Components/Layout/ThreeSectionLayout";
// import ChatComponent from "../Components/OrgChat/ChatComponent";
import SignLanguageComponent from "../Components/SignLanguage/SignLanguageComponent"
import { Typography } from "@mui/material";

import langChain from "../Pages/Global/Icons/langChain.webp";
// import RAGLangChain from "../Pages/Global/Icons/RAGLangChain.webp";
// import LLAMA from "../Pages/Global/Icons/LLAMA.webp";
// import GoogDocSearch from "../Pages/Global/Icons/GoogDocSearch.webp";
// import GoogSimRet from "../Pages/Global/Icons/GoogSimRet.webp";

function SignLanguage() {


  const leftSection = {
    title: "Features",
    features: [
      {
        label: "TBA",
        image: langChain,
        value: "feature1",
        description: "RAG langchain with OpenAI",
      },

    ],
    action: (feature) => console.log(`${feature.value} clicked`),
  };

  const middleSection = {
    content: (
        <SignLanguageComponent />
    ),
  };

  const rightSection = {
    title: "Sign Language Translation",
    content: (
      <Typography
        variant="h5"
        gutterBottom
        component="div"
        sx={{ width: "100%", textAlign: "center" }}
      >
        TBA
      </Typography>
    ),
  };

  return (
    <>
      <ThreeSectionLayout
        leftSection={leftSection}
        middleSection={middleSection}
        rightSection={rightSection}
      />
    </>
  );
}

export default SignLanguage;
