import { createSlice } from "@reduxjs/toolkit";
import { handleACEApiRequest } from "./ACEApiHandler";

const ACESlice = createSlice({
  name: "ACE",
  initialState: {

    agentAudioPath: "",
    agentAudioSrcState: "",
    agentAudioFilename: "",

    clientAudioPath: "",
    clientAudioSrcState: "",
    clientAudioFilename: "",

    // dialogID: "11B037128A1134AD0B3958D73318E5FFF",
    // callGuid: "16809762",

    loading: false,
    error: false,
    errorMessage: "",

    dialogID: "",
    callGuid: "",

    transcript: [],
  },
  reducers: {

    setAudioFile: (state, action) => {
      const filename = action.payload.filename;
      const lastLetter = filename.split('.')[0].slice(-1);

      const parts = filename.split('^');
      const callGuid = parts[0];
      const dialogID = parts[1];
    
      // Save callGuid and dialogID in state
      state.callGuid = callGuid;
      state.dialogID = dialogID;
      // console.log(lastLetter)
      // console.log(filename.split('.')[0])


      if (lastLetter === "A") {
        state.agentAudioFilename = filename;
        state.agentAudioPath = action.payload.url;
        state.agentAudioSrcState = "success";
      } else if (lastLetter === "C") {
        state.clientAudioFilename = filename;
        state.clientAudioPath = action.payload.url;
        state.clientAudioSrcState = "success";
      }

    },
    setTranscription: (state, action) => {
      state.transcript = action.payload
      // console.log(state.transcript)
    },
    setLoading: (state, action) => {
      state.loading = action.payload
      // console.log(state.transcript)
    },
    setError: (state, action) => {
      const {errorState, errorMessage} = action.payload
      state.error = errorState
      state.errorMessage = errorMessage
      // console.log(state.transcript)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleACEApiRequest.pending, (state, action) => {
        const { type } = action.meta.arg;

        switch (type) {


          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleACEApiRequest.fulfilled, (state, action) => {
        const { type, data } = action.payload;
        switch (type) {



          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleACEApiRequest.rejected, (state, action) => {
        const { type } = action.meta.arg;

        switch (type) {

          case "get_transcript":
            console.log("data");

            break;



          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      });
  },
});

export const ACEActions = ACESlice.actions;

export default ACESlice;
