import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
} from "@mui/material";

const ReasonDialog = ({ open, onClose, onSubmit }) => {
  const [reasons, setReasons] = useState({
    notUnderstandable: false,
    notHebrew: false,
    backgroundNoise: false,
    distorted: false,
    other: false,
  });
  const [otherText, setOtherText] = useState("");

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setReasons((prev) => ({
      ...prev,
      [name]: checked,
    }));
    if (name === "other" && !checked) {
      setOtherText("");
    }
  };

  const handleSubmit = () => {
    onSubmit({ ...reasons, otherText });
    onClose();
  };

  const isSubmitDisabled = () => {
    const atLeastOneReasonChecked = Object.values(reasons).some((value) => value);
    const otherReasonValid = !reasons.other || (reasons.other && otherText.trim() !== "");
    return !atLeastOneReasonChecked || !otherReasonValid;
  };

  return (
    <Dialog
    fullWidth={false}
    // maxWidth={'sm'}
      open={open}
      onClose={onClose}
      sx={{
        direction: "rtl",
        // margin: "1rem ",
        // width:1500,

      }}
    >
      <DialogTitle>מה הסיבה שלא הצלחת?</DialogTitle>
      <DialogContent>
        <FormGroup sx={{ direction: "rtl" }}>
          <FormControlLabel
            control={<Checkbox name="notUnderstandable" checked={reasons.notUnderstandable} onChange={handleChange} />}
            label="לא מובן"
          />
          <FormControlLabel
            control={<Checkbox name="notHebrew" checked={reasons.notHebrew} onChange={handleChange} />}
            label="לא עברית"
          />
          <FormControlLabel
            control={<Checkbox name="backgroundNoise" checked={reasons.backgroundNoise} onChange={handleChange} />}
            label="רעש רקע דומיננטי"
          />
          <FormControlLabel
            control={<Checkbox name="distorted" checked={reasons.distorted} onChange={handleChange} />}
            label="מעוות"
          />
          <FormControlLabel
            control={<Checkbox name="other" checked={reasons.other} onChange={handleChange} />}
            label="Other"
          />
          {reasons.other && (
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={otherText}
              onChange={(e) => setOtherText(e.target.value)}
              placeholder="Please specify"
              sx={{ mt: 2 }}
            />
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          sx={{
            backgroundColor: "#fff",
            borderColor: "#007BFF",
            color: "#007BFF",
            marginLeft: "1rem",
            "&:hover": {
              backgroundColor: "#e6f7ff",
              borderColor: "#0056b3",
            },
          }}
        >
          בטל 
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isSubmitDisabled()}
        >
          הגשה
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReasonDialog;
