import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Box,
  LinearProgress,
  Alert,
  TextField
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AudioPlayer from "../AudioPlayer";
import TranscriptField from "../TranscriptField";
import ActionButtons from "../ActionButtons";
import ReasonDialog from "../ReasonDialog";
import AudioLoading from "../../AudioLoading";
import { transcriptionActions } from "./transcription-slice";
import { handleTranscriptionApiRequest } from "./transcriptionApiHandler";

const theme = createTheme({
  direction: "rtl",
});

const TranscriptionComponent = () => {
  const dispatch = useDispatch();
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [audioFiles, setAudioFiles] = useState([]);
  const fileInputRef = useRef();

  const {
    audioTranscript,
    voiceTranscript,

    audioLoading,
    voiceRecordingLoading,
    audioError,
    voiceRecordingError,
    audioErrorMessage,
    voiceRecordingErrorMessage,

    audioFilename,
    audioPath,
    audioSrcState,
    microphoneSelected,
    isRecording,
    audioFileSelected,
  } = useSelector((state) => state.transcriptionData);
  const { link } = useSelector((state) => state.featureData);

  const handleAudioFileUpload = async (event) => {
    stopRecording();
    dispatch(transcriptionActions.setAudioFileSelected(true));
    dispatch(transcriptionActions.setMicrophoneSelected(false));
    dispatch(transcriptionActions.setAudioLoading(true));

    const files = event.target.files;
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("transcription_type", "ivrit_ai");

    const newFiles = Array.from(files).map((file) => ({
      name: file.name,
      url: URL.createObjectURL(file),
      audioFile: file,
    }));
    setAudioFiles(newFiles);
    dispatch(
      transcriptionActions.setAudioFile({
        filename: newFiles[0].name,
        url: newFiles[0].url,
      })
    );

    try {
      // console.log(formData);
      const response = await axios.post(
        // "http://localhost:7117/transcribe",
        "http://192.168.35.190:7117/transcribe",
        formData,
        {
          responseType: "blob",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "json", // Ensure response type is blob
        }
      );

      if (response.status === 200) {
        dispatch(
          transcriptionActions.setAudioTranscript(response.data.transcription)
        );
        dispatch(transcriptionActions.setAudioLoading(false));
      }
    } catch (error) {
      console.error("Error processing file:", error);
      dispatch(transcriptionActions.setAudioError(true));
      dispatch(
        transcriptionActions.setAudioErrorMessage("Error processing file")
      );
      dispatch(transcriptionActions.setAudioLoading(false));
    }
  };

  const startRecording = async () => {
    dispatch(transcriptionActions.setMicrophoneSelected(true));
    dispatch(transcriptionActions.setIsRecording(true));
    // dispatch(transcriptionActions.setAudioFileSelected(false));
    dispatch(transcriptionActions.setVoiceRecordingLoading(true));

    audioChunksRef.current = [];

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorderRef.current.onstop = async () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      const formData = new FormData();
      formData.append("file", audioBlob, "recording.wav");

      try {
        const response = await fetch("/api/upload_audio", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          // console.log("Audio uploaded successfully");
          dispatch(transcriptionActions.setVoiceRecordingLoading(false));
        } else {
          console.error("Failed to upload audio");
          dispatch(transcriptionActions.setVoiceRecordingError(true));
          dispatch(
            transcriptionActions.setVoiceRecordingErrorMessage(
              "Failed to upload audio"
            )
          );
          dispatch(transcriptionActions.setVoiceRecordingLoading(false));
        }
      } catch (error) {
        console.error("Error uploading audio", error);
        dispatch(transcriptionActions.setVoiceRecordingError(true));
        dispatch(
          transcriptionActions.setVoiceRecordingErrorMessage(
            "Error uploading audio"
          )
        );
        dispatch(transcriptionActions.setVoiceRecordingLoading(false));
      }
    };

    mediaRecorderRef.current.start();
  };

  const stopRecording = () => {
    if (isRecording) {
      dispatch(transcriptionActions.setIsRecording(false));
      mediaRecorderRef.current.stop();
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ width: "100%" }}>
        <Paper
          elevation={0}
          sx={{ padding: 0, paddingTop: 3, margin: 0, width: "100%" }}
        >
          <Typography variant="h4" gutterBottom>
            Transcription Component
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4} md={6}>
              <Button
                variant="contained"
                color={isRecording ? "secondary" : "primary"}
                startIcon={isRecording ? <StopIcon /> : <MicIcon />}
                onClick={isRecording ? stopRecording : startRecording}
                sx={{ textTransform: "capitalize", width: "100%" }}
              >
                {isRecording ? "Stop recording" : "Open microphone"}
              </Button>
              {voiceRecordingLoading && <LinearProgress />}
            </Grid>
            <Grid item xs={6} sm={4} md={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<UploadFileIcon />}
                onClick={() => fileInputRef.current.click()}
                sx={{ textTransform: "capitalize", width: "100%" }}
              >
                {audioFileSelected ? "Audio Transcription" : "Upload audio file"}
                <input
                  type="file"
                  hidden
                  ref={fileInputRef}
                  onChange={(event) => handleAudioFileUpload(event)}
                />
              </Button>
              {audioLoading && <LinearProgress />}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            paddingTop={4}
          >
            <Grid item xs={12} sm={12} md={12}>
              {microphoneSelected && isRecording && <AudioLoading />}
              {audioFileSelected && !microphoneSelected && (
                <AudioPlayer
                  title={"Audio File"}
                  filename={audioFilename}
                  audio_path={audioPath}
                  audioSrcState={audioSrcState}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                value={
                  audioLoading || voiceRecordingLoading
                    ? "Loading..."
                    : audioError || voiceRecordingError
                    ? audioErrorMessage || voiceRecordingErrorMessage
                    : audioTranscript || voiceTranscript || ""
                }
                placeholder="Transcription will appear here..."
                sx={{ direction: "rtl" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {audioFileSelected && !microphoneSelected && (
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<UploadFileIcon />}
                  onClick={() => fileInputRef.current.click()}
                  sx={{ textTransform: "capitalize", width: "50%", marginTop: 2 }}
                >
                  Upload new audio file
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Container>
    </ThemeProvider>
  );

};

export default TranscriptionComponent;



