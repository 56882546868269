import { createSlice } from "@reduxjs/toolkit";
import { handleLlamaIndexApiRequest } from "./llamaIndexApiHandler";

const llamaIndexSlice = createSlice({
  name: "llamaIndexHandler",
  initialState: {
    botIsTyping: false,
    chatHistory: {}, 
    uploadedDocumentsFiles: {},
    documentLoadStatus: "idle",
    cloudUploadStatus: "idle",
    availableVectorDatastores: [{ db_name: "", files: [], created: "" }],
    datastoreName: "",
    selectedVectorDB: { vectorDBName: "", vectorDBFiles: [], created: "" },
    loadingAvailableDatastores: false,
    deleteSingleFileIconStatus: "idle",
    datastoreOption: "create",
    deleteIconStatus: "idle",
    deleteFileIconStatuses: {},
    selectedMenuItem: "",
    dbToUse: "",
    files: {},
  },
  reducers: {
    setDatastoreName: (state, action) => {
      const { newName } = action.payload;
      state.datastoreName = newName;
    },
    setUploadedDocumentsFiles: (state, action) => {
      const { files } = action.payload;
      state.uploadedDocumentsFiles = files;
    },
    updateSelectedVectorDB: (state, action) => {
      const { selectedDB } = action.payload;
      state.selectedVectorDB = selectedDB;
    },
    setDatastoreOption: (state, action) => {
      const datastoreOption = action.payload;
      state.datastoreOption = datastoreOption;
    },
    setDBToUse: (state, action) => {
      state.dbToUse = action.payload;
    },
    setSelectedMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload;
      // console.log(state.selectedMenuItem);
    },
    updateChatHistory: (state, action) => {
      const { dbToUse, text, sender } = action.payload;
      if (!state.chatHistory[dbToUse]) {
        state.chatHistory[dbToUse] = [];
      }
      state.chatHistory[dbToUse].push({ text, sender });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleLlamaIndexApiRequest.pending, (state, action) => {
        const { type, filename } = action.meta.arg;
        switch (type) {
          case "uploadFiles":
            state.documentLoadStatus = "loading";
            state.deleteIconStatus = "idle";
            break;
          case "sendMessage":
            state.botIsTyping = true;
            break;
          case "deleteSingleFile":
            state.uploadedDocumentsFiles[filename] = "deleting";
            break;
          case "deleteChatHistory":
            state.botIsTyping = true;
            break;
          case "getHistory":
            state.botIsTyping = false;
            state.chatHistory[state.dbToUse] = [
              { text: "שלום, איך אוכל לעזור?", sender: "bot" },
            ];
            break;
          case "uploadVectorDB":
            state.cloudUploadStatus = "loading";
            break;
          case "getAvailableDatastores":
            state.loadingAvailableDatastores = true;
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleLlamaIndexApiRequest.fulfilled, (state, action) => {
        const { type, data } = action.payload;
        switch (type) {
          case "uploadFiles":
            state.uploadStatus = "success";
            state.documentLoadStatus = "success";
            state.files = data;
            state.uploadedDocumentsFiles = data;
            break;
          case "sendMessage":
            state.botIsTyping = false;
            let sender;
            // console.log(data);
            data.sender === "Chatbot" ? (sender = "bot") : (sender = "error");
            if (!state.chatHistory[state.dbToUse]) {
              state.chatHistory[state.dbToUse] = [];
            }
            state.chatHistory[state.dbToUse].push({ text: data.text, sender });
            break;
          case "getHistory":
            state.botIsTyping = false;
            state.chatHistory[state.dbToUse] = data.map((message) => ({
              text: message.text,
              sender: message.sender,
            }));
            break;
          case "deleteSingleFile":
            state.files = data;
            state.uploadedDocumentsFiles = data;
            break;
          case "deleteChatHistory":
            state.chatHistory[state.dbToUse] = [
              { text: "שלום, איך אוכל לעזור?", sender: "bot" },
            ];
            state.botIsTyping = false;
            break;
          case "cleanup":
            state.chatHistory[state.dbToUse] = [
              { text: "שלום, איך אוכל לעזור?", sender: "bot" },
            ];
            state.botIsTyping = false;
            state.deleteIconStatus = "idle";
            state.deleteSingleFileIconStatus = "idle";
            state.files = [];
            state.uploadStatus = "idle";
            break;
          case "getAvailableDatastores":
            state.availableVectorDatastores = data;
            // console.log(state.availableVectorDatastores)
            state.loadingAvailableDatastores = false;
            break;
          case "uploadVectorDB":
            state.cloudUploadStatus = "success";
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      })
      .addCase(handleLlamaIndexApiRequest.rejected, (state, action) => {
        const { type } = action.meta.arg;
        switch (type) {
          case "sendMessage":
            state.botIsTyping = false;
            break;
          case "deleteSingleFile":
            const { message } = action.arg;
            state.deleteFileIconStatuses[message] = "error";
            break;
          case "uploadFiles":
            state.documentLoadStatus = "error";
            break;
          case "deleteChatHistory":
            state.botIsTyping = false;
            break;
          case "uploadVectorDB":
            state.cloudUploadStatus = "error";
            break;
          case "getHistory":
            state.botIsTyping = false;
            break;
          default:
            console.warn(`Unhandled action type: ${type}`);
        }
      });
  },
});

export const llamaIndexActions = llamaIndexSlice.actions;

export default llamaIndexSlice;
