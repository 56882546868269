export const validTags = {
  agent: {
    gender: ["Male", "Female", "Other"],
    age: "free-text",
    accent: [
      "Israeli",
      "Russian",
      "Arabic",
      "Yemeni",
      "Ethiopian",
      "French",
      "American",
      "British",
    ],
    conversation_type: [
      "Business",
      "Customer Service",
      "Emergency",
      "Treatment",
      "Interview",
      "Lecture",
      "Meeting",
      "Radio",
      "Podcast",
      "Protocol",
      "Speech",
    ],
    subject: "free-text",
    emotion: ["Happy", "Sad", "Angry", "Neutral"],
    intention: ["Sales", "Marketing", "Accusation"],
    intention_success: ["Yes", "No", "NA"],
    names_or_terms: "free-text",
    double_meaning: "free-text",
  },
  client: {
    gender: ["Male", "Female", "Other"],
    age: "free-text",
    accent: [
      "Israeli",
      "Russian",
      "Arabic",
      "Yemeni",
      "Ethiopian",
      "French",
      "American",
      "British",
    ],
    conversation_type: [
      "Business",
      "Customer Service",
      "Emergency",
      "Treatment",
      "Interview",
      "Lecture",
      "Meeting",
      "Radio",
      "Podcast",
      "Protocol",
      "Speech",
    ],
    subject: "free-text",
    emotion: ["Happy", "Sad", "Angry", "Neutral"],
    intention: ["Sales", "Marketing", "Accusation"],
    intention_success: ["Yes", "No", "NA"],
    names_or_terms: "free-text",
    double_meaning: "free-text",
  },
};

