import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import chatImage from "../Pages/Global/Icons/chat.webp";
import recognitionImage from "../Pages/Global/Icons/recognition.webp";
import reductionImage from "../Pages/Global/Icons/reduction.webp";
import signLanguageImage from "../Pages/Global/Icons/signlanguage.webp";
import objectDetectionImage from "../Pages/Global/Icons/objectDetection.webp";
import ManualTranscribeImage from "../Pages/Global/Icons/Transcription.webp";

const featureSlice = createSlice({
  name: "featureHandler",
  initialState: {
    link: "",
    controllerLink:
      process.env.REACT_APP_CONTROLLER_API ||
      "/controller-api-ws/",
      // `wss://innovation-lab.it.kaleidoo-dev.com/controller-api/ws`,
    // controllerLink: "/controller-api/",
    controllerLinkWS: "/controller-api-ws/",
    // controllerLink:
    //   process.env.REACT_APP_LOCALHOST_CONTROLLER_API || "/controller-api/",
    selectedFeature: "",
    featureDescription: "",
    userUniqueId: uuidv4(),

    thumbnails: [
      {
        title: "Organizational Chatbot",
        imageSrc: chatImage,
        navigateTo: "/orgChat",
      },
      {
        title: "Transcription",
        imageSrc: ManualTranscribeImage,
        navigateTo: "/transcription",
      },
      {
        title: "Noise Reduction",
        imageSrc: reductionImage,
        navigateTo: "/noiseReduction",
      },
      {
        title: "Voice Recognition",
        imageSrc: recognitionImage,
        navigateTo: "/voiceRecognition",
      },
      {
        title: "Sign Language Translation",
        imageSrc: signLanguageImage,
        navigateTo: "/signLanguage",
      },
      {
        title: "Object Detection",
        imageSrc: objectDetectionImage,
        navigateTo: "/objectDetection",
      },

    ],
  },

  reducers: {


    setSelectedFeature: (state, action) => {
      const data = action.payload
      state.selectedFeature = data
    },
    setFeatureLink: (state, action) => {
      const linkMap = {
        "RAG langchain with OpenAI":
          process.env.REACT_APP_LOCALHOST_API || "/openai-langchain-api/",
        "RAG langchain with Google":
          process.env.REACT_APP_LOCALHOST_OTHER_API || "/google-langchain-api/",
        "llama Index":
          process.env.REACT_APP_LOCALHOST_LLAMA_API || "/llama-index-api/",
        "Google Documents Search":
          process.env.REACT_APP_LOCALHOST_API || "/-api/",
        "Google Similarity Retrieval":
          process.env.REACT_APP_LOCALHOST_SEMANTIC_API ||
          "/semantic-retriever-api/",

        "Auto Encoder":
          process.env.REACT_APP_LOCALHOST_API || "/auto-encoder-api/",
        "Spectral Gating":
          process.env.REACT_APP_LOCALHOST_API || "/spectral-gating-api/",

        "Pyannote Embeddings":
          process.env.REACT_APP_SPECTRAL_GATING_API ||
          "/pyannote-embedding-api/process",
        "KNN Clustering": process.env.REACT_APP_LOCALHOST_API || "/-api/",
        "Deep Clustering": process.env.REACT_APP_LOCALHOST_API || "/-api/",

        YOLO: process.env.REACT_APP_LOCALHOST_API || "/yolo-api/",
        DETR: process.env.REACT_APP_LOCALHOST_API || "/detr-api/",
        EasyOCR: process.env.REACT_APP_LOCALHOST_API || "/easy-ocr-api/",
        "Donut Processor":
          process.env.REACT_APP_LOCALHOST_API || "/donut-processor-api/",
        "Manual Transcribe":
          process.env.REACT_APP_LOCALHOST_MANUAL_TRANSCRIBE_API ||
          "/manual-transcribe-api/",
        "File Management":
          process.env.REACT_APP_LOCALHOST_MANUAL_TRANSCRIBE_API ||
          "/manual-transcribe-api/",

        "multichannel-separation":
          process.env.REACT_APP_MULTICHANNEL_SEPARATION_API ||
          "/multichannel-separation-api/",
        // "": process.env.REACT_APP_LOCALHOST_API || "/-api/",
      };

      const descriptionMap = {
        TBA: " TBA",
        ACE: " ACE",
        Transcription: " TBA",
        "File Management":
          " A dedicated page designed to manage manual transcription files and their associated transcriptions, ensuring accurate handling and organization. Additionally, this page will provide tools for monitoring and tracking the progress of transcription tasks.",
        "Manual Transcribe": ` On the transcription screen, you are presented with a sound segment up to 29 seconds. 
        In addition, an initial transcription that we made with the help of the model is shown, and a window where the transcription can be corrected. 
        After checking or correcting the transcript and if it is correct, click Save Transcript (the blue button). 
        If the sound is not correct, click on I Couldn't Transcribe (red button) and enter the reason why the sound is not correct`,
        "RAG langchain with OpenAI":
          "RAG (Retrieval-Augmented Generation) combined with OpenAI's language models refers to an approach where a language model like GPT is enhanced with the ability to retrieve and use external knowledge (from a database or the internet) to generate more informed and accurate responses. It's a hybrid model that leverages both generative capabilities and external knowledge retrieval.",
        "RAG langchain with Google":
          "Similar to RAG with OpenAI, this approach utilizes Google's technologies for the retrieval component. It may involve leveraging Google's search engine or datasets to retrieve relevant information that a language model can use to augment its response generation, aiming for higher accuracy and relevance in generated content",
        "llama Index":
          'Indexing models like "Llama Index" could refer to systems designed for efficiently searching and retrieving information from large datasets, possibly using language models for understanding and organizing content.',
        "Google Documents Search":
          "This refers to the capability to search within documents using Google's technology, which may involve natural language processing and understanding to return relevant results. It's used in various Google products, including Google Drive, to help users find specific information within their documents.",
        "Google Similarity Retrieval":
          "A method or system developed by Google that focuses on retrieving items or content that are similar to a given input. This could involve semantic understanding and similarity measurement techniques to find content related to the query across various Google services or datasets.",
        "Auto Encoder":
          "An Auto Encoder is a type of neural network used for unsupervised learning. It's designed to learn efficient representations (encodings) of the input data, typically for dimensionality reduction or feature learning purposes. AutoEncoders have two main components: an encoder that compresses the input and a decoder that reconstructs the input from the compressed representation.",
        "Spectral Gating":
          "Spectral gating is a signal processing technique often used in audio processing to reduce noise. It works by selectively gating (attenuating or silencing) parts of the audio spectrum that are below a certain threshold, effectively reducing background noise while preserving the main audio signal.",
        "Pyannote Embeddings":
          "Pyannote is a Python toolkit for speaker diarization, which is the process of partitioning an audio stream into homogeneous segments according to the speaker identity. Pyannote embeddings refer to the speaker embeddings generated by Pyannote's models, which capture the characteristics of a speaker's voice in a compact form, useful for identifying or verifying speakers.",
        "KNN Clustering":
          "K-Nearest Neighbors (KNN) is a simple, versatile algorithm used in both classification and regression but can be adapted for clustering. In clustering, KNN might be used to assign data points to the cluster of their nearest neighbors based on some distance metric, though it's more traditionally known for its use in supervised learning.",
        "Deep Clustering":
          "Deep clustering involves using deep learning models to perform clustering tasks, where the goal is to group data points into clusters such that points in the same cluster are more similar to each other than to those in other clusters. Deep clustering leverages the representation learning capabilities of deep neural networks to discover complex patterns in data that traditional clustering algorithms might miss.",
        YOLO: "Designed for object detection tasks within the realm of computer vision. Distinguished by its remarkable speed and the ability to process images in a single evaluation, YOLO excels in real-time applications. It uniquely predicts bounding boxes and class probabilities directly from full images in one go, significantly streamlining the detection process. This approach not only enhances performance efficiency but also simplifies the detection pipeline, making YOLO a preferred choice for applications requiring quick and accurate object detection.",
        DETR: "The DETR model is an encoder-decoder transformer with a convolutional backbone. Two heads are added on top of the decoder outputs in order to perform object detection: a linear layer for the class labels and a MLP (multi-layer perceptron) for the bounding boxes. The model uses so-called object queries to detect objects in an image",
        EasyOCR:
          "EasyOCR is built on deep learning frameworks, primarily utilizing Convolutional Neural Networks (CNNs) for image processing and sequence-to-sequence models for understanding the context and intricacies of the text. This combination allows it to handle a wide range of text recognition tasks with impressive accuracy.",
        "Donut Processor":
          "The Donut model was proposed in OCR-free Document Understanding Transformer. Donut consists of an image Transformer encoder and an autoregressive text Transformer decoder to perform document understanding tasks such as document image classification, form understanding and visual question answering.",
        // Add more mappings as necessary based on the labels
      };
      // Use the label to determine the new link
      state.selectedFeature = action.payload;
      state.link = linkMap[action.payload] || state.link; // Fallback to current link if label not found
      state.featureDescription =
        descriptionMap[action.payload] || state.featureDescription; // Fallback to current link if label not found
    },
  },
});

export const featureActions = featureSlice.actions;

export default featureSlice;
