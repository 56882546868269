import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const FileUploader = ({
  handleUploadFile,
  inputFileRef,
  documentLoadStatus,
  handleFileChange,
  selectedFeature,
  disableCreateNewCorpora
}) => {
  return (
    <div>
      <input
        type="file"
        multiple
        style={{ display: "none" }}
        ref={inputFileRef}
        onChange={handleFileChange} // Ensure handleFileChange is passed as a prop if it's used here
      />
      <Tooltip title="Upload files" enterDelay={500} leaveDelay={200}>
        <IconButton
          onClick={handleUploadFile}
          disabled={disableCreateNewCorpora}
          style={{
            color:
              documentLoadStatus === "success"
                ? "#06A77D"
                : documentLoadStatus === "error"
                ? "red"
                : "inherit",
          }}
        
          >
          <UploadFileIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default FileUploader;
