import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const handleManualTranscribeApiRequest = createAsyncThunk(
  "api/handleManualTranscribeApiRequest",
  async (
    {
      type,
      transcript_id = "",
      filename = "",
      transcript = "",
      original_transcript = "",
      user_name_to_fetch = "",
      audiofile_length = 0,
      options = {},
      tags = {},
    },
    thunkAPI
  ) => {
    const link = thunkAPI.getState().featureData.link;
    const userName = thunkAPI.getState().userData.userName;

    let url;
    let config = {};
    let payload;
    let METHOD = "post";

    switch (type) {
      case "fetch_transcripts":
        METHOD = "get";
        url = `${link}transcript_data`;
        config = {
          params: { transcript_id: transcript_id },
          headers: { "Content-Type": "application/json" },
        };
        break;
      case "get_files_status":
        METHOD = "get";
        url = `${link}mongo_documents`;
        config.headers = { "Content-Type": "application/json" };
        break;
      case "get_average_wer":
        METHOD = "get";
        url = `${link}average_wer`;
        config.headers = { "Content-Type": "application/json" };
        break;
      case "update_available_status":
        METHOD = "put";
        url = `${link}update_status`;
        payload = {
          transcript_id: transcript_id,
        };
        config.headers = { "Content-Type": "application/json" };
        break;
      case "get_hours_transcribed":
        METHOD = "get";
        url = `${link}sum_audio_length`;

        if (user_name_to_fetch === "") {
          user_name_to_fetch = userName;
        }
        config = {
          params: { transcriber_username: user_name_to_fetch },
          headers: { "Content-Type": "application/json" },
        };
        break;
      case "get_transcript":
        url = `${link}get_transcript`;
        config.headers = { "Content-Type": "application/json" };
        break;
      case "fetch_audio_file":
        METHOD = "get";
        url = `${link}audio/${transcript_id}`;
        config.headers = { "Content-Type": "multipart/form-data" };
        break;
      case "check_transcript_server_status":
        METHOD = "get";
        url = `${link}check_server_status`;
        config.headers = { "Content-Type": "multipart/form-data" };
        break;
      case "save_transcription":
        METHOD = "put";
        payload = {
          transcript: transcript,
          original_transcript: original_transcript,
          filename: filename,
          transcript_id: transcript_id,
          tags: tags,
          transcriber_username: userName,
          audiofile_length: audiofile_length,
        };
        url = `${link}save_transcript`;
        config.headers = { "Content-Type": "application/json" };
        break;
      case "save_error_transcript":
        METHOD = "put";

        payload = {
          transcript_id: transcript_id,
          options: options,
        };
        url = `${link}save_error_transcript`;
        config.headers = { "Content-Type": "application/json" };
        break;

      default:
        throw new Error("Unsupported API request type");
    }

    const response = await axios({
      method: METHOD,
      url: url,
      data: payload,
      ...config,
    });
    return { type, data: response.data };
  }
);
