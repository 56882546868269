import React from "react";
import { Grid } from "@mui/material";
import AudioPlayer from "./AudioPlayer";
import TranscriptFieldKareoke from "./TranscriptFieldKareoke";
import TranscriptField from "./TranscriptField";

const TranscriptionControls = ({
  audioFilename,
  audioSrcState,
  handleTimeUpdate,
  seekTime,
  originalTranscript,
  currentTime,
  handleWordClick,
  wordsTranscriptTimestamp,
  diff,
  newTranscript,
  handleNewTranscriptChange,
  handleExtractAudioDuration,
}) => {
  const handleWordClickWrapper = (word, index) => {
    handleWordClick(word, index);
  };

  return (
    <>
      <AudioPlayer
        title={"Audio File"}
        filename={audioFilename}
        audioSrcState={audioSrcState}
        onTimeUpdate={handleTimeUpdate}
        seekTo={seekTime}
        handleExtractAudioDuration={handleExtractAudioDuration}
      />
      <Grid container spacing={3} mt={0}>
        <TranscriptFieldKareoke
          label="תמלול ראשוני"
          value={originalTranscript}
          md={12}
          disable={true}
          currentTime={currentTime}
          handleWordClick={handleWordClickWrapper}
          wordsTranscriptTimestamp={wordsTranscriptTimestamp}
          diff={diff}
        />
        <TranscriptField
          label="עריכת תמלול"
          value={newTranscript}
          md={12}
          onChange={handleNewTranscriptChange}
          currentTime={currentTime}
          handleWordClick={handleWordClickWrapper}
          wordsTranscriptTimestamp={wordsTranscriptTimestamp}
        />
      </Grid>
    </>
  );
};

export default TranscriptionControls;